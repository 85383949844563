/**
 * Footer.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default theme => ( {
	root: {
		flexGrow: 0,
    	padding: theme.spacing(2, 4),
	},
} )
