/**
 * Sidebar.jsx
 *
 * @file This file exports a component which creates the sidebar.
 * @author Robin Walter <hello@robinwalter.me>
 */

import {
	AccountCircle,
	Announcement,
	ContactMail,
	Dashboard,
	Event,
	GroupAdd,
	People,
	Place,
	RssFeed,
	School
} from '@material-ui/icons'
import { Box, Divider, Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import { Profile } from '../Profile'
import SidebarNav from './SidebarNav'
import styles from './Sidebar.styles'

// Create styles
const useStyles = makeStyles( styles )

/**  */
export const pages = [
	{
		href: '/',
		icon: <Dashboard />,
		title: 'Dashboard'
	},
	{
		create: '/events/schedule',
		href: '/events',
		icon: <Event />,
		title: 'Events'
	},
	{
		href: "/wordpress",
		icon: <RssFeed />,
		title: "Veranstaltungen (WP)",
	},
	{
		href: '/participants',
		icon: <People />,
		title: 'Teilnehmer'
	},
	{
		href: '/participations',
		icon: <GroupAdd />,
		title: 'Anmeldungen'
	},
	{
		create: '/news/publish',
		href: '/news',
		icon: <Announcement />,
		title: 'Status Meldungen'
	},
	{
		create: '/schools/new',
		href: '/schools',
		icon: <School />,
		title: 'Schulen'
	},
	{
		create: '/venues/new',
		href: '/venues',
		icon: <Place />,
		title: 'Veranstaltungsorte'
	},
	{
		create: '/reference-people/new',
		href: '/reference-people',
		icon: <ContactMail />,
		title: 'Ansprechpartner'
	},
	{
		href: '/users',
		icon: <AccountCircle />,
		me: '/users/me',
		title: 'Systembenutzer'
	}
]

/**
 * Create the sidebar.
 *
 * @param {object} props The component props.
 * @returns {Node} The rendered component.
 */
const Sidebar = ( { onClose, open, ...other } ) => {
	const classes = useStyles()

	return (
    	<Drawer
			anchor="left"
    		classes={ { paper: classes.drawer } }
    		onClose={ onClose }
    		open={ open }
			{ ...other }>
			<Box className={ classes.root }>
				<Profile />
				<Divider className={ classes.divider } />
        		<SidebarNav className={ classes.nav } pages={ pages } />
			</Box>
		</Drawer>
	)
}

/**
 *
 */
Sidebar.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired
}

export default Sidebar
