/**
 * EventCard.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	actionButton: {
		display: "flex",
		flexGrow: 1,
		justifyContent: "center",
	},
	actions: {
		borderTop: `1px solid ${theme.palette.grey["300"]}`,
		display: "flex",
	},
	card: {
		maxWidth: 600,
		width: "100%",
	},
	inlineContainer: {
		display: "flex",
		flexGrow: 1,
	},
	inlineParagraph: {
		display: "inline-block",
		width: '100%',
	},
	icon: {
		marginRight: theme.spacing(1),
	},
	paragraph: {
		display: "inline-block",
	},
})
