/**
 * NewsDetail.jsx
 *
 * @file This file exports a detail view for news.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Grid, Typography } from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useQuery } from '@apollo/client'
import Lottie from 'lottie-react'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import React from 'react'
import { useParams } from '@reach/router'

// internal imports
/**!
 * Copyright © Erick Daniel Juarez Gil
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/ErickDanielJuarezGil
 * @link https://lottiefiles.com/9531-oops-something-went-wrong?lang=en
 */
import errorAnimationData from '../../assets/animations/ErrorAnimation.json'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'
import styles from './NewsDetail.styles'

// Create styles
const useStyles = makeStyles(styles)

/** Fetch the specified news and all it's details from the GraphQL API. */
const GET_NEWS_DETAIL = gql`
	query NewsDetail( $id: ID! ) {
		news( id: $id ) {
			content
			expiryDate
			id
			status
			title
		}
	}
`

/**
 * Create the news detail view.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const NewsDetail = ( { count, ...other } ) => {
	const classes = useStyles()
	const { id }  = useParams()

	/** Execute the query and fetch the data from the GraphQL API. */
	const { data, error, loading, refetch } = useQuery( GET_NEWS_DETAIL, {
		fetchPolicy: 'network-only',
        variables: { id }
	} )

	return (
		<>
			<GatsbySeo title="Status Meldungen" />
			{ error &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ errorAnimationData }
							autoplay={ true }
							loop={ true }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ loading &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ true }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ !error && data &&
				data.news &&
					<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
						<Grid item xs={ 12 }>
							<Typography align="center" className={ classes.paragraph } gutterBottom variant="h5">{ data.news.title }</Typography>
							<Typography align="right" className={ classes.paragraph } gutterBottom variant="caption">Läuft ab am { moment( data.news.expiryDate ).format( 'DD. MMMM YYYY' ) }</Typography>
							<Typography align="justify" className={ classes.paragraph } gutterBottom variant="body1">{ data.news.content }</Typography>
						</Grid>
					</Grid>
			}
		</>
	)
}

export default NewsDetail
