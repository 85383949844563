/**
 * Footer.jsx
 *
 * @file This file exports a component which creates a footer.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import React from 'react'

// internal imports
import styles from './Footer.styles'

// Create styles
const useStyles = makeStyles( styles )

/**
 * Create the footer.
 *
 * @returns {Node} The rendered component.
 */
const Footer = () => {
	const classes = useStyles()

	return (
    	<Box className={ classes.root } component="footer">
    		<Typography variant="body1">
        		&copy; <Link href="https://robinwalterfit.com/" target="_blank">Robin Walter</Link>. 2019 – { moment().format( 'YYYY' ) }
    		</Typography>

    		<Typography variant="caption">
        		Template: <Link href="https://github.com/devias-io/react-material-dashboard" target="_blank">Devias IO</Link>
    		</Typography>
    	</Box>
	)
}

export default Footer
