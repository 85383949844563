/**
 * upload.jsx
 *
 * @file This file exports a view to upload pre-defined venues.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { navigate } from 'gatsby'
import React from 'react'
import { useSelector } from 'react-redux'

// internal imports
import { redirectUnauthenticatedToSignIn } from '../../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'

/**
 * This component creates a view to upload pre-defined venues.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const UploadVenuesPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	/** @TODO Upload venues page */
	navigate( '/' )
	return null

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<>
				<GatsbySeo title="Veranstaltungsorte hochladen" />
			</>
		)
}

export default UploadVenuesPage
