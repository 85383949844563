/**
 * SchoolEdit.jsx
 *
 * @file This file exports a view to edit a specific school.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import { Alert } from '@material-ui/lab'
import {
	Button,
	Grid,
	Snackbar,
	Typography
} from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import logger from 'loglevel'
import Lottie from 'lottie-react'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useParams } from '@reach/router'

// internal imports
import { SchoolEditor } from '../SchoolEditor'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'

/** Query to get a specific school from the server. */
const GET_SCHOOL_DETAILS = gql`
	query GetSchoolDetails( $id: ID! ) {
		school( id: $id ) {
			address
			city
			comment
			email
			firstParticipation
			id
			name
			postalCode
			regionalNetwork
			telephone
			type
			website
		}
	}
`

/** Mutation to update a specific school. */
const UPDATE_SCHOOL = gql`
	mutation UpdateSchool(
		$address: String
		$city: String
		$comment: String
		$email: Email
		$firstParticipation: String
		$id: ID!
		$name: String
		$postalCode: String
		$regionalNetwork: String
		$telephone: String
		$type: String
		$website: String
	) {
		updateSchool( input: {
			address: $address
			city: $city
			comment: $comment
			email: $email
			firstParticipation: $firstParticipation
			id: $id
			name: $name
			postalCode: $postalCode
			regionalNetwork: $regionalNetwork
			telephone: $telephone
			type: $type
			website: $website
		} ) {
			id
		}
	}
`

/**
 * This component creates a view to edit an existing school.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const SchoolEdit = () => {
	const params = useParams()

	/** Define a state holding the address of the school. */
	const [ address, setAddress ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the city of the school. */
	const [ city, setCity ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the comment of the school. */
	const [ comment, setComment ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state disabling the submit button. */
	const [ disableSubmit, setDisableSubmit ] = useState( true )
	/** Define a state holding the email address of the school. */
	const [ email, setEmail ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding an error. */
	const [ error, setError ] = useState( {} )
	/** Define a state holding the first participation of the school. */
	const [ firstParticipation, setFirstParticipation ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the ID of the newly created news. */
	const [ ID, setID ] = useState( '0' )
	/** Define a state holding the name of the school. */
	const [ name, setName ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the original school's data. */
	const [ originalSchool, setOriginalSchool ] = useState( {} )
	/** Define a state holding the postal-code of the school. */
	const [ postalCode, setPostalCode ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the regional network the school belongs to. */
	const [ regionalNetwork, setRegionalNetwork ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the telephoone number of the school. */
	const [ telephone, setTelephone ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the type of the school. */
	const [ type, setType ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the website of the school. */
	const [ website, setWebsite ] = useState( {
		error: false,
		value: '',
		verified: true
	} )

	/** Fetch the school from the GraphQL Server. */
	const { error: queryError, loading: queryLoading } = useQuery( GET_SCHOOL_DETAILS, {
		displayName: 'GetSchoolDetailsQuery',
		onCompleted: data => {
			if ( data ) {
				if ( data.school ) {
					setOriginalSchool( data.school )
					setAddress( {
						error: false,
						value: data.school.address,
						verified: true
					} )
					setCity( {
						error: false,
						value: data.school.city,
						verified: true
					} )
					setComment( {
						error: false,
						value: data.school.comment || '',
						verified: true
					} )
					setEmail( {
						error: false,
						value: data.school.email || '',
						verified: true
					} )
					setFirstParticipation( {
						error: false,
						value: data.school.firstParticipation,
						verified: true
					} )
					setID( data.school.id )
					setName( {
						error: false,
						value: data.school.name,
						verified: true
					} )
					setPostalCode( {
						error: false,
						value: data.school.postalCode,
						verified: true
					} )
					setRegionalNetwork( {
						error: false,
						value: data.school.regionalNetwork,
						verified: true
					} )
					setTelephone( {
						error: false,
						value: data.school.telephone || '',
						verified: true
					} )
					setType( {
						error: false,
						value: data.school.type,
						verified: true
					} )
					setWebsite( {
						error: false,
						value: data.school.website ||' ',
						verified: true
					} )
				}
			}
		},
		ssr: false,
		variables: {
			id: params.id
		}
	} )

	/** Update a specific existing `School` on the server. */
	const [ updateSchool, { loading: mutationLoading } ] = useMutation( UPDATE_SCHOOL, {
		onCompleted: (data) => {
			navigate(`/schools/${ ID }`)
		},
		onError: (error) => {
			logger.error( error )
			setError( error )
		}
	} )

	/** Disable the save button, if necessary. */
	useEffect(
		() => {
			if (
				!address.verified ||
				!city.verified ||
				!comment.verified ||
				!email.verified ||
				!firstParticipation.verified ||
				!name.verified ||
				!postalCode.verified ||
				!regionalNetwork.verified ||
				!telephone.verified ||
				!type.verified ||
				!website.verified ||
				!schoolWasMutated()
			) {
				setDisableSubmit(true)
			}
			else {
				setDisableSubmit(false)
			}
		},
		[
			address,
			city,
			comment,
			email,
			firstParticipation,
			name,
			postalCode,
			regionalNetwork,
			telephone,
			type,
			website
		]
	)

	/**
	 * Checks if the original data was mutated.
	 *
	 * @returns {boolean}
	 */
	const schoolWasMutated = () => {
		let mutated = false

		if ( !_.isEmpty( originalSchool ) ) {
			if ( !_.isEqual( originalSchool.address, address.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.city, city.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.comment || '', comment.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.email || '', email.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.firstParticipation, firstParticipation.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.name, name.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.postalCode, postalCode.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.regionalNetwork, regionalNetwork.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.telephone || '', telephone.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.type, type.value ) ) mutated = true

			if ( !_.isEqual( originalSchool.website || '', website.value ) ) mutated = true
		}

		return mutated
	}

	return (
		<>
			<GatsbySeo title="Schule bearbeiten" />
			<Typography color="textSecondary" gutterBottom variant="h3">Schule bearbeiten</Typography>
			{ queryLoading &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ true }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 'auto',
							}} />
					</Grid>
				</Grid>
			}
			{ !queryLoading &&
				<SchoolEditor
					address={ address }
					city={ city }
					comment={ comment }
					email={ email }
					firstParticipation={ firstParticipation }
					handleAddressChanged={ setAddress }
					handleCityChanged={ setCity }
					handleCommentChanged={ setComment }
					handleEmailChanged={ setEmail }
					handleFirstParticipationChanged={ setFirstParticipation }
					handleNameChanged={ setName }
					handlePostalCodeChanged={ setPostalCode }
					handleRegionalNetworkChanged={ setRegionalNetwork }
					handleTelephoneChanged={ setTelephone }
					handleTypeChanged={ setType }
					handleWebsiteChanged={ setWebsite }
					name={ name }
					postalCode={ postalCode }
					regionalNetwork={ regionalNetwork }
					telephone={ telephone }
					type={ type }
					website={ website }>
					<Grid item md={ 6 } xs={ 12 }>
						<Typography gutterBottom variant="h5">Änderungen speichern</Typography>

						<Typography paragraph variant="body2">Bitte überprüfen Sie Ihre Eingaben noch einmal, bevor Sie die Änderungen in der Datenbank speichern.</Typography>
						<Button
							color="secondary"
							disabled={ disableSubmit }
							onClick={ e => {
								e.preventDefault()

								if ( schoolWasMutated() ) {
									updateSchool( {
										variables: {
											address: address.value,
											city: city.value,
											comment: _.isEmpty( comment.value ) ? null : comment.value,
											email: _.isEmpty( email.value ) ? null : email.value,
											firstParticipation: firstParticipation.value,
											id: ID,
											name: name.value,
											postalCode: postalCode.value,
											regionalNetwork: regionalNetwork.value,
											telephone: _.isEmpty( telephone.value ) ? null : telephone.value,
											type: type.value,
											website: _.isEmpty( website.value ) ? null : website.value
										}
									} )
								}
							} }
							variant="contained">
							Änderungen speichern
						</Button>
					</Grid>
				</SchoolEditor>
			}

			<Snackbar open={ !_.isEmpty( error ) }>
				<Alert elevation={ 6 } severity="error" variant="filled">Es trat ein unerwarteter Fehler auf. Bitte öffnen Sie die Konsole (Rechtsklick auf die Website -&gt; Element untersuchen -&gt; Konsole ) und beschreiben Sie dem Entwickler möglichst genau die Fehlermeldung.</Alert>
			</Snackbar>
		</>
	)
}

export default SchoolEdit
