/**
 * index.jsx
 *
 * @file This file exports a dashboard view, which provides much useful
 * information to the user.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useQuery } from '@apollo/client'
import { Grid } from '@material-ui/core'
import Lottie from 'lottie-react'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

// internal imports
import { ActiveNews } from '../components/ActiveNews'
import { LatestEvents } from '../components/LatestEvents'
import { LatestParticipants } from '../components/LatestParticipants'
// import { MiniPlaceholder } from '../components/MiniPlaceholder'
// import { Placeholder } from '../components/Placeholder'
// import { TasksProgress } from '../components/TasksProgress'
import { TotalParticipants } from '../components/TotalParticipants'
// import { UsersByDevice } from '../components/UsersByDevice'
/**!
 * Copyright © Erick Daniel Juarez Gil
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/ErickDanielJuarezGil
 * @link https://lottiefiles.com/9531-oops-something-went-wrong?lang=en
 */
import errorAnimationData from '../assets/animations/ErrorAnimation.json'
/**!
 * Copyright © Alex Martov
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/Martov90
 * @link https://lottiefiles.com/9408-creative-process?lang=en
 */
import loadingAnimationData from '../assets/animations/LoadingDashboardAnimation.json'
import { redirectUnauthenticatedToSignIn } from '../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../state'

/** Fetch the necessary dashboard details from the GraphQL API. */
const GET_DASHBOARD_DETAILS = gql`
	query dashboardDetails( $newsAfter: Date ) {
		allNews( expiresAfter: $newsAfter first: 100 status: PUBLISH ) {
			paginatorInfo {
				count
			}
		}
		currentEvents( first: 5 ) {
			data {
				eventID
				id
				regionalNetworks
				status
				type {
					description
					type
				}
			}
			paginatorInfo {
				count
				total
			}
		}
		#devicesDetection
		participants( first: 10 sort: { column: "created_at" order: DESC } ) {
			data {
				appellation
				createdAt
				firstName
				id
				lastName
				participation {
					event {
						eventID
						id
					}
					id
				}
				status
				uuid
			}
			paginatorInfo {
				count
				total
			}
		}
	}
`

/**
 * This component creates a dashboard view, which provides much
 * useful information to the user.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const IndexPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	/** Execute the query to fetch the data from the GraphQL API. */
	const { data, error, loading } = useQuery( GET_DASHBOARD_DETAILS, {
		variables: { newsAfter: moment().format( 'YYYY-MM-DD' ) }
	} )

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<>
				<GatsbySeo title="Dashboard" />
				{ data &&
					<Grid container spacing={ 4 }>
						<Grid item lg={ 3 } sm={ 6 } xl={ 3 } xs={ 12 }>
							{ data.allNews &&
								data.allNews.paginatorInfo &&
									<ActiveNews count={ data.allNews.paginatorInfo.count } />
							}
						</Grid>

						<Grid item lg={ 3 } sm={ 6 } xl={ 3 } xs={ 12 }>
							{ data.participants &&
								data.participants.data && data.participants.paginatorInfo &&
									<TotalParticipants count={ data.participants.paginatorInfo.total } />
							}
						</Grid>
						{/* TODO: Safe the space until it's actually needed
						<Grid item lg={ 3 } sm={ 6 } xl={ 3 } xs={ 12 }>
							<TasksProgress />
						</Grid>

						<Grid item lg={ 3 } sm={ 6 } xl={ 3 } xs={ 12 }>
							<MiniPlaceholder />
						</Grid>

						<Grid item lg={ 8 } md={ 12 } xl={ 9 } xs={ 12 }>
							<Placeholder />
						</Grid>
						*/}
						{/* TODO: does not work, query isn't send to the server
						<Grid item lg={ 4 } md={ 6 } xl={ 3 } xs={ 12 }>
							{ data.devicesDetection &&
								<UsersByDevice stats={ data.devicesDetection } />
							}
						</Grid>
						*/}

						<Grid item lg={ 4 } md={ 6 } xl={ 3 } xs={ 12 }>
							{ data.currentEvents &&
								data.currentEvents.data && data.currentEvents.paginatorInfo &&
									<LatestEvents
										events={ data.currentEvents.data }
										paginatorInfo={ data.currentEvents.paginatorInfo } />
							}
						</Grid>

						<Grid item lg={ 8 } md={ 12 } xl={ 9 } xs={ 12 }>
							{ data.participants &&
								data.participants.data && data.participants.paginatorInfo &&
									<LatestParticipants
										paginatorInfo={ data.participants.paginatorInfo }
										participants={ data.participants.data } />
							}
						</Grid>

					</Grid>
				}
				{ error &&
					<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
						<Grid item xs={ 12 }>
							<Lottie
								animationData={ errorAnimationData }
								autoplay={ true }
								loop={ false }
								style={{
									display: 'inline-block',
									height: 'auto',
									maxWidth: '100%',
									width: 1000,
								}} />
						</Grid>
					</Grid>
				}
				{ loading &&
					<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
						<Grid item xs={ 12 }>
							<Lottie
								animationData={ loadingAnimationData }
								autoplay={ true }
								loop={ false }
								style={{
									display: 'inline-block',
									height: 'auto',
									maxWidth: '100%',
									width: 'auto',
								}} />
						</Grid>
					</Grid>
				}
			</>
		)
}

export default IndexPage
