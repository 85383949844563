/**
 * new.jsx
 *
 * @file This file exports a view to create new schools.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash/core'
import { Alert } from '@material-ui/lab'
import {
	Button,
	Grid,
	Snackbar,
	Typography
} from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation } from '@apollo/client'
import logger from 'loglevel'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// internal imports
import { redirectUnauthenticatedToSignIn } from '../../services/auth'
import { SchoolEditor } from '../../components/SchoolEditor'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'

/** Mutation to create a new school. */
const ADD_SCHOOL = gql`
	mutation AddSchool(
		$address: String!
		$city: String!
		$comment: String
		$email: Email
		$firstParticipation: String!
		$name: String!
		$postalCode: String!
		$regionalNetwork: String!
		$telephone: String
		$type: String!
		$website: String
	) {
		createSchool( input: {
			address: $address
			city: $city
			comment: $comment
			email: $email
			firstParticipation: $firstParticipation
			name: $name
			postalCode: $postalCode
			regionalNetwork: $regionalNetwork
			telephone: $telephone
			type: $type
			website: $website
		} ) {
			id
		}
	}
`

/**
 * This component creates a view to create new schools.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const NewSchoolPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	/** Define a state holding the address of the school. */
	const [ address, setAddress ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the city of the school. */
	const [ city, setCity ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the comment of the school. */
	const [ comment, setComment ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state disabling the submit button. */
	const [ disableSubmit, setDisableSubmit ] = useState( true )
	/** Define a state holding the email address of the school. */
	const [ email, setEmail ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding an error. */
	const [ error, setError ] = useState( {} )
	/** Define a state holding the first participation of the school. */
	const [ firstParticipation, setFirstParticipation ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the ID of the newly created news. */
	const [ ID, setID ] = useState( '0' )
	/** Define a state holding the name of the school. */
	const [ name, setName ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the postal-code of the school. */
	const [ postalCode, setPostalCode ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the regional network the school belongs to. */
	const [ regionalNetwork, setRegionalNetwork ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the telephoone number of the school. */
	const [ telephone, setTelephone ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the type of the school. */
	const [ type, setType ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the website of the school. */
	const [ website, setWebsite ] = useState( {
		error: false,
		value: '',
		verified: true
	} )

	/** Create a new `School` on the server. */
	const [ addSchool ] = useMutation( ADD_SCHOOL, {
		onCompleted: (data) => {
			setID(data.createSchool.id)

			navigate(`/schools/${ data.createSchool.id }`)
		},
		onError: (error) => {
			logger.error(error)
			setError(error)
		},
		variables: {
			address: address.value,
			city: city.value,
			comment: _.isEmpty( comment.value ) ? null : comment.value,
			email: _.isEmpty( email.value ) ? null : email.value,
			firstParticipation: firstParticipation.value,
			name: name.value,
			postalCode: postalCode.value,
			regionalNetwork: regionalNetwork.value,
			telephone: _.isEmpty( telephone.value ) ? null : telephone.value,
			type: type.value,
			website: _.isEmpty( website.value ) ? null : website.value
		}
	} )

	/** Disable the save button, if necessary. */
	useEffect(
		() => {
			if (
				!address.verified ||
				!city.verified ||
				!comment.verified ||
				!email.verified ||
				!firstParticipation.verified ||
				!name.verified ||
				!postalCode.verified ||
				!regionalNetwork.verified ||
				!telephone.verified ||
				!type.verified ||
				!website.verified
			) {
				setDisableSubmit(true)
			}
			else {
				setDisableSubmit(false)
			}
		},
		[
			address,
			city,
			comment,
			email,
			firstParticipation,
			name,
			postalCode,
			regionalNetwork,
			telephone,
			type,
			website
		]
	)

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<>
				<GatsbySeo title="Neue Schule anlegen" />
				<Typography color="textSecondary" gutterBottom variant="h3">Neue Schule anlegen</Typography>
				<SchoolEditor
					address={ address }
					city={ city }
					comment={ comment }
					email={ email }
					firstParticipation={ firstParticipation }
					handleAddressChanged={ setAddress }
					handleCityChanged={ setCity }
					handleCommentChanged={ setComment }
					handleEmailChanged={ setEmail }
					handleFirstParticipationChanged={ setFirstParticipation }
					handleNameChanged={ setName }
					handlePostalCodeChanged={ setPostalCode }
					handleRegionalNetworkChanged={ setRegionalNetwork }
					handleTelephoneChanged={ setTelephone }
					handleTypeChanged={ setType }
					handleWebsiteChanged={ setWebsite }
					name={ name }
					postalCode={ postalCode }
					regionalNetwork={ regionalNetwork }
					telephone={ telephone }
					type={ type }
					website={ website }>
					<Grid item md={ 6 } xs={ 12 }>
						<Typography gutterBottom variant="h5">Änderungen speichern</Typography>

						<Typography paragraph variant="body2">Bitte überprüfen Sie Ihre Eingaben noch einmal, bevor Sie die Änderungen in der Datenbank speichern.</Typography>

						<Button
							color="secondary"
							disabled={ disableSubmit }
							onClick={ e => {
								e.preventDefault()

								addSchool()
							} }
							variant="contained">
							Neue Schule speichern
						</Button>
					</Grid>
				</SchoolEditor>

				<Snackbar open={ !_.isEmpty( error ) }>
					<Alert elevation={ 6 } severity="error" variant="filled">Es trat ein unerwarteter Fehler auf. Bitte öffnen Sie die Konsole (Rechtsklick auf die Website -&gt; Element untersuchen -&gt; Konsole ) und beschreiben Sie dem Entwickler möglichst genau die Fehlermeldung.</Alert>
				</Snackbar>
			</>
		)
}

export default NewSchoolPage
