/**
 * LatestEvents.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	actions: {
    	justifyContent: 'flex-end',
	},
	avatar: {
    	height: 48,
    	width: 48,
	},
	content: {
    	padding: 0,
	},
	error: {
		backgroundColor: theme.palette.error.main,
	},
	info: {
		backgroundColor: theme.palette.info.main,
	},
	root: {
    	height: '100%',
	},
	success: {
		backgroundColor: theme.palette.success.main,
	},
	warning: {
		backgroundColor: theme.palette.warning.main,
	},
	white: {
		backgroundColor: theme.palette.white,
	},
})
