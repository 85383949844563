/**
 * LatestParticipants.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	actions: {
    	justifyContent: 'flex-end',
	},
	content: {
    	padding: 0,
	},
	inner: {
    	minWidth: 900,
	},
	root: {},
	status: {
    	marginRight: theme.spacing(1),
	},
	statusContainer: {
		alignItems: 'center',
    	display: 'flex',
	},
})
