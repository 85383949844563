/**
 * BasicInformation.jsx
 *
 * @file This file exports the form part for the basic information.
 * @author Robin Walter <hello@robinwalter.me>
 */

import {
	Grid,
	TextField,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import validator from 'validator'

// internal imports
import styles from './SchoolEditor.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * This component creates a form part for the basic information.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered component.
 */
const BasicInformation = ({
	firstParticipation,
	handleFirstParticipationChanged,
	handleNameChanged,
	handleRegionalNetworkChanged,
	handleTypeChanged,
	md,
	name,
	regionalNetwork,
	type,
	xs
}) => {
	const classes = useStyles()

	/** Validate the input data and check if there are any mistakes. */
	useEffect( () => {
		// Validate the first participation
		if ( validator.isEmpty( firstParticipation.value ) ) {
			handleFirstParticipationChanged( {
				...firstParticipation,
				error: true,
				verified: false
			} )
		}
		else {
			handleFirstParticipationChanged( {
				...firstParticipation,
				error: false,
				verified: true
			} )
		}
		// Validate the name
		if ( validator.isEmpty( name.value ) ) {
			handleNameChanged( {
				...name,
				error: true,
				verified: false
			} )
		}
		else {
			handleNameChanged( {
				...name,
				error: false,
				verified: true
			} )
		}
		// Validate the regional network
		if ( validator.isEmpty( regionalNetwork.value ) ) {
			handleRegionalNetworkChanged( {
				...regionalNetwork,
				error: true,
				verified: false
			} )
		}
		else {
			handleRegionalNetworkChanged( {
				...regionalNetwork,
				error: false,
				verified: true
			} )
		}
		// Validate the type
		if ( validator.isEmpty( type.value ) ) {
			handleTypeChanged( {
				...type,
				error: true,
				verified: false
			} )
		}
		else {
			handleTypeChanged( {
				...type,
				error: false,
				verified: true
			} )
		}
	}, [ firstParticipation.value, name.value, regionalNetwork.value, type.value ] )

	return (
		<Grid item md={ md || 6 } xs={ xs || 12 }>
			<Typography gutterBottom variant="h5">1. Basisinformationen über die Schule</Typography>
			<TextField
				className={ classes.formControl }
				error={ name.error }
				helperText="Geben Sie den Namen der Schule ein."
				id="school-name"
				InputProps={{
					className: classes.input,
				}}
				label="Name"
				onChange={ e => {
					handleNameChanged( {
						...name,
						value: e.target.value
					} )
				} }
				required
				value={ name.value } />
			<TextField
				className={ classes.formControl }
				error={ type.error }
				helperText="Z.B. Gymnasium oder Realschule"
				id="school-type"
				InputProps={{
					className: classes.input,
				}}
				label="Schulart"
				onChange={ e => {
					handleTypeChanged( {
						...type,
						value: e.target.value
					} )
				} }
				required
				value={ type.value } />
			<TextField
				className={ classes.formControl }
				error={ regionalNetwork.error }
				helperText="Zu welchem Regionalverbund gehört die Schule?"
				id="school-regional-network"
				InputProps={{
					className: classes.input,
				}}
				label="Regionalverbund"
				onChange={ e => {
					handleRegionalNetworkChanged( {
						...regionalNetwork,
						value: e.target.value
					} )
				} }
				required
				value={ regionalNetwork.value } />
			<TextField
				className={ classes.formControl }
				error={ firstParticipation.error }
				helperText="In welchem Schuljahr hat die Schule zum ersten Mal an Jugend debattiert teilgenommen? Z.B. 2020/2021"
				id="school-first-participation"
				InputProps={{
					className: classes.input,
				}}
				label="Erste Teilnahme"
				onChange={ e => {
					handleFirstParticipationChanged( {
						...firstParticipation,
						value: e.target.value
					} )
				} }
				required
				value={ firstParticipation.value } />
		</Grid>
	)
}

/**
 *
 */
BasicInformation.propTypes = {
	firstParticipation: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	handleFirstParticipationChanged: PropTypes.func.isRequired,
	handleNameChanged: PropTypes.func.isRequired,
	handleRegionalNetworkChanged: PropTypes.func.isRequired,
	handleTypeChanged: PropTypes.func.isRequired,
	md: PropTypes.number,
	name: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	regionalNetwork: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	type: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	xs: PropTypes.number
}

export default BasicInformation
