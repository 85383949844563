/**
 * index.jsx
 *
 * @file This file exports a view to list all schools from the database.
 * Some client-only routes are configured as well.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box, Grid } from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useQuery } from '@apollo/client'
import Lottie from 'lottie-react'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { Router } from '@reach/router'
import { useSelector } from 'react-redux'

// internal imports
/**!
 * Copyright © Erick Daniel Juarez Gil
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/ErickDanielJuarezGil
 * @link https://lottiefiles.com/9531-oops-something-went-wrong?lang=en
 */
import errorAnimationData from '../../assets/animations/ErrorAnimation.json'
import { SchoolDetail } from '../../components/SchoolDetail'
import { SchoolEdit } from '../../components/SchoolEdit'
import { SchoolsTable } from '../../components/SchoolsTable'
import { SchoolsToolbar } from '../../components/SchoolsToolbar'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'
import { redirectUnauthenticatedToSignIn } from '../../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'

// Create styles
const useStyles = makeStyles((theme) => ({
	content: {
    	marginTop: theme.spacing(2),
	},
}))

/** Fetch all schools from the GraphQL API. */
const GET_SCHOOLS_LIST = gql`
	query SchoolsList(
		$city: String
		$firstParticipation: String
		$name: String
		$postalCode: String
		$regionalNetwork: String
		$type: String
	) {
		allSchools(
			city: $city
			firstParticipation: $firstParticipation
			name: $name
			postalCode: $postalCode
			regionalNetwork: $regionalNetwork
			type: $type
		) {
			address
			city
			comment
			email
			firstParticipation
			id
			name
			postalCode
			regionalNetwork
			telephone
			type
			website
		}
	}
`

/**
 * This component creates a view which lists all schools.
 *
 * @returns {Node} The rendered component.
 */
const SchoolsList = () => {
	const classes = useStyles()

	/** Define a state holding the selected schools. */
	const [selectedSchools, setSelectedSchools] = useState([])

	/** Execute the query and fetch the data from the GraphQL API. */
	const { data, error, loading, refetch } = useQuery(GET_SCHOOLS_LIST, {
		fetchPolicy: 'network-only',
		variables: {},
	})

	return (
		<>
			<GatsbySeo title="Schulen" />
			{ error &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ errorAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ loading &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ !error && data &&
				data.allSchools &&
					<>
						<SchoolsToolbar refetch={ refetch } />
						<Box className={ classes.content }>
							<SchoolsTable
								schools={ data.allSchools }
								loading={ loading }
								refetch={ refetch }
								selectedSchools={ selectedSchools }
								setSelectedSchools={ setSelectedSchools } />
						</Box>
					</>
			}
		</>
	)
}

/**
 * This component creates a view to list and manage all schools from the database,
 * provided by the GraphQL API.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const SchoolsPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<>
				<GatsbySeo title="Schulen" />
				<Router basepath="/schools">
					<SchoolDetail path="/:id" />
					<SchoolEdit path="/:id/edit" />
					<SchoolsList path="/" />
				</Router>
			</>
		)
}

export default SchoolsPage
