/**
 * Sidebar.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { colors } from '@material-ui/core'

export default theme => ( {
	active: {
    	color: theme.palette.primary.main,
    	fontWeight: theme.typography.fontWeightMedium,
    	'& $icon': {
    		color: theme.palette.primary.main,
    	},
	},
	button: {
    	color: colors.blueGrey[800],
		letterSpacing: 0,
		fontWeight: theme.typography.fontWeightMedium,
		justifyContent: 'flex-start',
    	padding: '10px 8px',
    	textTransform: 'none',
    	width: '100%',
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	drawer: {
    	width: 250,
    	[theme.breakpoints.up('lg')]: {
			height: 'calc(100% - 64px)',
			marginTop: 64,
    	},
	},
	icon: {
		alignItems: 'center',
    	color: theme.palette.icon,
		display: 'flex',
		height: 24,
		marginRight: theme.spacing(1),
    	width: 24,
	},
	item: {
    	display: 'flex',
		paddingBottom: 0,
    	paddingTop: 0,
	},
	nav: {
    	marginBottom: theme.spacing(2),
	},
	navRoot: {},
	root: {
    	backgroundColor: theme.palette.white,
    	display: 'flex',
    	flexDirection: 'column',
    	height: '100%',
    	padding: theme.spacing(2),
	},
} )
