/**
 * NewsTable.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	actions: {
    	justifyContent: "flex-end",
	},
	avatar: {
    	marginRight: theme.spacing(2),
	},
	content: {
    	padding: 0,
	},
	inner: {
    	minWidth: 1050,
	},
	nameContainer: {
		alignItems: "center",
    	display: "flex",
	},
	root: {},
})
