/**
 * index.jsx
 *
 * @file This file exports a view to list all venues from the database.
 * Some client-only routes are configured as well.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box } from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { navigate } from 'gatsby'
import React from 'react'
import { Router } from '@reach/router'
import { useSelector } from 'react-redux'

// internal imports
import { redirectUnauthenticatedToSignIn } from '../../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'

/**
 * This component creates a view to list and manage all venues from the database,
 * provided by the GraphQL API.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const VenuesPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	/** @TODO Venues page */
	navigate( '/' )
	return null

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<>
				<GatsbySeo title="Veranstaltungsorte" />
				<Router basepath="/venues">
					<Box path="/:id" />
					<Box path="/:id/edit" />
				</Router>
			</>
		)
}

export default VenuesPage
