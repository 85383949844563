/**
 * EventCard.jsx
 *
 * @file This component creates a card to display an event.
 * @author Robin Walter <hello@robinwalter.me>
 */

import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	Tooltip,
	Typography
} from '@material-ui/core'
import {
	faClock,
	faEdit,
	faFilePdf,
	faHourglassHalf,
	faInfoCircle,
	faMapSigns,
	faSchool,
	faThumbsDown,
	faThumbsUp,
	faUserAlt,
	faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useLocation } from '@reach/router'

// internal imports
import { IconButtonLink } from '../IconButtonLink'
import styles from './EventCard.styles'

// Create styles
const useStyles = makeStyles( styles )

const PARTICIPANT_STATUS = [
	'ABGELEHNT',    // 1
	'ABGEMELDET',   // 2
	'ABSENT',       // 3
	'ANGEMELDET',   // 4
	'TEILGENOMMEN', // 5
	'ZUGELASSEN'    // 6
]

const PARTICIPANT_TYPE = [
	'FORMER',
	'OTHER',
	'PARENT',
	'PUPIL',
	'TEACHER'
]

/**
 * Create a card to display an event.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered card.
 */
const EventCard = ({ accepted, count, denied, event, ...other }) => {
	const classes = useStyles()

	const location = useLocation()

	const end = moment( event.end )
	const initialRegistrationPeriod = moment( event.initialRegistrationPeriod )
	const now = moment()
	const periodOfRest = moment( event.periodOfRest )
	const secondRegistrationPeriod = moment( event.secondRegistrationPeriod )
	const start = moment( event.start )

	let secondDateEnd = null
	let secondDateStart = null

	if ( event.secondDate ) {
		secondDateEnd   = moment( event.secondDate.end )
		secondDateStart = moment( event.secondDate.start )
	}

	let deadline = null

	if ( now.isBefore( initialRegistrationPeriod ) ) deadline = moment(secondRegistrationPeriod).subtract(1, 'days')

	else if ( now.isAfter( initialRegistrationPeriod ) && now.isBefore( secondRegistrationPeriod ) ) deadline = moment(secondRegistrationPeriod).subtract(1, 'days')

	else deadline = moment(periodOfRest).subtract(1, 'days')

	return (
		<Card className={ classes.card } raised { ...other }>
			<CardHeader
				subheader={ event.type.description }
				subheaderTypographyProps={ { color: 'secondary', variant: 'h5' } }
				title={ `Region ${JSON.parse(event.regionalNetworks).cities.join(", ")}` }
				titleTypographyProps={ { color: 'primary', variant: 'body1' } } />
			<CardContent>
				<Grid container spacing={ 1 }>
					<Grid item sm={ 6 } xs={ 12 }>
						<Box className={ classes.inlineContainer }>
							<FontAwesomeIcon className={ classes.icon } icon={ faUserAlt } />
							<Typography className={ classes.inlineParagraph } variant="body2">
								{ event.lecturer ? event.lecturer.name : event.lecturerFallback }
							</Typography>
						</Box>
					</Grid>
					<Grid item sm={ 6 } xs={ 12 }>
						<FontAwesomeIcon className={ classes.icon } icon={ faInfoCircle } />
						<Typography className={ classes.paragraph } variant="body2">{ event.eventID }</Typography>
					</Grid>
					{ event.venue &&
						<Grid item sm={ 6 } xs={ 12 }>
							<FontAwesomeIcon className={ classes.icon } icon={ faMapSigns } />
							<Typography className={ classes.paragraph } variant="body2">{ event.venue.city }</Typography>
						</Grid>
					}
					<Grid item sm={ 6 } xs={ 12 }>
						<FontAwesomeIcon className={ classes.icon } icon={ faHourglassHalf } />
						<Typography className={ classes.paragraph } variant="body2">{ secondDateEnd ? moment.duration( start.diff( secondDateEnd ) ).humanize() : moment.duration( start.diff( end ) ).humanize() }</Typography>
					</Grid>
					<Grid item xs={ 12 }>
						<Box className={ classes.inlineContainer }>
							<FontAwesomeIcon className={ classes.icon } icon={ faClock } />
							<Typography className={ classes.inlineParagraph } variant="body2">
								<b>{ start.format( 'LL' ) }</b>, { start.format( 'HH:mm' ) }-{ end.format( 'HH:mm [Uhr]' ) }
								{ secondDateStart ? `, und ${ secondDateStart.format( 'LL' ) }, ${ secondDateStart.format( 'HH:mm' ) }-${ secondDateEnd.format( 'HH:mm [Uhr]' ) }` : '' }
							</Typography>
						</Box>
					</Grid>
					{ event.venue &&
					<Grid item xs={ 12 }>
							<Box className={ classes.inlineContainer }>
								<FontAwesomeIcon className={ classes.icon } icon={ faSchool } />
								<Typography className={ classes.inlineParagraph } variant="body2">{ event.venue.name }</Typography>
							</Box>
						</Grid>
					}
					<Grid item xs={ 12 }>
						<FontAwesomeIcon className={ classes.icon } icon={ faUserPlus } />
						<Typography className={ classes.paragraph } variant="body2">{ deadline ? deadline.calendar() : 'N/A' }</Typography>
					</Grid>
					<Grid item xs={ 12 }>
						<FontAwesomeIcon className={ classes.icon } icon={ faUserPlus } />
						<Typography className={ classes.paragraph } variant="body2">{ `Anzahl angemeldeter Teilnehmer: ${ count }` }</Typography>
					</Grid>
					<Grid item xs={ 6 }>
						<FontAwesomeIcon className={ classes.icon } icon={ faThumbsUp } />
						<Typography className={ classes.paragraph } variant="body2">{ `Anzahl Teilnehmer zugelassen: ${ accepted }` }</Typography>
					</Grid>
					<Grid item xs={ 6 }>
						<FontAwesomeIcon className={ classes.icon } icon={ faThumbsDown } />
						<Typography className={ classes.paragraph } variant="body2">{ `Anzahl Teilnehmer abgelehnt: ${ denied }` }</Typography>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions className={ classes.actions }>
				<Box className={ classes.actionButton }>
					<Tooltip
						placement="top"
						title="Informationen zur Veranstaltung herunterladen">
						<IconButton
							aria-label="Informationen zur Veranstaltung herunterladen"
							color="primary"
							href={ `${ process.env.GATSBY_FILES_PDF_ENDPOINT }/${ event.eventID }/${ event.eventID }` }
							tabIndex="-1"
							target="_blank">
							<FontAwesomeIcon icon={ faFilePdf } />
						</IconButton>
					</Tooltip>
				</Box>
				<Box className={ classes.actionButton }>
					<Tooltip
						placement="top"
						title="Die Veranstaltung bearbeiten">
						<IconButtonLink
							aria-label="Die Veranstaltung bearbeiten"
							color="primary"
							tabIndex="-1"
							to={ `${ location.pathname }/edit` }>
							<FontAwesomeIcon icon={ faEdit } />
						</IconButtonLink>
					</Tooltip>
				</Box>
			</CardActions>
		</Card>
	)
}

/**
 *
 */
EventCard.propTypes = {
	accepted: PropTypes.number.isRequired,
	count: PropTypes.number,
	denied: PropTypes.number.isRequired,
	event: PropTypes.shape( {
		creator: PropTypes.shape( {
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		} ).isRequired,
		end: PropTypes.string.isRequired,
		eventID: PropTypes.string.isRequired,
		fee: PropTypes.bool.isRequired,
		htmlLink: PropTypes.string,
		id: PropTypes.string.isRequired,
		initialRegistrationPeriod: PropTypes.string.isRequired,
		lecturer: PropTypes.shape( {
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		} ),
		lecturerFallback: PropTypes.string,
		participations: PropTypes.arrayOf( PropTypes.shape( {
			participants: PropTypes.arrayOf( PropTypes.shape( {
				appellation: PropTypes.string.isRequired,
				firstName: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
				lastName: PropTypes.string.isRequired,
				status: PropTypes.oneOf(PARTICIPANT_STATUS).isRequired,
				type: PropTypes.oneOf(PARTICIPANT_TYPE).isRequired,
				uuid: PropTypes.string.isRequired
			} ) ).isRequired,
			school: PropTypes.shape( {
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired
			} )
		} ) ),
		periodOfRest: PropTypes.string.isRequired,
		regionalNetworks: PropTypes.string.isRequired,
		secondDate: PropTypes.shape( {
			end: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			start: PropTypes.string.isRequired
		} ),
		secondRegistrationPeriod: PropTypes.string.isRequired,
		start: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		type: PropTypes.shape({
			description: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
		}).isRequired,
		venue: PropTypes.shape( {
			city: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			location: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		} )
	} ).isRequired
}

export default EventCard
