/**
 * SchoolDetail.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	content: {
    	marginTop: theme.spacing(2),
	},
	paragraph: {
		display: "block",
	},
})
