/**
 * auth.js
 *
 * @file This file exports some functions to handle authentication.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash/core'
import axios from 'axios'
import { navigate } from 'gatsby'
import qs from 'qs'

/**
 * This function sends a request to the Passport OAuth 2.0 Server to claim
 * a new access token with the password grant type.
 *
 * Since `axios` is a promised based client, the answer isn't progressed directly.
 * Instead this function returns a `Promise`.
 *
 * @example
 * cleamAccessTokenWithPassword( 'username', 'super-secret1' )
 * 	.then( res => { const tokenData = res.data } )
 *
 * @param {string} mail The mail address as username.
 * @param {string} password The password to claim a new access token.
 * @returns {Promise}
 */
export const claimAccessTokenWithPassword = (mail, password) => {
	const data = {
		client_id: process.env.GATSBY_PASSPORT_CLIENT_ID,
		client_secret: process.env.GATSBY_PASSPORT_CLIENT_SECRET,
		grant_type: 'password',
		password: password,
		scope: '*',
		username: mail
	}

	const options = {
		data: qs.stringify( data ),
		headers: { 'content-type': 'application/x-www-form-urlencoded' },
		method: 'POST',
		url: `${ process.env.GATSBY_PASSPORT_CLIENT_URL }/token` // Trailing slash breaks CORS
	}

	return axios(options)
}

/**
 * This function sends a request to the Passport OAuth 2.0 Server to claim
 * a new access token with the refresh token grant type.
 *
 * Since `axios` is a promised based client, the answer isn't progressed directly.
 * Instead this function returns a `Promise`.
 *
 * @example
 * cleamAccessTokenWithRefreshToken( 'abc123' )
 * 	.then( res => { const tokenData = res.data } )
 *
 * @param {string} refreshToken The refresh token to claim a new access token.
 * @returns {Promise}
 */
export const claimAccessTokenWithRefreshToken = (refreshToken) => {
	const data = {
		client_id: process.env.GATSBY_PASSPORT_CLIENT_ID,
		client_secret: process.env.GATSBY_PASSPORT_CLIENT_SECRET,
		grant_type: 'refresh_token',
		refresh_token: refreshToken,
		scope: '*'
	}

	const options = {
		data: qs.stringify( data ),
		headers: { 'content-type': 'application/x-www-form-urlencoded' },
		method: 'POST',
		url: `${ process.env.GATSBY_PASSPORT_CLIENT_URL }/token` // Trailing slash breaks CORS
	}

	return axios(options)
}

/** Little helper function, to check if the environment is a browser. */
export const isBrowser = typeof window !== 'undefined'

/**
 * This function redirects the user to the dashboard if he/she is already
 * signed-in.
 *
 * @param {boolean} stayLoggedIn If true, the user wants to stay logged-in.
 * The `accessToken` will then be checked instead of the `sessionAccessToken`.
 * @param {string} accessToken The access-token from the store.
 * @param {string} sessionAccessToken The session access-token from the store.
 * @returns {boolean} True, if the user is already authenticated.
 */
export const redirectAuthenticatedToDashboard = (stayLoggedIn, accessToken, sessionAccessToken) => {
	if ( stayLoggedIn ) {
		if ( !_.isEmpty( accessToken ) ) {
			navigate( '/' )
			return true
		}
	}
	else {
		if ( !_.isEmpty( sessionAccessToken ) ) {
			navigate( '/' )
			return true
		}
	}

	return false
}

/**
 * This function redirects an unauthenticated user to the sign-in page.
 *
 * @param {boolean} stayLoggedIn If true, the user wants to stay logged-in.
 * The `accessToken` will then be checked instead of the `sessionAccessToken`.
 * @param {string} accessToken The access-token from the store.
 * @param {string} sessionAccessToken The session access-token from the store.
 * @returns {boolean} True, if the user needs to be authenticated.
 */
export const redirectUnauthenticatedToSignIn = (stayLoggedIn, accessToken, sessionAccessToken) => {
	if ( stayLoggedIn ) {
		if ( _.isEmpty( accessToken ) ) {
			navigate( '/sign-in' )
			return true
		}
	}
	else {
		if ( _.isEmpty( sessionAccessToken ) ) {
			navigate( '/sign-in' )
			return true
		}
	}

	return false
}
