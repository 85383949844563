/**
 * TotalParticipants.jsx
 *
 * @file This file exports a tile view which shows the total of participants.
 * @author Robin Walter <hello@robinwalter.me>
 */

import {
	Avatar,
	Card,
	CardContent,
	Grid,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { PeopleOutlined } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import styles from './TotalParticipants.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * Create the total participants tile.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const TotalParticipants = ({ count, ...other }) => {
	const classes = useStyles()

	return (
    	<Card className={ classes.root } { ...other }>
    		<CardContent>
        		<Grid container justifyContent="space-between">
        			<Grid item>
            			<Typography
            				className={ classes.title }
            				color="textSecondary"
            				gutterBottom
            				variant="body2">
            				TEILNEHMER INSGESAMT
            			</Typography>
            			<Typography variant="h3">Anzahl: { count }</Typography>
        			</Grid>
        			<Grid item>
            			<Avatar className={ classes.avatar }>
            				<PeopleOutlined className={ classes.icon } />
            			</Avatar>
        			</Grid>
        		</Grid>
    		</CardContent>
    	</Card>
	)
}

/**
 *
 */
TotalParticipants.propTypes = {
	count: PropTypes.number.isRequired
}

export default TotalParticipants
