/**
 * EventDetail.jsx
 *
 * @file This file exports a detail view for events..
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from "lodash"
import { Box, Card, CardContent, Grid } from '@material-ui/core'
import { DataGrid } from "@mui/x-data-grid"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import Lottie from 'lottie-react'
import { makeStyles } from '@material-ui/styles'
import React, { useMemo, useState } from 'react'
import { useParams } from '@reach/router'

// internal imports
import { ButtonLink } from "../ButtonLink"
/**!
 * Copyright © Erick Daniel Juarez Gil
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/ErickDanielJuarezGil
 * @link https://lottiefiles.com/9531-oops-something-went-wrong?lang=en
 */
import errorAnimationData from '../../assets/animations/ErrorAnimation.json'
import { EventCard } from '../EventCard'
import { EventDetailParticipantsTable } from '../EventDetailParticipantsTable'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'
import { pages } from "../Sidebar"
import { ParticipantsToolbar } from '../ParticipantsToolbar'
import styles from './EventDetail.styles'

// Create styles
const useStyles = makeStyles(styles)

/** Delete the specified participant. */
const DELETE_PARTICIPANT = gql`
	mutation deleteParticipant( $id: ID! ) {
		deleteParticipant( id: $id ) {
			id
		}
	}
`

/** Fetch the specified event and all it's details from the GraphQL API. */
const GET_EVENT_DETAIL = gql`
	query eventDetail( $id: ID! ) {
		event( id: $id ) {
			creator {
				id
				name
			}
			end
			eventID
			fee
            htmlLink
			id
			initialRegistrationPeriod
			lecturer {
				id
				name
			}
			lecturerFallback
			participations {
				id
				participants {
					appellation
					confirmed
	            	firstName
					id
	            	lastName
	            	status
	            	type
					uuid
				}
				school {
					city
					id
					name
				}
			}
			periodOfRest
			regionalNetworks
			secondDate {
				end
				id
				start
			}
			secondRegistrationPeriod
			start
			status
			type {
				description
				type
			}
			venue {
				city
				id
				location
				name
			}
		}
	}
`

/** Mutate the specified participant's status. */
const UPDATE_PARTICIPANT_STATUS = gql`
	mutation participantStatus( $id: ID! $status: ParticipantStatus! ) {
		updateParticipantStatus( id: $id status: $status ) {
			id
			status
			uuid
		}
	}
`

/**
 * Create the event detail view.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const EventDetail = ({ count, ...other }) => {
	const classes = useStyles()
	const { id } = useParams()

	/** Define a state that holds the number of participants accepted */
	const [participantsAccepted, setParticipantsAccepted] = useState(0)
	/** Define a state that holds the number of participants denied */
	const [participantsDenied, setParticipantsDenied] = useState(0)
	/** Define a state that holds the count of participants */
	const [participantCount, setParticipantCount] = useState(0)
	/** Define a state that holds the rows currently displayed */
	const [rows, setRows] = useState([])

	/** Execute the query and fetch the data from the GraphQL API. */
	const { data, error, loading, refetch } = useQuery( GET_EVENT_DETAIL, {
		fetchPolicy: 'network-only',
        variables: { id }
	} )
	/** Prepare the mutation. */
	const [ updateParticipantStatus, { data: dataMutation, error: errorMutation, loading: loadingMutation } ] = useMutation( UPDATE_PARTICIPANT_STATUS )

	/** Prepare the deletion. */
	const [deleteParticipant, { data: dataDeletion, error: errorDeletion, loading: loadingDeletion }] = useMutation(DELETE_PARTICIPANT)

	const renderCellDbId = (params) => {
		if (params.value === 0) {
			return "N/A"
		}

		return (
			<ButtonLink
				color="primary"
				size="small"
				to={ `${ pages[6].href }/${ params.value }` }
				variant="text">
				{ params.value }
			</ButtonLink>
		)
	}

	const columns = [
		{ field: "dbId", filterable: false, flex: 0.5, headerName: "#ID", renderCell: renderCellDbId, sortable: false, },
		{ field: "school", filterable: false, flex: 2, headerName: "Schule", hideable: false, },
		{ field: "registrations", filterable: false, flex: 1, headerName: "Anmeldungen (Insgesamt)", hideable: false, type: "number" },
		{ field: "accepted", filterable: false, flex: 1, headerName: "Zugelassen", hideable: false, type: "number", },
		{ field: "denied", filterable: false, flex: 1, headerName: "Abgelehnt", hideable: false, type: "number", },
	]

	useMemo(
		() => {
			if (data && data.event && data.event.participations.length > 0) {
				let count = 0
				let countAccepted = 0
				let countDenied = 0
				const schoolRows = []
				data.event.participations.forEach((participation) => {
					count += participation.participants.length
					let countAcceptedSchool = 0
					let countDeniedSchool = 0
					let countRegistrationsSchool = 0
					participation.participants.forEach((participant) => {
						if (participant.status === "ZUGELASSEN") {
							countAccepted += 1
							countAcceptedSchool += 1
						}
						else if (participant.status === "ABGELEHNT") {
							countDenied += 1
							countDeniedSchool += 1
						}

						countRegistrationsSchool += 1
					})
					const schoolsIndex = _.findIndex(schoolRows, { dbId: participation.school ? participation.school.id : 0 })
					if (schoolsIndex === -1) {
						schoolRows.push({
							accepted: countAcceptedSchool,
							dbId: participation.school ? participation.school.id : 0,
							denied: countDeniedSchool,
							id: participation.school ? participation.school.id : participation.id,
							registrations: countRegistrationsSchool,
							school: participation.school ? `${participation.school.name} (${participation.school.city})` : "N/A",
						})
					}
					else {
						schoolRows[schoolsIndex].accepted += countAcceptedSchool
						schoolRows[schoolsIndex].denied += countDeniedSchool
						schoolRows[schoolsIndex].registrations += countRegistrationsSchool
					}
				})
				setParticipantCount(count)
				setParticipantsAccepted(countAccepted)
				setParticipantsDenied(countDenied)
				setRows(schoolRows)
			}
		},
		[data]
	)

	return (
		<>
			<GatsbySeo title="Veranstaltung" />
			{ ( error || errorDeletion || errorMutation ) &&
				<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ errorAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ loading &&
				<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ true }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ !error && !errorDeletion && !errorMutation && data &&
				data.event &&
					<>
						<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
							<Grid item xs={ 12 }>
								<EventCard
									accepted={ participantsAccepted }
									count={ participantCount }
									denied={ participantsDenied }
									event={ data.event } />
							</Grid>
						</Grid>
						{ data.event.participations.length > 0 &&
							<>
								<Box className={ classes.content }>
									<EventDetailParticipantsTable
										dataDeletion={ dataDeletion }
										deleteParticipant={ deleteParticipant }
										errorDeletion={ errorDeletion }
										loading={ loadingDeletion || loadingMutation }
										participations={ data.event.participations }
										updateParticipantStatus={ updateParticipantStatus } />
								</Box>
								<ParticipantsToolbar refetch={ refetch } />
								<Card className={ classes.root } { ...other }>
									<CardContent className={ classes.contentCard }>
										<Box className={ classes.inner }>
											<DataGrid
												autoHeight
												columns={ columns }
												disableSelectionOnClick
												initialState={{
													sorting: {
														sortModel: [{ field: "accepted", sort: "desc" }],
													}
												}}
												loading={ loading }
												pageSize={ 25 }
												pagination
												rowCount={ rows.length }
												rows={ rows }
												rowsPerPageOptions={[ 10, 15, 25, 50, 100 ]} />
										</Box>
									</CardContent>
								</Card>
							</>
						}
					</>
			}
		</>
	)
}

export default EventDetail
