/**
 * authSlice.js
 *
 * @file Define a reducer for the authentication.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { createSlice } from '@reduxjs/toolkit'

/** Create and export the slice for the authentication. */
export const authSlice = createSlice( {
	initialState: {
		access: {
			expires: -1,
			token: ''
		},
		refresh: {
			expires: -1,
			token: ''
		}
	},
	name: 'auth',
	reducers: {
		/**
		 * Remove the access token from the store.
		 *
		 * @param {Object} state The current state.
		 * @returns {Object} The "mutated" state.
		 */
		cleanAccessToken: state => {
			state.access.expires = -1
			state.access.token   = ''
		},
		/**
		 * Remove the refresh token from the store.
		 *
		 * @param {Object} state The current state.
		 * @returns {Object} The "mutated" state.
		 */
		cleanRefreshToken: state => {
			state.refresh.expires = -1
			state.refresh.token   = ''
		},
		/**
		 * Set a a datetime when the access token will expire.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state.
		 */
		setAccessTokenExpires: ( state, action ) => {
			state.access.expires = action.payload
		},
		/**
		 * Set a a datetime when the refresh token will expire.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state.
		 */
		setRefreshTokenExpires: ( state, action ) => {
			state.refresh.expires = action.payload
		},
		/**
		 * Stores a new access token.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state.
		 */
		storeAccessToken: ( state, action ) => {
			state.access.token = action.payload
		},
		/**
		 * Stores a new refresh token.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state.
		 */
		storeRefreshToken: ( state, action ) => {
			state.refresh.token = action.payload
		}
	}
} )

/** Export the created action functions. */
export const {
	cleanAccessToken,
	cleanRefreshToken,
	setAccessTokenExpires,
	setRefreshTokenExpires,
	storeAccessToken,
	storeRefreshToken
} = authSlice.actions

/** Export the selector to receive the current state of the auth access token. */
export const selectAuthAccessToken = state => state.auth.access
/** Export the selector to receive the current state of the auth refresh token. */
export const selectAuthRefreshToken = state => state.auth.refresh

export default authSlice.reducer
