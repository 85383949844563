/**
 * SchoolsToolbar.jsx
 *
 * @file This file exports a toolbar which holds some actions for the
 * schools table.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import { Alert } from '@material-ui/lab'
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Snackbar,
} from '@material-ui/core'
import { gql, useMutation } from "@apollo/client"
import logging from "loglevel"
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { Refresh } from '@material-ui/icons'
import UploadFileIcon from "@mui/icons-material/UploadFile"

// internal imports
import { ButtonLink } from '../ButtonLink'
import { pages } from '../Sidebar'
import styles from './SchoolsToolbar.styles'

// Create styles
const useStyles = makeStyles(styles)

/** Define the query to upload a school file */
const UPLOAD_SCHOOLS = gql`
	mutation($file: Upload!) {
		uploadSchools(
			file: $file
			overwrite: true
			path: "/"
		)
	}
`

/**
 * Create the toolbar for the schools table.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const SchoolsToolbar = ({ refetch, ...other }) => {
	const classes = useStyles()

	/** Define a state that holds the error */
	const [error, setError] = useState({})
	/** Define a state that holds the file */
	const [file, setFile] = useState(null)
	/** Define a state that holds the open state of the upload dialog */
	const [open, setOpen] = useState(false)

	const [mutate, { loading }] = useMutation(UPLOAD_SCHOOLS, {
		onCompleted: (data) => {
			setOpen(false)
			setFile(null)
			refetch()
		},
		onError: (error) => {
			const logger = logging.getLogger("graphql")
			logger.error(error)
			setError(error)
		},
	})

	const onFileChange = (event) => {
		setFile(event.target.files[0])
	}

	return (
    	<Box className={ classes.root } { ...other }>
    		<Box className={ classes.row }>
        		<Box className={ classes.spacer } component="span" />
        		<IconButton
					className={ classes.refreshButton }
					onClick={ () => { refetch() } }>
					<Refresh />
				</IconButton>
				<IconButton
					className={ classes.uploadButton }
					onClick={ () => { setOpen(!open) } }>
					<UploadFileIcon />
				</IconButton>
        		<ButtonLink
					color="primary"
					to={ pages[6].create }
    				variant="contained">
        			Neue Projektschule anlegen
        		</ButtonLink>
				<Dialog onClose={ () => { setOpen(false) } } open={ open }>
					<DialogTitle>Datei hochladen</DialogTitle>
					<DialogContent>
						<DialogContentText>Laden Sie eine CSV-Datei hoch, die zur Aktualisierung der Schulen genutzt werden soll.</DialogContentText>
						<Box className={ classes.uploadRoot }>
							{ loading && <CircularProgress className={ classes.progress } size={ 50 } />}
							{ !_.isEmpty(error) && <DialogContentText>Ein unerwarteter Fehler trat auf. Bitte öff</DialogContentText> }
							{ !loading && _.isEmpty(error) && (
								<>
									<input
										accept="text/csv"
										className={ classes.input }
										id="upload-schools-input"
										onChange={ onFileChange }
										type="file" />
									<label htmlFor="upload-schools-input">
										<Button color="primary" component="span" variant={ file ? "text" : "contained" }>{ file ? file.name : "Upload" }</Button>
									</label>
								</>
							)}
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							disabled={ file === null }
							onClick={ () => {
								mutate({
									variables: {
										file
									},
								})
							} }
							variant="contained">
							Hochladen
						</Button>
					</DialogActions>
				</Dialog>
				<Snackbar open={ !_.isEmpty(error) }>
				<Alert elevation={ 6 } severity="error" variant="filled">Es trat ein unerwarteter Fehler auf. Bitte öffnen Sie die Konsole (Rechtsklick auf die Website -&gt; Element untersuchen -&gt; Konsole ) und beschreiben Sie dem Entwickler möglichst genau die Fehlermeldung.</Alert>
			</Snackbar>
    		</Box>
    	</Box>
	)
}

/**
 *
 */
SchoolsToolbar.propTypes = {
	refetch: PropTypes.func.isRequired
}

export default SchoolsToolbar
