/**
 * EventsToolbar.jsx
 *
 * @file This file exports a toolbar which holds some actions for the
 * events table.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import { Box, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Refresh } from '@material-ui/icons'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { pages } from '../Sidebar'
import styles from './EventsToolbar.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * Create the toolbar for the events table.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const EventsToolbar = ({ refetch, ...other }) => {
	const classes = useStyles()

	return (
    	<Box className={ classes.root } { ...other }>
    		<Box className={ classes.row }>
        		<Box className={ classes.spacer } component="span" />
        		<IconButton
					className={ classes.refreshButton }
					onClick={ () => { refetch() } }>
					<Refresh />
				</IconButton>
        		<ButtonLink
					color="primary"
					to={ pages[ 1 ].create }
    				variant="contained">
        			Neues Event anlegen
        		</ButtonLink>
    		</Box>
    	</Box>
	)
}

/**
 *
 */
EventsToolbar.propTypes = {
	refetch: PropTypes.func.isRequired,
}

export default EventsToolbar
