/**
 * valueFormatterSingleSelectShouldShowLabel.js
 *
 * @file This file exports a function with a DataGrid workaround based on the
 * issue {@link https://github.com/mui/mui-x/issues/4437}
 * @author Robin Walter <hello@robinwalter.me>
 */

/**
 * Show the label instead of the value in DataGrid table.
 *
 * @param {Object} params The params for the cell
 * @returns {string} The label to show
 */
export default ({ api, field, value }) => {
	const colDef = api.getColumn(field)
	const option = colDef.valueOptions.find(
		({ value: optionValue }) => value === optionValue
	)

	return option.label
}
