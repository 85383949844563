/**
 * wrapPageElement.jsx
 *
 * @file This component is injected via Gatsby's Browser & SSR APIs,
 * to wrap the root element of this web application with all necessary
 * providers.
 * @author Robin Walter <hello@robinwalter.me>
 */

import isIn from 'validator/es/lib/isIn'
import React from 'react'
import { useLocation } from '@reach/router'

// internal imports
import { MainLayout, MinimalLayout } from './components/Layout'

const WithLayout = ({ element, props }) => {
	const location = useLocation()

	const Layout =
		(isIn(location.pathname, [ '/404', '/sign-in' ])) ?
			MinimalLayout
		:
			MainLayout

	return (
        <Layout { ...props }>
			{ element }
		</Layout>
    )
}

// Pass all props (hence the ...props) to the base element component so it has access to things like pageContext or location
export default ({ element, props }) => <WithLayout element={ element } props={ props } />
