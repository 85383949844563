/**
 * index.jsx
 *
 * @file This file exports a view to list all news from the database.
 * Some client-only routes are configured as well.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box, Grid } from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import Lottie from 'lottie-react'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { Router } from '@reach/router'
import { useSelector } from 'react-redux'

// internal imports
/**!
 * Copyright © Erick Daniel Juarez Gil
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/ErickDanielJuarezGil
 * @link https://lottiefiles.com/9531-oops-something-went-wrong?lang=en
 */
import errorAnimationData from '../../assets/animations/ErrorAnimation.json'
import { NewsDetail } from '../../components/NewsDetail'
import { NewsEdit } from '../../components/NewsEdit'
import { NewsTable } from '../../components/NewsTable'
import { NewsToolbar } from '../../components/NewsToolbar'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'
import { redirectUnauthenticatedToSignIn } from '../../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'

// Create styles
const useStyles = makeStyles(theme => ({
	content: {
    	marginTop: theme.spacing(2)
	}
}))

/** Fetch all news from the GraphQL API. */
const GET_NEWS_LIST = gql`
	query NewsList(
		$expiresAfter: Date
		$expiresBefore: Date
		$first: Int!
		$page: Int
		$status: NewsStatus
		$title: String
	) {
		allNews(
			expiresAfter: $expiresAfter
			expiresBefore: $expiresBefore
			first: $first
			page: $page
			status: $status
			title: $title
		) {
			data {
                content
            	expiryDate
				id
            	status
                title
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`

/** Define a mutation to update a news status. */
const UPDATE_NEWS_STATUS = gql`
	mutation NewsStatus( $id: ID! $status: NewsStatus! ) {
		updateNews( input: {
			id: $id
			status: $status
		} ) {
			id
			status
		}
	}
`

/**
 * This component creates a view which lists all news.
 *
 * @returns {Node} The rendered component.
 */
const NewsList = () => {
	const classes = useStyles()

	/** Define a state holding the current page. */
	const [ page, setPage ] = useState( 1 )
	/** Define a state holding the maximum number of rows per page. */
	const [ rowsPerPage, setRowsPerPage ] = useState( 15 )
	/** Define a state holding the selected news. */
	const [ selectedNews, setSelectedNews ] = useState( [] )

	/** Execute the query and fetch the data from the GraphQL API. */
	const { data, error, fetchMore: more, loading, refetch } = useQuery( GET_NEWS_LIST, {
		fetchPolicy: 'network-only',
		variables: { first: rowsPerPage, page: page }
	} )
	/** Prepare the mutation. */
	const [updateNewsStatus, { error: errorMutation, loading: loadingMutation }] = useMutation(UPDATE_NEWS_STATUS)

	/**
	 * Execute a query to fetch more content from the GraphQL API.
	 */
	const fetchMore = (variables) => {
		let moreVariables = {}
		if (typeof(variables) !== "undefined") {
			moreVariables = variables
		}

		more({
			updateQuery: (prev, { fetchMoreResult }) => {
				const newData = fetchMoreResult.allNews.data
				const paginatorInfo = fetchMoreResult.allNews.paginatorInfo

				return newData.length ?
					{
						data: {
							allNews: {
								__typename: prev.allNews ? prev.allNews.__typename : 'News',
								data: [ ...newData ],
								paginatorInfo
							}
						}
					}
				: prev
			},
			variables: { first: rowsPerPage, page: page, ...moreVariables }
		})
	}

	return (
		<>
			<GatsbySeo title="Status Meldungen" />
			{ ( error || errorMutation ) &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ errorAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ ( loading || loadingMutation ) &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ !error && !errorMutation && data &&
				data.allNews &&
					data.allNews.data && data.allNews.paginatorInfo &&
						<>
							<NewsToolbar refetch={ refetch } />
							<Box className={ classes.content }>
								<NewsTable
									news={ data.allNews.data }
									fetchMore={ fetchMore }
									loading={ loading || loadingMutation }
									page={ page }
									paginatorInfo={ data.allNews.paginatorInfo }
									refetch={ refetch }
									rowsPerPage={ rowsPerPage }
									selectedNews={ selectedNews }
									setPage={ setPage }
									setRowsPerPage={ setRowsPerPage }
									setSelectedNews={ setSelectedNews }
									updateNewsStatus={ updateNewsStatus } />
							</Box>
						</>
			}
		</>
	)
}

/**
 * This component creates a view to list and manage all news from the database,
 * provided by the GraphQL API.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const NewsPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<>
				<GatsbySeo title="Status Meldungen" />
				<Router basepath="/news">
					<NewsDetail path="/:id" />
					<NewsEdit path="/:id/edit" />
					<NewsList path="/" />
				</Router>
			</>
		)
}

export default NewsPage
