/**
 * SidebarNav.jsx
 *
 * @file This file exports a component which creates the sidebar navigation.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash/core'
import { Add, PersonPinCircle } from '@material-ui/icons'
import {
	Box,
	List,
	ListItem,
	ListItemSecondaryAction,
	Tooltip
} from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { useLocation } from '@reach/router'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { IconButtonLink } from '../IconButtonLink'
import styles from './Sidebar.styles'

// Create styles
const useStyles = makeStyles( styles )

/**
 * Create the sidebar navigation.
 *
 * @param {object} props The component props.
 * @returns {Node} The rendered component.
 */
const SidebarNav = ( { pages } ) => {
	const classes = useStyles()

	const location = useLocation()

	return (
    	<List className={ classes.navRoot }>
    		{ pages.map( page => (
        		<ListItem
        			className={ classes.item }
        			disableGutters
        			key={ page.href }>
        			<ButtonLink
            			className={ clsx( classes.button, page.href === location.pathname && classes.active ) }
            			to={ page.href }>
            			<Box className={ classes.icon }>{ page.icon }</Box>
            			{ page.title }
        			</ButtonLink>
					{ !_.isEmpty( page.create ) &&
						<ListItemSecondaryAction>
							<Tooltip title="Erstellen">
								<IconButtonLink
									aria-label="Erstellen"
									className={ page.create === location.pathname && classes.active }
									edge="end"
									to={ page.create }>
									<Add />
								</IconButtonLink>
							</Tooltip>
						</ListItemSecondaryAction>
					}
					{ !_.isEmpty( page.me ) &&
						<ListItemSecondaryAction>
							<Tooltip title="Benutzerprofil">
								<IconButtonLink
									aria-label="Benutzerprofil"
									className={ page.me === location.pathname && classes.active }
									edge="end"
									to={ page.me }>
									<PersonPinCircle />
								</IconButtonLink>
							</Tooltip>
						</ListItemSecondaryAction>
					}
        		</ListItem>
    		) ) }
    	</List>
	)
}

/**
 *
 */
SidebarNav.propTypes = {
	pages: PropTypes.arrayOf(
		PropTypes.shape( {
			href: PropTypes.string.isRequired,
			icon: PropTypes.node.isRequired,
			title: PropTypes.string.isRequired
		} ).isRequired
	).isRequired
}

export default SidebarNav
