/**
 * NewsEdit.jsx
 *
 * @file This file exports a view to edit a specific news.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import { Alert } from '@material-ui/lab'
import {
	Button,
	Grid,
	Snackbar,
	Typography
} from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import logger from 'loglevel'
import Lottie from 'lottie-react'
import moment from 'moment'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useParams } from '@reach/router'

// internal imports
import { NewsEditor } from '../NewsEditor'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'

/** Query to get a specific news from the server. */
const GET_NEWS_DETAILS = gql`
	query GetNewsDetails( $id: ID! ) {
		news( id: $id ) {
			content
			expiryDate
			id
			status
			title
		}
	}
`

/** Mutation to update a specific news. */
const UPDATE_NEWS = gql`
	mutation UpdateNews(
		$content: String
		$expiryDate: Date
		$id: ID!
		$status: NewsStatus
		$title: String
	) {
		updateNews( input: {
			content: $content
			expiryDate: $expiryDate
			id: $id
			status: $status
			title: $title
		} ) {
			id
		}
	}
`

/**
 * This component creates a view to edit existing news.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const NewsEdit = () => {
	const params = useParams()

	/** Define a state holding the content of the news. */
	const [ content, setContent ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state disabling the submit button. */
	const [ disableSubmit, setDisableSubmit ] = useState( true )
	/** Define a state holding the datetime when the news expires. */
	const [ expiryDate, setExpiryDate ] = useState( moment().add( 14, 'd' ) )
	/** Define a state holding an error. */
	const [ error, setError ] = useState( {} )
	/** Define a state holding the ID of the newly created news. */
	const [ ID, setID ] = useState( '0' )
	/** Define a state holding the original news data. */
	const [ originalNews, setOriginalNews ] = useState( {} )
	/** Define a state holding the status of the new news. */
	const [ status, setStatus ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the title of the new news. */
	const [ title, setTitle ] = useState( {
		error: false,
		value: '',
		verified: false
	} )

	/** Fetch all venues from the GraphQL Server. */
	const { error: queryError, loading: queryLoading } = useQuery( GET_NEWS_DETAILS, {
		displayName: 'GetNewsDetailsQuery',
		onCompleted: data => {
			if ( data ) {
				if ( data.news ) {
					setOriginalNews( data.news )
					setContent( {
						error: false,
						value: data.news.content,
						verified: true
					} )
					setExpiryDate( moment( data.news.expiryDate ) )
					setID( data.news.id )
					setStatus( {
						error: false,
						value: data.news.status,
						verified: true
					} )
					setTitle( {
						error: false,
						value: data.news.title,
						verified: true
					} )
				}
			}
		},
		ssr: false,
		variables: {
			id: params.id
		}
	} )

	/** Update a specific existing `News` on the server. */
	const [ updateNews, { loading: mutationLoading } ] = useMutation( UPDATE_NEWS, {
		onCompleted: (data) => {
			navigate(`/news/${ ID }`)
		},
		onError: (error) => {
			logger.error(error)
			setError(error)
		}
	} )

	/** Disable the save button, if necessary. */
	useEffect(
		() => {
			if (
				!content.verified ||
				!status.verified ||
				!title.verified ||
				!newsWasMutated()
			) {
				setDisableSubmit( true )
			}
			else {
				setDisableSubmit( false )
			}
		},
		[
			content,
			expiryDate,
			status,
			title
		]
	)

	/**
	 * Checks if the original data was mutated.
	 *
	 * @returns {boolean}
	 */
	const newsWasMutated = () => {
		let mutated = false

		if ( !_.isEmpty( originalNews ) ) {
			if ( !_.isEqual( originalNews.content, content.value ) ) mutated = true

			if ( !expiryDate.isSame( originalNews.expiryDate ) ) mutated = true

			if ( !_.isEqual( originalNews.status, status.value ) ) mutated = true

			if ( !_.isEqual( originalNews.title, title.value ) ) mutated = true
		}

		return mutated
	}

	return (
		<>
			<GatsbySeo title="Status Meldung bearbeiten" />
			<Typography color="textSecondary" gutterBottom variant="h3">Status Meldung bearbeiten</Typography>
			{ queryLoading &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ true }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 'auto',
							}} />
					</Grid>
				</Grid>
			}
			{ !queryLoading &&
				<NewsEditor
					content={ content }
					expiryDate={ expiryDate }
					handleContentChanged={ setContent }
					handleExpiryDateChanged={ setExpiryDate }
					handleStatusChanged={ setStatus }
					handleTitleChanged={ setTitle }
					status={ status }
					title={ title }>
					<Grid item md={ 6 } xs={ 12 }>
						<Typography gutterBottom variant="h5">Änderungen speichern</Typography>

						<Typography paragraph variant="body2">Bitte überprüfen Sie Ihre Eingaben noch einmal, bevor Sie die Änderungen in der Datenbank speichern.</Typography>
						<Button
							color="secondary"
							disabled={ disableSubmit }
							onClick={ e => {
								e.preventDefault()

								if ( newsWasMutated() ) {
									updateNews( {
										variables: {
											content: content.value,
											expiryDate: expiryDate.format( "YYYY-MM-DD" ),
											id: ID,
											status: _.isEmpty( status.value ) ? null : status.value,
											title: title.value
										}
									} )
								}
							} }
							variant="contained">
							Änderungen speichern
						</Button>
					</Grid>
				</NewsEditor>
			}

			<Snackbar open={ !_.isEmpty( error ) }>
				<Alert elevation={ 6 } severity="error" variant="filled">Es trat ein unerwarteter Fehler auf. Bitte öffnen Sie die Konsole (Rechtsklick auf die Website -&gt; Element untersuchen -&gt; Konsole ) und beschreiben Sie dem Entwickler möglichst genau die Fehlermeldung.</Alert>
			</Snackbar>
		</>
	)
}

export default NewsEdit
