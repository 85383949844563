/**
 * IconButtonLink.jsx
 *
 * @file This component creates a wrapper around the `Link` component provided by Gatsby
 * and the `IconButton` component provided by Material-UI.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { IconButton } from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'

/**
 * This component creates an new `Link` component and works as
 * a wrapper around the `Link` component from Gatsby and
 * `IconButton` from Material-UI.
 *
 * @param {Object} props – The component props.
 * @param {Object} ref   – A reference to the DOM element.
 * @returns {Node} The rendered component which is able to forward
 * a reference to the DOM element.
 */
const IconButtonLink = React.forwardRef( ( props, ref ) => (
	<IconButton component={ GatsbyLink } ref={ ref } { ...props } />
) )

export default IconButtonLink
