/**
 * NewsEditor.jsx
 *
 * @file This file exports the form to publish news.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from "lodash"
import {
	Box,
	Chip,
	Grid,
	MenuItem,
	TextField,
	Typography
} from '@material-ui/core'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import validator from 'validator'

// internal imports
import styles from './NewsEditor.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * This component creates a form to publish news.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered component.
 */
const NewsEditor = ({
	content,
	children,
	expiryDate,
	handleContentChanged,
	handleExpiryDateChanged,
	handleStatusChanged,
	handleTitleChanged,
	status,
	title
}) => {
	const classes = useStyles()

	const NEWS_STATUS = [
		{ label: "Entwurf", value: "DRAFT" },
		{ label: "Veröffentlicht", value: "PUBLISH" },
	]

	/** Validate the input data and check if there are any mistakes. */
	useEffect(() => {
		// Validate the content
		if ( validator.isEmpty( content.value ) ) {
			handleContentChanged( {
				...content,
				error: true,
				verified: false
			} )
		}
		else {
			handleContentChanged( {
				...content,
				error: false,
				verified: true
			} )
		}
		// Validate the news status; Only necessary if status isn't empty
		if ( !validator.isEmpty( status.value ) ) {
			if ( !validator.isIn( status.value, [
				'DRAFT',
				'PUBLISH'
			] ) ) {
				handleStatusChanged( {
					...status,
					error: true,
					verified: false
				} )
			}
			else {
				handleStatusChanged( {
					...status,
					error: false,
					verified: true
				} )
			}
		}
		// Validate the event title
		if ( validator.isEmpty( title.value ) ) {
			handleTitleChanged( {
				...title,
				error: true,
				verified: false
			} )
		}
		else {
			handleTitleChanged( {
				...title,
				error: false,
				verified: true
			} )
		}
	}, [ content.value, status.value, title.value ])

	return (
		<Box autoComplete="off" className={ classes.container } component="form" noValidate>
			<Grid container spacing={ 4 }>
				<Grid item xs={ 12 }>
					<Typography gutterBottom variant="h5">Worüber möchten Sie informieren?</Typography>
					<TextField
						className={ classes.formControl }
						error={ title.error }
						id="news-title"
						InputProps={{
							className: classes.input,
						}}
						label="Überschrift"
						onChange={ e => {
							handleTitleChanged( {
								...title,
								value: e.target.value
							} )
						} }
						required
						value={ title.value } />
					<TextField
						className={ classes.formControl }
						error={ status.error }
						helperText="Wählen Sie den Veröffentlichungsstatus für die Status Meldung aus."
						id="news-status"
						InputProps={{
							className: classes.input,
						}}
						label="Veröffentlichungsstatus"
						onChange={ e => {
							handleStatusChanged( {
								...status,
								value: e.target.value
							} )
						} }
						required
						select
						SelectProps={ {
							autoWidth: true,
							renderValue: (selected) => (
								<Chip className={ classes.chip } color="secondary" label={ _.find(NEWS_STATUS, (status) => status.value === selected ).label } />
							)
						} }
						value={ status.value }>
						{ NEWS_STATUS.map(({ label, value }) => (
							<MenuItem key={ value } value={ value }>{ label }</MenuItem>
						))}
					</TextField>
					<DatePicker
						className={ classes.formControl }
						disableMaskedInput
						disablePast
						format="[Am] DD. MMMM YYYY"
						helperText="An wechem Datum läuft die Status Meldung ab."
						label="Ablaufdatum"
						onChange={ handleExpiryDateChanged }
						renderInput={ (props) => <TextField className={ classes.pickersInput } {...props} /> }
						required
						value={ expiryDate } />
					<TextField
						className={ classes.formControl }
						error={ content.error }
						id="news-content"
						InputProps={{
							className: classes.input,
						}}
						label="Nachricht"
						multiline
						onChange={ e => {
							handleContentChanged( {
								...content,
								value: e.target.value
							} )
						} }
						required
						rowsMax={ 10 }
						value={ content.value } />
				</Grid>
				{ children }
			</Grid>
		</Box>
	)
}

/**
 *
 */
NewsEditor.propTypes = {
	content: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	children: PropTypes.node,
	expiryDate: PropTypes.instanceOf( moment ).isRequired,
	handleContentChanged: PropTypes.func.isRequired,
	handleExpiryDateChanged: PropTypes.func.isRequired,
	handleStatusChanged: PropTypes.func.isRequired,
	handleTitleChanged: PropTypes.func.isRequired,
	status: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.oneOf( [
			'',
			'DRAFT',
			'PUBLISH'
		] ).isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	title: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired
}

export default NewsEditor
