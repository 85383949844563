/**
 * NewsToolbar.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	refreshButton: {
    	marginRight: theme.spacing(1),
	},
	root: {},
	row: {
		alignItems: "center",
		display: "flex",
    	height: 42,
    	marginTop: theme.spacing(1),
	},
	spacer: {
    	flexGrow: 1,
	},
	updateButton: {
    	marginRight: theme.spacing(1),
	},
})
