/**
 * settingsSlice.js
 *
 * @file Define a reducer for the user settings feature.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { createSlice } from '@reduxjs/toolkit'
import isIn from 'validator/es/lib/isIn'
import logging from 'loglevel'

const logger = logging.getLogger('redux')

export const settingsInitialState = {
	fontSize: 16,
	uiMode: 'system',
}

/** Create and export the slice for the app drawer. */
export const settingsSlice = createSlice( {
	initialState: settingsInitialState,
	name: 'settings',
	reducers: {
		/**
		 * Adjust the font size to a custom value.
		 *
		 * @param {Object} state The current state of the font size.
		 * @returns {Object} The new state of the font size.
		 */
		adjustFontSize: (state, action) => {
			if (typeof action.payload.size === 'undefined' || typeof action.payload.size !== 'number') {
				logger.warn('settings.adjustFontSize was called w/ wrong payload')
				return
			}
			else if ( action.payload.size < 12 || action.payload.size > 20 ) {
				logger.warn('settings.adjustFontSize was called with number out of range')
				return
			}
			state.fontSize = action.payload.size
		},
		/**
		 * Switch the current state of the color mode.
		 *
		 * @param {Object} state The current state of the color mode.
		 * @returns {Object} The new state of the color mode.
		 */
		switchUIMode: (state, action) => {
			if (typeof action.payload.mode === 'undefined') {
				logger.warn('settings.switchUIMode was called without a payload')
				return
			}
			else if ( !isIn( action.payload.mode, ['system', 'light', 'dark'] ) ) {
				logger.warn('settings.switchUIMode was called with unknown option. Ignoring the input')
				return
			}
			state.uiMode = action.payload.mode
		},
	},
} )

/** Export the created action functions. */
export const {
	adjustFontSize,
	switchUIMode,
} = settingsSlice.actions

/** Export the selector to receive the current state of the font size setting. */
export const selectSettingFontSize = state => state.settings.fontSize

/** Export the selector to receive the current state of the color mode setting. */
export const selectSettingUIMode = state => state.settings.uiMode

export default settingsSlice.reducer
