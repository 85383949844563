/**
 * Profile.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default theme => ( {
	avatar: {
		height: 60,
    	width: 60
	},
	name: {
    	marginTop: theme.spacing( 1 )
	},
	root: {
		alignItems: 'center',
    	display: 'flex',
    	flexDirection: 'column',
    	minHeight: 'fit-content'
	}
} )
