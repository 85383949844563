/**
 * participants.jsx
 *
 * @file This file exports a view to list all participants from the database.
 * Some client-only routes are configured as well.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import { Box, Grid } from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import Lottie from 'lottie-react'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { Router } from '@reach/router'
import { useSelector } from 'react-redux'

// internal imports
/**!
 * Copyright © Erick Daniel Juarez Gil
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/ErickDanielJuarezGil
 * @link https://lottiefiles.com/9531-oops-something-went-wrong?lang=en
 */
import errorAnimationData from '../assets/animations/ErrorAnimation.json'
import { ParticipantsTable } from '../components/ParticipantsTable'
import { ParticipantsToolbar } from '../components/ParticipantsToolbar'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../assets/animations/LoadingAnimation.json'
import { redirectUnauthenticatedToSignIn } from '../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../state'

// Create styles
const useStyles = makeStyles((theme) => ({
	content: {
    	marginTop: theme.spacing(2),
	},
}))

/** Define a mutation to delete a specific participant */
const DELETE_PARTICIPANT = gql`
	mutation deleteParticipant( $id: ID! ) {
		deleteParticipant( id: $id ) {
			id
		}
	}
`

/** Fetch all participants (with their participation data) from the GraphQL API. */
const GET_PARTICIPANT_LIST = gql`
	query participantList(
		$first: Int!
		$page: Int
		$status: ParticipantStatus
		$type: ParticipantType
	) {
		participants(
			first: $first
			page: $page
			sort: { column: "id" order: DESC }
			status: $status
			type: $type
		) {
			data {
				appellation
				confirmed
            	firstName
				id
            	lastName
				participation {
					event {
						eventID
                    	id
                	}
					id
					school {
						city
						id
						name
					}
            	}
            	status
            	type
				uuid
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`

/** Define a mutation to update a participant's status. */
const UPDATE_PARTICIPANT_STATUS = gql`
	mutation participantStatus( $id: ID! $status: ParticipantStatus! ) {
		updateParticipantStatus( id: $id status: $status ) {
			id
			status
			uuid
		}
	}
`

/**
 * This component creates a view which lists all participants.
 *
 * @returns {Node} The rendered component.
 */
const ParticipantList = () => {
	const classes = useStyles()

	/** Define a state holding the current page. */
	const [page, setPage] = useState(1)
	/** Define a state holding the maximum number of rows per page. */
	const [rowsPerPage, setRowsPerPage] = useState(15)
	/** Define a state holding the selected participants. */
	const [selectedParticipants, setSelectedParticipants] = useState([])

	/** Execute the query and fetch the data from the GraphQL API. */
	const { data, error, fetchMore: more, loading, refetch } = useQuery(GET_PARTICIPANT_LIST, {
		fetchPolicy: "network-only",
		variables: { first: rowsPerPage, page: page },
	})
	/** Prepare the mutation. */
	const [updateParticipantStatus, { error: errorMutation, loading: loadingMutation }] = useMutation(UPDATE_PARTICIPANT_STATUS)
	/** Prepare the deletion. */
	const [deleteParticipant, { data: dataDeletion, error: errorDeletion, loading: loadingDeletion }] = useMutation(DELETE_PARTICIPANT)

	/**
	 * Execute a query to fetch more content from the GraphQL API.
	 *
	 * @param {Object} variables More variables to add to the query
	 */
	const fetchMore = (variables) => {
		let moreVariables = {}
		if (typeof(variables) !== "undefined") {
			moreVariables = variables
		}

		more({
			updateQuery: (prev, { fetchMoreResult }) => {
				const newData = fetchMoreResult.participants.data
				const paginatorInfo = fetchMoreResult.participants.paginatorInfo

				return newData.length ?
					{
						data: {
							participants: {
								__typename: !_.isEmpty(prev.participants) ? prev.participants.__typename : 'Participant',
								data: [...newData],
								paginatorInfo,
							},
						},
					}
				: prev
			},
			variables: { first: rowsPerPage, page: page, ...moreVariables }
		})
	}

	return (
		<>
			<GatsbySeo title="Teilnehmer" />
			{(error || errorMutation) &&
				<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ errorAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{(loading || loadingMutation) &&
				<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{!error && !errorMutation && data &&
				data.participants &&
					data.participants.data && data.participants.paginatorInfo &&
						<>
							<ParticipantsToolbar refetch={ refetch } />
							<Box className={ classes.content }>
								<ParticipantsTable
									dataDeletion={ dataDeletion }
									deleteParticipant={ deleteParticipant }
									errorDeletion={ errorDeletion }
									fetchMore={ fetchMore }
									loading={ loading || loadingMutation }
									page={ page }
									paginatorInfo={ data.participants.paginatorInfo }
									participants={ data.participants.data }
									refetch={ refetch }
									rowsPerPage={ rowsPerPage }
									selectedParticipants={ selectedParticipants }
									setPage={ setPage }
									setRowsPerPage={ setRowsPerPage }
									setSelectedParticipants={ setSelectedParticipants }
									updateParticipantStatus={ updateParticipantStatus } />
							</Box>
						</>
			}
		</>
	)
}

/**
 * This component creates a view to list and manage all participants from the database,
 * provided by the GraphQL API.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const ParticipantsPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<Router basepath="/participants">
				<Box path="/:id" />
				<Box path="/:id/edit" />
				<ParticipantList path="/" />
			</Router>
		)
}

export default ParticipantsPage
