/**
 * publish.jsx
 *
 * @file This file exports a view to create new news.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash/core'
import { Alert } from '@material-ui/lab'
import {
	Button,
	Grid,
	Snackbar,
	Typography
} from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation } from '@apollo/client'
import logger from 'loglevel'
import moment from 'moment'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// internal imports
import { NewsEditor } from '../../components/NewsEditor'
import { redirectUnauthenticatedToSignIn } from '../../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'

/** Mutation to create a new news. */
const ADD_NEWS = gql`
	mutation AddNews(
		$content: String!
		$expiryDate: Date!
		$status: NewsStatus
		$title: String!
	) {
		createNews( input: {
			content: $content
			expiryDate: $expiryDate
			status: $status
			title: $title
		} ) {
			id
		}
	}
`

/**
 * This component creates a view to create new news.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const PublishNewsPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	/** Define a state holding the content of the news. */
	const [ content, setContent ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state disabling the submit button. */
	const [ disableSubmit, setDisableSubmit ] = useState( true )
	/** Define a state holding the datetime when the news expires. */
	const [ expiryDate, setExpiryDate ] = useState( moment().add( 14, 'd' ) )
	/** Define a state holding an error. */
	const [ error, setError ] = useState( {} )
	/** Define a state holding the ID of the newly created news. */
	const [ ID, setID ] = useState( '0' )
	/** Define a state holding the status of the new news. */
	const [ status, setStatus ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the title of the new news. */
	const [ title, setTitle ] = useState( {
		error: false,
		value: '',
		verified: false
	} )

	/** Create a new `News` on the server. */
	const [ addNews ] = useMutation( ADD_NEWS, {
		onCompleted: (data) => {
			setID(data.createNews.id)

			navigate(`/news/${ data.createNews.id }`)
		},
		onError: (error) => {
			logger.error(error)
			setError(error)
		},
		variables: {
			content: content.value,
			expiryDate: expiryDate.format( "YYYY-MM-DD" ),
			status: _.isEmpty( status.value ) ? null : status.value,
			title: title.value
		}
	} )

	/** Disable the save button, if necessary. */
	useEffect(
		() => {
			if (
				!content.verified ||
				!status.verified ||
				!title.verified
			) {
				setDisableSubmit(true)
			}
			else {
				setDisableSubmit(false)
			}
		},
		[
			content.verified,
			status.verified,
			title.verified
		]
	)

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<>
				<GatsbySeo title="Neue Status Meldung veröffentlichen" />
				<Typography color="textSecondary" gutterBottom variant="h3">Neue Status Meldung veröffentlichen</Typography>
				<NewsEditor
					content={ content }
					expiryDate={ expiryDate }
					handleContentChanged={ setContent }
					handleExpiryDateChanged={ setExpiryDate }
					handleStatusChanged={ setStatus }
					handleTitleChanged={ setTitle }
					status={ status }
					title={ title }>
					<Grid item md={ 6 } xs={ 12 }>
						<Typography gutterBottom variant="h5">Status Meldung speichern</Typography>

						<Typography paragraph variant="body2">Bitte überprüfen Sie Ihre Eingaben noch einmal, bevor Sie sie in der Datenbank speichern.</Typography>

						<Button
							color="secondary"
							disabled={ disableSubmit }
							onClick={ e => {
								e.preventDefault()

								addNews()
							} }
							variant="contained">
							Status Meldung speichern
						</Button>
					</Grid>
				</NewsEditor>

				<Snackbar open={ !_.isEmpty( error ) }>
					<Alert elevation={ 6 } severity="error" variant="filled">Es trat ein unerwarteter Fehler auf. Bitte öffnen Sie die Konsole (Rechtsklick auf die Website -&gt; Element untersuchen -&gt; Konsole ) und beschreiben Sie dem Entwickler möglichst genau die Fehlermeldung.</Alert>
				</Snackbar>
			</>
		)
}

export default PublishNewsPage
