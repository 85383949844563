/**
 * index.jsx
 *
 * @file This file exports a view to list all events from the database.
 * Some client-only routes are configured as well.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box, Grid } from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import Lottie from 'lottie-react'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { Router } from '@reach/router'
import { useSelector } from 'react-redux'

// internal imports
/**!
 * Copyright © Erick Daniel Juarez Gil
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/ErickDanielJuarezGil
 * @link https://lottiefiles.com/9531-oops-something-went-wrong?lang=en
 */
import errorAnimationData from '../../assets/animations/ErrorAnimation.json'
import { EventDetail } from '../../components/EventDetail'
import { EventEdit } from '../../components/EventEdit'
import { EventsTable } from '../../components/EventsTable'
import { EventsToolbar } from '../../components/EventsToolbar'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'
import { redirectUnauthenticatedToSignIn } from '../../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'

// Create styles
const useStyles = makeStyles((theme) => ({
	content: {
    	marginTop: theme.spacing(2),
	},
}))

/** Fetch all events (without their second date) from the GraphQL API. */
const GET_EVENT_LIST = gql`
	query eventList(
		$first: Int!
		$initialRegistrationPeriodAfter: Date
		$initialRegistrationPeriodBefore: Date
		$page: Int
		$regionalNetwork: String
		$startAfter: Date
		$startBefore: Date
		$status: EventStatus
		$type: String
	) {
		eventsWithoutSecondDates(
			first: $first
			initialRegistrationPeriodAfter: $initialRegistrationPeriodAfter
			initialRegistrationPeriodBefore: $initialRegistrationPeriodBefore
			page: $page
			regionalNetwork: $regionalNetwork
			sort: { column: "id" order: DESC }
			startAfter: $startAfter
			startBefore: $startBefore
			status: $status
			type: $type
		) {
			data {
            	eventID
            	fee
				id
				initialRegistrationPeriod
            	lecturer {
                	id
                	name
            	}
            	lecturerFallback
            	regionalNetworks
				start
            	status
            	type {
					description
					type
				}
            	venue {
                	id
                	name
            	}
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`

/** Fetch all event types from the GraphQL API. */
const GET_EVENT_TYPES = gql`
	query eventTypes {
		allEventTypes {
			type
			description
		}
	}
`

/** Define a mutation to update an events status. */
const UPDATE_EVENT_STATUS = gql`
	mutation eventStatus( $id: ID! $status: EventStatus! ) {
		updateEventStatus( id: $id status: $status ) {
			id
			status
		}
	}
`

/**
 * This component creates a view which lists all events (without their second dates).
 *
 * @returns {Node} The rendered component.
 */
const EventList = () => {
	const classes = useStyles()

	/** Define a state holding the current page. */
	const [page, setPage] = useState(1)
	/** Define a state holding the maximum number of rows per page. */
	const [rowsPerPage, setRowsPerPage] = useState(15)
	/** Define a state holding the selected events. */
	const [selectedEvents, setSelectedEvents] = useState([])

	/** Execute the query and fetch the data from the GraphQL API. */
	const { data, error, fetchMore: more, loading, refetch } = useQuery(GET_EVENT_LIST, {
		fetchPolicy: 'network-only',
		variables: { first: rowsPerPage, page: page },
	})
	const { data: dataTypes, error: errorTypes, loading: loadingTypes } = useQuery(GET_EVENT_TYPES, {
		fetchPolicy: "cache-and-network",
	})
	/** Prepare the mutation. */
	const [updateEventStatus, { error: errorMutation, loading: loadingMutation }] = useMutation(UPDATE_EVENT_STATUS)

	/**
	 * Execute a query to fetch more content from the GraphQL API.
	 *
	 * @param {Object} variables More variables to add to the query
	 */
	const fetchMore = (variables) => {
		let moreVariables = {}
		if (typeof(variables) !== "undefined") {
			moreVariables = variables
		}

		more({
			updateQuery: (prev, { fetchMoreResult }) => {
				const newData = fetchMoreResult.eventsWithoutSecondDates.data
				const paginatorInfo = fetchMoreResult.eventsWithoutSecondDates.paginatorInfo

				return newData.length ?
					{
						data: {
							eventsWithoutSecondDates: {
								__typename: prev.eventsWithoutSecondDates ? prev.eventsWithoutSecondDates.__typename : 'Event',
								data: [...newData],
								paginatorInfo,
							},
						},
					}
				: prev
			},
			variables: { first: rowsPerPage, page: page, ...moreVariables }
		})
	}

	return (
		<>
			<GatsbySeo title="Veranstaltungen" />
			{(error || errorTypes || errorMutation) &&
				<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ errorAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{(loading || loadingTypes) &&
				<Grid alignItems="center" container direction="column" justifyContent="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '50%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{!error && !errorMutation && data && dataTypes &&
				data.eventsWithoutSecondDates && dataTypes.allEventTypes &&
					data.eventsWithoutSecondDates.data && data.eventsWithoutSecondDates.paginatorInfo &&
						<>
							<EventsToolbar refetch={ refetch } />
							<Box className={ classes.content }>
								<EventsTable
									events={ data.eventsWithoutSecondDates.data }
									eventTypes={ dataTypes.allEventTypes }
									fetchMore={ fetchMore }
									loading={ loading || loadingMutation }
									page={ page }
									paginatorInfo={ data.eventsWithoutSecondDates.paginatorInfo }
									refetch={ refetch }
									rowsPerPage={ rowsPerPage }
									selectedEvents={ selectedEvents }
									setPage={ setPage }
									setRowsPerPage={ setRowsPerPage }
									setSelectedEvents={ setSelectedEvents }
									updateEventStatus={ updateEventStatus } />
							</Box>
						</>
			}
		</>
	)
}

/**
 * This component creates a view to list and manage all events from the database,
 * provided by the GraphQL API.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const EventsPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<Router basepath="/events">
				<EventDetail path="/:id" />
				<EventEdit path="/:id/edit" />
				<EventList path="/" />
			</Router>
		)
}

export default EventsPage
