/**
 * darkPalette.js
 *
 * @file The custom theme configuration for the dark palette.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { blue, blueGrey, grey } from '@mui/material/colors'
import { darken, lighten } from '@mui/material/styles'

const white = '#FFFFFF'
const black = '#1B1B1B'

export default {
	common: {
		black,
		white: darken(white, 0.1),
	},
	contrastThreshold: 3,
	primary: {
		contrastText: darken(white, 0.1),
		main: lighten('#009EE3', 0.1),
	},
	secondary: {
		contrastText: darken(white, 0.1),
		main: lighten('#C41239', 0.1),
	},
	success: {
		contrastText: darken(white, 0.1),
		main: lighten('#4CAF50', 0.1), // '#379605',
	},
	info: {
		contrastText: darken(white, 0.1),
		main: lighten('#03A9F4', 0.1), // '#808080',
	},
	warning: {
		contrastText: darken(white, 0.1),
		main: lighten('#FF9800', 0.1), // '#FFB700',
	},
	error: {
		contrastText: darken(white, 0.1),
		main: lighten('#EF5350', 0.1), // '#C51239',
	},
	text: {
		primary: darken(white, 0.1),
		secondary: lighten('#6C757D', 0.5),
		link: blue[ 800 ],
	},
	background: {
		default: darken('#009EE3', 0.85),
		paper: darken('#009EE3', 0.85),
	},
	tonalOffset: 0.2,
	icon: blueGrey[ 600 ],
	divider: grey[ 200 ],
	mode: 'dark',
}
