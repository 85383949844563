/**
 * authSessionSlice.js
 *
 * @file Define a reducer for the session authentication.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { createSlice } from '@reduxjs/toolkit'

/** Create and export the slice for the session authentication. */
export const authSessionSlice = createSlice( {
	initialState: {
		accessToken: '',
		stayLoggedIn: false
	},
	name: 'authSession',
	reducers: {
		/**
		 * Remove the access token from the session store.
		 *
		 * @param {Object} state The current state.
		 * @returns {Object} The "mutated" state.
		 */
		cleanAccessTokenFromSession: state => {
			state.accessToken = ''
		},
		/**
		 * Sets the decision whether to stay logged in or not.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state.
		 */
		setStayLoggedIn: ( state, action ) => {
			state.stayLoggedIn = action.payload
		},
		/**
		 * Stores a new access token for the session.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state.
		 */
		storeAccessTokenForSession: ( state, action ) => {
			state.accessToken = action.payload
		}
	}
} )

/** Export the created action functions. */
export const {
	cleanAccessTokenFromSession,
	setStayLoggedIn,
	storeAccessTokenForSession
} = authSessionSlice.actions

/** Export the selector to receive the current state of the session-auth access token. */
export const selectAuthSessionAccessToken = state => state.authSession.accessToken
/** Export the selector to receive the current state of the session-auth stay logged in value. */
export const selectAuthSessionStayLoggedIn = state => state.authSession.stayLoggedIn

export default authSessionSlice.reducer
