/**
 * EventDetail.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	content: {
    	marginTop: theme.spacing(2),
	},
	contentCard: {
    	padding: 0,
	},
	inner: {
    	minWidth: 1050,
	},
	root: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
})
