/**
 * NewsTable.jsx
 *
 * @file This file exports a table which holds the news.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from "lodash"
import {
	Box,
	Card,
	CardContent,
} from '@material-ui/core'
import {
	DataGrid,
	getGridDateOperators,
	getGridSingleSelectOperators,
	getGridStringOperators,
	GridToolbar,
} from "@mui/x-data-grid"
import { Edit } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { IconButtonLink } from '../IconButtonLink'
import { pages } from '../Sidebar'
import styles from './NewsTable.styles'

// Create styles
const useStyles = makeStyles(styles)

const NEWS_STATUS = [
	{ label: "Entwurf", value: "DRAFT" },
	{ label: "Veröffentlicht", value: "PUBLISH" },
]

/**
 * Create the news table.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const NewsTable = ({
	news,
	fetchMore,
	loading,
	page,
	paginatorInfo,
	refetch,
	rowsPerPage,
	selectedNews,
	setPage,
	setRowsPerPage,
	setSelectedNews,
	updateNewsStatus,
	...other
}) => {
	const classes = useStyles()

	/** Define a state that holds the current filter variables */
	const [filter, setFilter] = useState({})
	/** Define a state that holds the rows currently displayed */
	const [rows, setRows] = useState([])

	const dataGridGetActions = (params) => [
		<IconButtonLink
			to={ `${pages[5].href}/${params.id}/edit` }>
			<Edit />
		</IconButtonLink>,
	]

	const filterOperatorsDate = getGridDateOperators().filter(
		(operator) => operator.value === "after" || operator.value === "before",
	)

	const filterOperatorsSingleSelect = getGridSingleSelectOperators().filter(
		(operator) => operator.value === "is",
	)

	const filterOperatorsString = getGridStringOperators().filter(
		(operator) => operator.value === "contains",
	)

	const renderCellDbId = (params) => {

		return (
			<ButtonLink
				color="primary"
				size="small"
				to={ `${pages[5].href}/${params.row.id}` }
				variant="text">
				{ params.value }
			</ButtonLink>
		)
	}

	const valueFormatterDate = (params) => moment(params.value).format("dd DD.MM.YYYY")

	const columns = [
		{ field: "dbId", filterable: false, flex: 0.25, headerName: "#ID", renderCell: renderCellDbId, },
		{ editable: true, field: "status", filterOperators: filterOperatorsSingleSelect, flex: 1, headerName: "Status", type: "singleSelect", valueOptions: NEWS_STATUS, }, /** TODO: Check {@link https://github.com/mui/mui-x/issues/4437} for fix, since this field MUST be filterable */
		{ field: "expiryDate", filterOperators: filterOperatorsDate, flex: 0.75, headerName: "Ablaufdatum", type: "date", valueFormatter: valueFormatterDate, },
		{ field: "title", filterOperators: filterOperatorsString, flex: 1, headerName: "Titel" },
		{ field: "actions", flex: 0.5, getActions: dataGridGetActions, headerName: "Bearbeiten", type: "actions", },
	]

	useMemo(
		() => {
			const newRows = []
			news.forEach((message) => {
				newRows.push({
					dbId: message.id,
					expiryDate: moment(message.expiryDate),
					id: message.id,
					status: message.status,
					title: message.title,
				})
			})
			setRows(newRows)
		},
		[news]
	)

	/**
	 * Change the current page, fetch more data from the server.
	 *
	 * @param {number} newPage The new page.
	 */
	 const handlePageChange = (newPage) => {
    	setPage(newPage)

		fetchMore(filter)
	}

	/**
	 * Change the number of total rows per page, fetch more data from the server
	 * and unselect all participants.
	 *
	 * @param {number} newPageSize The new page size (rows per page).
	 */
	 const handleRowsPerPageChange = (newPageSize) => {
    	setRowsPerPage(newPageSize)

		fetchMore(filter)
	}

	/**
	 * Change the status of a specific event.
	 *
	 * @param {Object} newMessage The altered news object.
	 * @param {Object} oldMessage The original news object.
	 */
	 const handleStatusChange = useCallback(
		(newMessage, oldMessage) => {
			if (_.isEqual(newMessage, oldMessage)) {
				return oldMessage
			}
			else {
				updateNewsStatus({
					variables: {
						id: newMessage.id,
						status: newMessage.status,
					},
				})
				return newMessage
			}
		},
		[updateNewsStatus]
	)

	/**
	 * Change the filter for the events.
	 *
	 * @param {Object} filterModel The filter to applay.
	 */
	 const handleFilterChange = useCallback(
		(filterModel) => {
			// DataGrid (free) only supports single filters
			let filterItem = filterModel.items[0]

			if (typeof filterItem === "undefined" || typeof filterItem.value === "undefined") {
				refetch({
					expiresAfter: undefined,
					expiresBefore: undefined,
					first: rowsPerPage,
					page: page,
					status: undefined,
					title: undefined,
				})
			}
			else {
				let variables = {}
				switch (filterItem.columnField) {
					case "expiryDate":
						if (filterItem.operatorValue === "after") {
							variables = {
								expiresAfter: filterItem.value,
								expiresBefore: undefined,
								status: undefined,
								title: undefined,
							}
						}
						else {
							variables = {
								expiresAfter: undefined,
								expiresBefore: filterItem.value,
								status: undefined,
								title: undefined,
							}
						}
						break
					case "status":
						variables = {
							expiresAfter: undefined,
							expiresBefore: undefined,
							status: filterItem.value,
							title: undefined,
						}
						break
					case "title":
						variables = {
							expiresAfter: undefined,
							expiresBefore: undefined,
							status: undefined,
							title: `%${filterItem.value}%`,
						}
						break
					default:
						variables = {
							expiresAfter: undefined,
							expiresBefore: undefined,
							status: undefined,
							title: undefined,
						}
				}

				setFilter(variables)
				refetch({
					first: rowsPerPage,
					page: page,
					...variables,
				})
			}
		},
		[refetch]
	)

	return (
    	<Card className={ classes.root } { ...other }>
    		<CardContent className={ classes.content }>
        		<Box className={ classes.inner }>
					<DataGrid
						autoHeight
						checkboxSelection
						columns={ columns }
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
						experimentalFeatures={{
							newEditingApi: true,
						}}
						filterMode="server"
						keepNonExistentRowsSelected
						loading={ loading }
						onFilterModelChange={ handleFilterChange }
						onPageChange={ handlePageChange }
						onPageSizeChange={ handleRowsPerPageChange }
						onSelectionModelChange={ (newSelectionModel) => {
							setSelectedNews(newSelectionModel)
						} }
						page={ page }
						pageSize={ rowsPerPage }
						pagination
						paginationMode="server"
						processRowUpdate={ handleStatusChange }
						rowCount={ paginatorInfo.lastPage }
						rows={ rows }
						rowsPerPageOptions={[ 10, 15, 25, 50, 100 ]}
						selectionModel={ selectedNews } />
    			</Box>
    		</CardContent>
    	</Card>
	)
}

/**
 *
 */
NewsTable.propTypes = {
	news: PropTypes.arrayOf( PropTypes.shape( {
		content: PropTypes.string.isRequired,
		expiryDate: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		start: PropTypes.string,
		status: PropTypes.oneOf( NEWS_STATUS ).isRequired,
		title: PropTypes.string.isRequired
	} ) ).isRequired,
	fetchMore: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	page: PropTypes.number.isRequired,
	paginatorInfo: PropTypes.exact( {
		__typename: PropTypes.string,
		count: PropTypes.number.isRequired,
		currentPage: PropTypes.number.isRequired,
		firstItem: PropTypes.number,
		hasMorePages: PropTypes.bool.isRequired,
		lastItem: PropTypes.number,
		lastPage: PropTypes.number.isRequired,
		perPage: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired
	} ).isRequired,
	refetch: PropTypes.func.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	selectedNews: PropTypes.array.isRequired,
	setPage: PropTypes.func.isRequired,
	setRowsPerPage: PropTypes.func.isRequired,
	setSelectedNews: PropTypes.func.isRequired,
	updateNewsStatus: PropTypes.func.isRequired,
}

export default NewsTable
