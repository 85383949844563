module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://admin.jugend-debattiert-nrw.de","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito:400,400i,600,600i"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"dangerouslySetAllPagesToNoFollow":true,"dangerouslySetAllPagesToNoIndex":true,"description":"Jugend debattiert ist ein Wettbewerb für Schülerinnen und Schüler, der auf Initiative und unter Schirmherrschaft des Bundespräsidenten von der Gemeinnützigen Hertie-Stiftung, der Robert Bosch Stiftung, der Stiftung Mercator und der Heinz Nixdorf Stiftung in Kooperation mit den Kultusministerien, der Kultusministerkonferenz und den Parlamenten der Länder durchgeführt wird.","language":"de","titleTemplate":"%s | Admin | Jugend debattiert NRW"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFFFFF","description":"Jugend debattiert ist ein Wettbewerb für Schülerinnen und Schüler, der auf Initiative und unter Schirmherrschaft des Bundespräsidenten von der Gemeinnützigen Hertie-Stiftung, der Robert Bosch Stiftung, der Stiftung Mercator und der Heinz Nixdorf Stiftung in Kooperation mit den Kultusministerien, der Kultusministerkonferenz und den Parlamenten der Länder durchgeführt wird.","display":"standalone","icon":"src/assets/images/icon-512x512.png","lang":"de","name":"Admin | Jugend debattiert NRW","short_name":"JD Admin NRW","start_url":"/","theme_color":"#009EE3","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"788b607dc495905fa055b0867708f9a1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/sign-in"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
