/**
 * store.js
 *
 * @file This module exports the redux store of this app.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { CookieStorage } from 'redux-persist-cookie-storage'
import localforage from 'localforage'
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE
} from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

// internal imports
import {
	appDrawerReducer,
	authReducer,
	authSessionReducer,
	cookieReducer,
	settingsReducer
} from './slices'

/** Try to get the "saved" user preference to decide how and if the store should persist. */
const userPref =
	typeof Cookies.get( `${ process.env.REDUX_PERSIST_COOKIE_KEY_PREFIX }cookie` ) !== 'undefined' ?
		JSON.parse( Cookies.get( `${ process.env.REDUX_PERSIST_COOKIE_KEY_PREFIX }cookie` ) )
	:
		undefined

/** Set the persist configuration for the `root` key. */
const rootPersistConfig = {
	blacklist: [ // do not persist these keys
		'appDrawer',
		'auth',
		'authSession',
		'cookie'
	],
	debug: process.env.NODE_ENV === 'development' ? true : false, // true -> verbose logs
	key: 'root',                                     // the key for the persist
	keyPrefix: process.env.GATSBY_REDUX_PERSIST_KEY_PREFIX, // will be prefixed to the storage key
	storage:                                         // the storage adapter, following the AsyncStorage api
		typeof userPref !== 'undefined' && userPref.saved && userPref.preferences ?
			localforage.createInstance( {
				description: 'Store to persist the `root` keys of redux',
				driver: [ localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE ],
				name: process.env.GATSBY_SITE_NAME,
				size: 4980736,
				storeName: `${ process.env.GATSBY_REDUX_PERSIST_KEY_PREFIX }root`,
				version: '2.0'
			} )
		:
			sessionStorage,
	version: 2,   // the state version as an integer (defaults to -1)
	whitelist: [ // only persist these keys
		'settings',
	]
}

/** Set the persist configuration for the `cookie` key. */
const cookiePersistConfig = {
	blacklist: [],                                          // do not persist these keys
	debug: process.env.NODE_ENV === 'development' ? true : false, // true -> verbose logs
	key: 'cookie',                                          // the key for the persist
	keyPrefix: process.env.GATSBY_REDUX_PERSIST_COOKIE_KEY_PREFIX, // will be prefixed to the storage key
	storage: new CookieStorage( Cookies, {                  // the storage adapter, following the AsyncStorage api
		expiration: {
			default: Number( process.env.GATSBY_SITE_COOKIE_BANNER_EXPIRES || 14 )
		},
		setCookieOptions: {
			domain: process.env.GATSBY_SITE_COOKIE_BANNER_DOMAIN,
			path: process.env.GATSBY_SITE_COOKIE_BANNER_PATH,
			sameSite: process.env.GATSBY_SITE_COOKIE_BANNER_SAME_SITE,
			secure: process.env.GATSBY_SITE_COOKIE_BANNER_SECURE
		}
	} ),
	version: 1,  // the state version as an integer (defaults to -1)
	whitelist: [ // only persist these keys
		'allowedSocial',
		'essential',
		'preferences',
		'saved',
		'social',
		'stats'
	]
}

/** Set the persist configuration for the `auth` key. */
const authPersistConfig = {
	blacklist: [],                                        // do not persist these keys
	debug: process.env.NODE_ENV === 'development' ? true : false, // true -> verbose logs
	key: 'store',                                         // the key for the persist
	keyPrefix: process.env.GATSBY_REDUX_PERSIST_AUTH_KEY_PREFIX, // will be prefixed to the storage key
	storage: localforage.createInstance( {
		description: 'Store to persist the `auth` keys of redux',
		driver: [ localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE ],
		name: `${ process.env.GATSBY_SITE_NAME } (Auth)`,
		size: 4980736,
		storeName: `${ process.env.GATSBY_REDUX_PERSIST_AUTH_KEY_PREFIX }root`,
		version: '2.0'
	} ),
	version: 2,  // the state version as an integer (defaults to -1)
	whitelist: [ // only persist these keys
		'access',
		'refresh'
	]
}

/** Set the persist configuration for the `authSession` key. */
const authSessionPersistConfig = {
	blacklist: [],                                                // do not persist these keys
	debug: process.env.NODE_ENV === 'development' ? true : false, // true -> verbose logs
	key: 'session',                                               // the key for the persist
	keyPrefix: process.env.GATSBY_REDUX_PERSIST_AUTH_SESSION_KEY_PREFIX, // will be prefixed to the storage key
	storage: sessionStorage,
	version: 1,  // the state version as an integer (defaults to -1)
	whitelist: [ // only persist these keys
		'accessToken',
		'stayLoggedIn'
	]
}

/** Create the `root` reducer with the nested persist reducer. */
const rootReducer = combineReducers( {
	appDrawer: appDrawerReducer,
	auth: persistReducer( authPersistConfig, authReducer ),
	authSession: persistReducer( authSessionPersistConfig, authSessionReducer ),
	cookie: persistReducer( cookiePersistConfig, cookieReducer ),
	settings: settingsReducer
} )

/** Create the store and configure the middleware to ignore persist actions. */
const store = configureStore( {
	middleware: getDefaultMiddleware( {
		serializableCheck: {
			ignoredActions: [ FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE ]
		}
	} ),
	reducer: persistReducer( rootPersistConfig, rootReducer )
} )
export default store

/** Create and export the persist store. */
export const persistor = persistStore( store )
