/**
 * Layout.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export const name = 'JDAdminLayout'

export default theme => ( {
	content: {
		flexGrow: 1,
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
    	flexGrow: 1,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	paddingTop: {
		paddingTop: theme.spacing(2),
	},
	rootMain: {
		display: 'flex',
		height: '100%',
		paddingTop: 56,
		width: '100%',
    	[theme.breakpoints.up('sm')]: {
    		paddingTop: 64,
    	},
	},
	rootMinimal: {
		display: 'flex',
		height: '100vh',
		paddingTop: 64,
		width: '100%',
	},
	shiftContent: {
    	paddingLeft: 250,
	},
} )
