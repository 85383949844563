/**
 * NewsEditor.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	chip: {
		margin: theme.spacing(0.25),
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	container: {
		padding: theme.spacing(4, 8),
	},
	formControl: {
		display: "block",
		margin: theme.spacing(3),
		maxWidth: 475,
		minWidth: 120,
		width: "100%",
	},
	input: {
		margin: [[0, "auto"]],
		maxWidth: 600,
		minWidth: 120,
		width: "100%",
	},
	pickersInput: {
		margin: theme.spacing(3),
		maxWidth: 475,
		minWidth: 120,
		width: "100%",
	},
})
