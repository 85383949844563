/**
 * Contact.jsx
 *
 * @file This file exports the form part for the contact data.
 * @author Robin Walter <hello@robinwalter.me>
 */

import {
	Grid,
	TextField,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import validator from 'validator'

// internal imports
import styles from './SchoolEditor.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * This component creates a form part for the contact data.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered component.
 */
const Contact = ({
	email,
	handleEmailChanged,
	handleTelephoneChanged,
	handleWebsiteChanged,
	md,
	telephone,
	website,
	xs
}) => {
	const classes = useStyles()

	/** Validate the input data and check if there are any mistakes. */
	useEffect( () => {
		// Validate the email
		if ( !validator.isEmpty( email.value ) && !validator.isEmail( email.value ) ) {
			handleEmailChanged( {
				...email,
				error: true,
				verified: false
			} )
		}
		else {
			handleEmailChanged( {
				...email,
				error: false,
				verified: true
			} )
		}
		// Validate the website
		if ( !validator.isEmpty( website.value ) && !validator.isURL( website.value ) ) {
			handleWebsiteChanged( {
				...website,
				error: true,
				verified: false
			} )
		}
		else {
			handleWebsiteChanged( {
				...website,
				error: false,
				verified: true
			} )
		}
	}, [ email.value, website.value ] )

	return (
		<Grid item md={ md || 6 } xs={ xs || 12 }>
			<Typography gutterBottom variant="h5">3. Kontaktdaten</Typography>
			<TextField
				className={ classes.formControl }
				error={ email.error }
				helperText="Geben Sie die E-Mail Adresse der Schule ein."
				id="school-email"
				InputProps={{
					className: classes.input,
				}}
				label="E-Mail Adresse"
				onChange={ e => {
					handleEmailChanged( {
						...email,
						value: e.target.value
					} )
				} }
				value={ email.value } />
			<TextField
				className={ classes.formControl }
				error={ telephone.error }
				helperText="Geben Sie die Telefonnummer der Schule ein."
				id="school-telephone"
				InputProps={{
					className: classes.input,
				}}
				label="Telefonnummer"
				onChange={ e => {
					handleTelephoneChanged( {
						...telephone,
						value: e.target.value
					} )
				} }
				value={ telephone.value } />
			<TextField
				className={ classes.formControl }
				error={ website.error }
				helperText="Geben Sie die Adresse der Website der Schule ein."
				id="school-website"
				InputProps={{
					className: classes.input,
				}}
				label="Website"
				onChange={ e => {
					handleWebsiteChanged( {
						...website,
						value: e.target.value
					} )
				} }
				value={ website.value } />
		</Grid>
	)
}

/**
 *
 */
Contact.propTypes = {
	email: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	handleEmailChanged: PropTypes.func.isRequired,
	handleTelephoneChanged: PropTypes.func.isRequired,
	handleWebsiteChanged: PropTypes.func.isRequired,
	md: PropTypes.number,
	telephone: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	website: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	xs: PropTypes.number
}

export default Contact
