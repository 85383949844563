/**
 * ButtonLink.jsx
 *
 * @file This component creates a wrapper around the `Link` component provided by Gatsby
 * and the `Button` component provided by Material-UI.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Button } from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'

/**
 * This component creates an new `Link` component and works as
 * a wrapper around the `Link` component from Gatsby and
 * `Button` from Material-UI.
 *
 * @param {Object} props – The component props.
 * @param {Object} ref   – A reference to the DOM element.
 * @returns {Node} The rendered component which is able to forward
 * a reference to the DOM element.
 */
const ButtonLink = React.forwardRef( ( props, ref ) => (
	<Button component={ GatsbyLink } ref={ ref } { ...props } />
) )

export default ButtonLink
