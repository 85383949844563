/**
 * Topbar.jsx
 *
 * @file This file exports a component which creates the topbar of
 * the UI.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash/core'
import { AppBar, Badge, Box, Hidden, IconButton, Toolbar } from '@material-ui/core'
import { navigate } from 'gatsby'
import { Input, Menu, NotificationsOutlined } from '@material-ui/icons'
import logging from 'loglevel'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useApolloClient } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

// internal imports
import {
	cleanAccessToken,
	cleanAccessTokenFromSession,
	cleanRefreshToken,
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'
import { Link } from '../Link'
import styles from './Topbar.styles'

// Create styles
const useStyles = makeStyles( styles )

/**
 * Create the topbar.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered component.
 */
const Topbar = ( { minimal, onSidebarOpen, ...other } ) => {
	const classes = useStyles()

	const client = useApolloClient()

	const logger = logging.getLogger("auth")

	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	const dispatch = useDispatch()

	/** Define a state holding the system notifications for the user. */
	const [ notifications ] = useState([])

	useEffect(
		() => {
			if ( _.isEmpty( authAccessToken.token ) && _.isEmpty( authSessionAccessToken ) ) {
				client.resetStore()
				navigate( '/sign-in' )
			}
		},
		[ authAccessToken, authSessionAccessToken, client ]
	)

	return (
    	<AppBar
    		className={ classes.root }
    		color="primary"
    		position="fixed"
			{ ...other }>
    		<Toolbar>
        		<Link to="/">
					<StaticImage
						alt="Jugend debattiert Icon"
						className={ classes.icon }
						layout="fixed"
						placeholder="blurred"
						src="../../assets/images/icon-512x512.png"
						width={ 36 } />
        		</Link>
				{ !minimal && (
					<>
						<Box className={ classes.flexGrow } />
						<Hidden mdDown>
							{ false &&
								<IconButton color="inherit">
									<Badge
										badgeContent={ notifications.length }
										color="primary"
										variant="dot">
										<NotificationsOutlined />
									</Badge>
								</IconButton>
							}
							<IconButton
								className={ classes.signOutButton }
								color="inherit"
								onClick={ () => {
									logger.debug('Logout')
									if ( authSessionStayLoggedIn ) {
										logger.debug('Stay logged in')
										dispatch( cleanAccessToken() )
										dispatch( cleanRefreshToken() )
									}
									else {
										logger.debugger('session log in')
										dispatch( cleanAccessTokenFromSession() )
									}
								} }>
								<Input />
							</IconButton>
						</Hidden>
						<Hidden lgUp>
							<IconButton
								color="inherit"
        						onClick={ onSidebarOpen }>
								<Menu />
							</IconButton>
						</Hidden>
					</>
				) }
    		</Toolbar>
    	</AppBar>
	)
}

/**
 *
 */
Topbar.propTypes = {
	minimal: PropTypes.bool,
	onSidebarOpen: PropTypes.func
}

export default Topbar
