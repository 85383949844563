/**
 * ParticipantsToolbar.jsx
 *
 * @file This file exports a component which creates a toolbar which provides
 * functionality to manage the participants.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import { Box, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { Refresh } from '@material-ui/icons'

// internal imports
import styles from './ParticipantsToolbar.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * Create the toolbar.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered component.
 */
const ParticipantsToolbar = ({ refetch, ...other }) => {
	const classes = useStyles()

	return (
    	<Box
    		className={ classes.root }
			{ ...other }>
    		<Box className={ classes.row }>
        		<Box className={ classes.spacer } component="span" />
				<IconButton
					className={ classes.refreshButton }
					onClick={ () => { refetch() } }>
					<Refresh />
				</IconButton>
        		<Button
        			color="primary"
					disabled
    				variant="contained">
        			Neuen Teilnehmer anlegen
        		</Button>
    		</Box>
    	</Box>
	)
}

/**
 *
 */
ParticipantsToolbar.propTypes = {
	refetch: PropTypes.func.isRequired,
}

export default ParticipantsToolbar
