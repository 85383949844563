/**
 * SchoolsToolbar.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	input: {
		display: "none",
	},
	progress: {
		color: theme.palette.secondary.main,
	},
	refreshButton: {
    	marginRight: theme.spacing(1),
	},
	root: {},
	row: {
		alignItems: "center",
		display: "flex",
    	height: 42,
    	marginTop: theme.spacing(1),
	},
	spacer: {
    	flexGrow: 1,
	},
	updateButton: {
    	marginRight: theme.spacing(1),
	},
	uploadButton: {
    	marginRight: theme.spacing(1),
	},
	uploadRoot: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100%",
		marginBottom: theme.spacing(2),
		textAlign: "center",
		width: "100%",
	},
})
