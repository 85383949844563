/**
 * StatusBullet.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	error: {
    	backgroundColor: theme.palette.error.main,
	},
	info: {
    	backgroundColor: theme.palette.info.main,
	},
	lg: {
    	height: theme.spacing(3),
    	width: theme.spacing(3),
	},
	md: {
    	height: theme.spacing(2),
    	width: theme.spacing(2),
	},
	neutral: {
    	backgroundColor: theme.palette.neutral,
	},
	primary: {
    	backgroundColor: theme.palette.primary.main,
	},
	root: {
		borderRadius: '50%',
    	display: 'inline-block',
    	flexGrow: 0,
    	flexShrink: 0,
	},
	sm: {
    	height: theme.spacing(1),
    	width: theme.spacing(1),
	},
	success: {
    	backgroundColor: theme.palette.success.main,
	},
	warning: {
    	backgroundColor: theme.palette.warning.main,
	},
})
