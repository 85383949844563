/**
 * SchoolEditor.jsx
 *
 * @file This file exports the form to edit schools.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import Address from './Address'
import BasicInformation from './BasicInformation'
import Contact from './Contact'
import styles from './SchoolEditor.styles'

// Create styles
const useStyles = makeStyles( styles )

/**
 * This component creates a form to edit schools.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered component.
 */
const SchoolEditor = ({
	address,
	children,
	city,
	comment,
	email,
	firstParticipation,
	handleAddressChanged,
	handleCityChanged,
	handleCommentChanged,
	handleEmailChanged,
	handleFirstParticipationChanged,
	handleNameChanged,
	handlePostalCodeChanged,
	handleRegionalNetworkChanged,
	handleTelephoneChanged,
	handleTypeChanged,
	handleWebsiteChanged,
	name,
	postalCode,
	regionalNetwork,
	telephone,
	type,
	website
}) => {
	const classes = useStyles()

	const itemMD =  6
	const itemXS = 12

	return (
		<Box autoComplete="off" className={ classes.container } component="form" noValidate>
			<Grid container spacing={ 4 }>

				<BasicInformation
					firstParticipation={ firstParticipation }
					handleFirstParticipationChanged={ handleFirstParticipationChanged }
					handleNameChanged={ handleNameChanged }
					handleRegionalNetworkChanged={ handleRegionalNetworkChanged }
					handleTypeChanged={ handleTypeChanged }
					md={ itemMD }
					name={ name }
					regionalNetwork={ regionalNetwork }
					type={ type }
					xs={ itemXS } />

				<Address
					address={ address }
					city={ city }
					handleAddressChanged={ handleAddressChanged }
					handleCityChanged={ handleCityChanged }
					handlePostalCodeChanged={ handlePostalCodeChanged }
					md={ itemMD }
					postalCode={ postalCode }
					xs={ itemXS } />

				<Contact
					email={ email }
					handleEmailChanged={ handleEmailChanged }
					handleTelephoneChanged={ handleTelephoneChanged }
					handleWebsiteChanged={ handleWebsiteChanged }
					md={ itemMD }
					telephone={ telephone }
					website={ website }
					xs={ itemXS } />

				<Grid item md={ itemMD || 6 } xs={ itemXS || 12 }>
					<Typography gutterBottom variant="h5">4. Kommentar zur Schule</Typography>
					<TextField
						className={ classes.formControl }
						error={ comment.error }
						id="school-comment"
						InputProps={ {
							className: classes.input
						} }
						label="Kommentar"
						multiline
						onChange={ e => {
							handleCommentChanged( {
								...comment,
								value: e.target.value
							} )
						} }
						rowsMax={ 10 }
						value={ comment.value } />
				</Grid>
				{ children }
			</Grid>
		</Box>
	)
}

/**
 *
 */
SchoolEditor.propTypes = {
	address: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	children: PropTypes.node,
	city: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	comment: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	email: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	firstParticipation: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	handleAddressChanged: PropTypes.func.isRequired,
	handleCityChanged: PropTypes.func.isRequired,
	handleCommentChanged: PropTypes.func.isRequired,
	handleEmailChanged: PropTypes.func.isRequired,
	handleFirstParticipationChanged: PropTypes.func.isRequired,
	handleNameChanged: PropTypes.func.isRequired,
	handlePostalCodeChanged: PropTypes.func.isRequired,
	handleRegionalNetworkChanged: PropTypes.func.isRequired,
	handleTelephoneChanged: PropTypes.func.isRequired,
	handleTypeChanged: PropTypes.func.isRequired,
	handleWebsiteChanged: PropTypes.func.isRequired,
	name: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	postalCode: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	regionalNetwork: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	telephone: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	type: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	website: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired
}

export default SchoolEditor
