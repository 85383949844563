/**
 * SchoolsTable.jsx
 *
 * @file This file exports a table which holds the schools.
 * @author Robin Walter <hello@robinwalter.me>
 */

import {
	Box,
	Card,
	CardContent,
} from '@material-ui/core'
import {
	DataGrid,
	getGridStringOperators,
	GridToolbar,
} from "@mui/x-data-grid"
import { Edit } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { IconButtonLink } from '../IconButtonLink'
import { pages } from '../Sidebar'
import styles from './SchoolsTable.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * Create the schools table.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const SchoolsTable = ({
	loading,
	page,
	paginatorInfo,
	refetch,
	rowsPerPage,
	schools,
	selectedSchools,
	setPage,
	setRowsPerPage,
	setSelectedSchools,
	...other
}) => {
	const classes = useStyles()

	/** Define a state that holds the current filter variables */
	const [filter, setFilter] = useState({})
	/** Define a state that holds the rows currently displayed */
	const [rows, setRows] = useState([])

	const dataGridGetActions = (params) => [
		<IconButtonLink
			to={ `${pages[6].href}/${params.id}/edit` }>
			<Edit />
		</IconButtonLink>,
	]

	const filterOperatorsString = getGridStringOperators().filter(
		(operator) => operator.value === "contains",
	)

	const filterOperatorsStringIs = getGridStringOperators().filter(
		(operator) => operator.value === "equals",
	)

	const renderCellDbId = (params) => {

		return (
			<ButtonLink
				color="primary"
				size="small"
				to={ `${pages[6].href}/${params.row.id}` }
				variant="text">
				{ params.value }
			</ButtonLink>
		)
	}

	const columns = [
		{ field: "dbId", filterable: false, flex: 0.25, headerName: "#ID", renderCell: renderCellDbId, },
		{ field: "name", filterOperators: filterOperatorsString, flex: 1, headerName: "Name", },
		{ field: "type", filterOperators: filterOperatorsString, flex: 0.75, headerName: "Schulart", },
		{ field: "postalCode", filterOperators: filterOperatorsStringIs, flex: 0.5, headerName: "Postleitzahl", },
		{ field: "city", filterOperators: filterOperatorsString, flex: 0.75, headerName: "Ort", },
		{ field: "regionalNetwork", filterOperators: filterOperatorsString, flex: 0.5, headerName: "Regionalverbund", },
		{ field: "firstParticipation", filterOperators: filterOperatorsString, flex: 0.5, headerName: "Erste Teilnahme", },
		{ field: "actions", flex: 0.5, getActions: dataGridGetActions, headerName: "Bearbeiten", type: "actions", },
	]

	useMemo(
		() => {
			const newRows = []
			schools.forEach((school) => {
				newRows.push({
					city: school.city,
					dbId: school.id,
					firstParticipation: school.firstParticipation,
					id: school.id,
					name: school.name,
					postalCode: school.postalCode,
					regionalNetwork: school.regionalNetwork,
					type: school.type,
				})
			})
			setRows(newRows)
		},
		[schools]
	)

	/**
	 * Change the filter for the events.
	 *
	 * @param {Object} filterModel The filter to applay.
	 */
	 const handleFilterChange = useCallback(
		(filterModel) => {
			// DataGrid (free) only supports single filters
			let filterItem = filterModel.items[0]

			if (typeof filterItem === "undefined" || typeof filterItem.value === "undefined") {
				refetch({
					city: undefined,
					firstParticipation: undefined,
					name: undefined,
					postalCode: undefined,
					regionalNetwork: undefined,
					type: undefined,
				})
			}
			else {
				let variables = {}
				switch (filterItem.columnField) {
					case "city":
						variables = {
							city: `%${filterItem.value}%`,
							firstParticipation: undefined,
							name: undefined,
							postalCode: undefined,
							regionalNetwork: undefined,
							type: undefined,
						}
						break
					case "firstParticipation":
						variables = {
							city: undefined,
							firstParticipation: `%${filterItem.value}%`,
							name: undefined,
							postalCode: undefined,
							regionalNetwork: undefined,
							type: undefined,
						}
						break
					case "name":
						variables = {
							city: undefined,
							firstParticipation: undefined,
							name: `%${filterItem.value}%`,
							postalCode: undefined,
							regionalNetwork: undefined,
							type: undefined,
						}
						break
					case "postalCode":
						variables = {
							city: undefined,
							firstParticipation: undefined,
							name: undefined,
							postalCode: filterItem.value,
							regionalNetwork: undefined,
							type: undefined,
						}
						break
					case "regionalNetwork":
						variables = {
							city: undefined,
							firstParticipation: undefined,
							name: undefined,
							postalCode: undefined,
							regionalNetwork: `%${filterItem.value}%`,
							type: undefined,
						}
						break
					case "type":
						variables = {
							city: undefined,
							firstParticipation: undefined,
							name: undefined,
							postalCode: undefined,
							regionalNetwork: undefined,
							type: `%${filterItem.value}%`,
						}
						break
					default:
						variables = {
							city: undefined,
							firstParticipation: undefined,
							name: undefined,
							postalCode: undefined,
							regionalNetwork: undefined,
							type: undefined,
						}
				}

				setFilter(variables)
				refetch({
					first: rowsPerPage,
					page: page,
					...variables,
				})
			}
		},
		[refetch]
	)

	return (
    	<Card className={ classes.root } { ...other }>
    		<CardContent className={ classes.content }>
        		<Box className={ classes.inner }>
					<DataGrid
						autoHeight
						checkboxSelection
						columns={ columns }
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
						filterMode="server"
						initialState={{
							pagination: {
								pageSize: 25,
							},
						}}
						loading={ loading }
						onFilterModelChange={ handleFilterChange }
						onSelectionModelChange={ (newSelectionModel) => {
							setSelectedSchools(newSelectionModel)
						} }
						pagination
						rowCount={ rows.length }
						rows={ rows }
						rowsPerPageOptions={[ 10, 15, 25, 50, 100 ]}
						selectionModel={ selectedSchools } />
    			</Box>
    		</CardContent>
    	</Card>
	)
}

/**
 *
 */
SchoolsTable.propTypes = {
	loading: PropTypes.bool,
	refetch: PropTypes.func.isRequired,
	schools: PropTypes.arrayOf( PropTypes.shape( {
		address: PropTypes.string,
		city: PropTypes.string.isRequired,
		comment: PropTypes.string,
		email: PropTypes.string,
		firstParticipation: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		postalCode: PropTypes.string.isRequired,
		regionalNetwork: PropTypes.string.isRequired,
		telephone: PropTypes.string,
		type: PropTypes.string.isRequired,
		website: PropTypes.string
	} ) ).isRequired,
	selectedSchools: PropTypes.array.isRequired,
	setSelectedSchools: PropTypes.func.isRequired
}

export default SchoolsTable
