/**
 * sign-in.jsx
 *
 * @file This file exports a view which gives the user the opportunity
 * to sign-in.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash/core'
import { ArrowBack, Close, Error, Visibility, VisibilityOff } from '@material-ui/icons'
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	Link,
	OutlinedInput,
	Paper,
	Snackbar,
	SnackbarContent,
	TextField,
	Typography
} from '@material-ui/core'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import validate from 'validate.js'

// internal imports
import {
	claimAccessTokenWithPassword,
	redirectAuthenticatedToDashboard
} from '../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn,
	setAccessTokenExpires,
	setRefreshTokenExpires,
	setStayLoggedIn,
	storeAccessToken,
	storeAccessTokenForSession,
	storeRefreshToken
} from '../state'

/** `validate` schema. */
const schema = {
	email: {
		email: true,
    	length: {
    		maximum: 64
    	},
    	presence: { allowEmpty: false, message: 'is required' }
	},
	password: {
		length: {
    		maximum: 128
    	},
    	presence: { allowEmpty: false, message: 'is required' }
	}
}

// Create styles
const useStyles = makeStyles( theme => ( {
	checkbox: {
		marginTop: theme.spacing(2),
	},
	content: {
		display: 'flex',
    	flexDirection: 'column',
    	height: '100%',
	},
	contentBody: {
		alignItems: 'center',
		display: 'flex',
    	flexGrow: 1,
    	[theme.breakpoints.down('md')]: {
    		justifyContent: 'center',
    	},
	},
	contentContainer: {},
	contentHeader: {
		alignItems: 'center',
    	display: 'flex',
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(2),
    	paddingRight: theme.spacing(2),
    	paddingTop: theme.spacing(5),
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	form: {
		flexBasis: 700,
		paddingBottom: 125,
    	paddingLeft: 100,
    	paddingRight: 100,
    	[theme.breakpoints.down('sm')]: {
    		paddingLeft: theme.spacing(2),
    		paddingRight: theme.spacing(2),
    	},
	},
	grid: {
		height: '100%',
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		marginRight: theme.spacing(1),
		opacity: 0.9,
	},
	logoImage: {
    	marginLeft: theme.spacing(4),
	},
	margin: {
		margin: theme.spacing(1),
	},
	message: {
		alignItems: 'center',
    	display: 'flex',
	},
	quote: {
		alignSelf: 'center',
		margin: 'auto',
	},
	quoteContainer: {
		backgroundColor: theme.palette.white,
		borderRadius: 0,
		display: 'flex',
		height: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
    	[theme.breakpoints.down('md')]: {
    		display: 'none',
    	},
	},
	quoteGrid: {
		height: '100%',
	},
	root: {
    	backgroundColor: theme.palette.background.default,
    	height: '100%',
	},
	signInButton: {
    	margin: theme.spacing(2, 0),
	},
 	title: {
    	marginTop: theme.spacing(3),
	},
	textField: {
    	marginTop: theme.spacing(2),
	},
} ) )

/**
 * This component creates a view which gives the user the opportunity
 * to sign-in.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const SignInPage = ({ data }) => {
	const classes = useStyles()

	const client = useApolloClient()

	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	const dispatch = useDispatch()

	/** Define a state holding the form values. */
	const [ formState, setFormState ] = useState( {
    	isValid: false,
    	values: {},
    	touched: {},
    	errors: {}
	} )
	/** Define a state holding the value if the snackbar should be shown. */
	const [ open, setOpen ] = useState( false )
	/** Define a state holding the value if the password should be shown or hidden. */
	const [ showPassword, setShowPassword ] = useState( false )

	/** Validate the form if some of the form fields changes. */
	useEffect( () => {
    	const errors = validate( formState.values, schema )

    	setFormState( formState => ( {
			...formState,
			errors: errors || {},
    		isValid: errors ? false : true
    	} ) )
	}, [ formState.values ] )

	/**
	 * Set the new state, if some of the form fields has changed.
	 *
	 * @param {Object} event The event objected that was invoked.
	 */
	const handleChange = event => {
    	event.persist()

    	setFormState( formState => ( {
			...formState,
			touched: {
				...formState.touched,
        		[ event.target.name ]: true
    		},
    		values: {
				...formState.values,
        		[ event.target.name ]:
        			event.target.type === 'checkbox' ?
						event.target.checked
            		:
						event.target.value
    		}
    	} ) )
	}

	/** Checks if one of the form fields has any errors. */
	const hasError = field =>
		formState.touched[ field ] && formState.errors[ field ] ? true : false

	return redirectAuthenticatedToDashboard( authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken ) ?
		null
	:
		(
			<>
				<GatsbySeo title="Anmelden" />
				<Grid className={ classes.grid } container>
					<Grid className={ classes.quoteGrid } item lg={ 5 }>
						<Paper className={ classes.quoteContainer } elevation={ 0 }>
							<GatsbyImage alt="Jugend debattiert Logo" image={ getImage(data.file) } className={ classes.quote } />
						</Paper>
					</Grid>
					<Grid item lg={ 7 } xs={ 12 }>
						<Box className={ classes.content }>
							<Box className={ classes.contentHeader }>
								<Link href={ process.env.GATSBY_SITE_WEB_URL }><ArrowBack /></Link>
							</Box>
							<Box className={ classes.contentBody }>
								<Box className={ classes.form } component="form" onSubmit={ (event) => {
									event.preventDefault()

									const email = formState.values.email
									const password = formState.values.password
									const stayLoggedIn = formState.values.stayLoggedIn

									const now = moment()

									let accessToken = ''

									claimAccessTokenWithPassword(email, password)
										.then( (res) => {
											const tokenData = res.data

											accessToken = tokenData.access_token

											dispatch( setStayLoggedIn( stayLoggedIn ) )
											if ( stayLoggedIn ) {
												dispatch( storeAccessToken( accessToken ) )
												dispatch( setAccessTokenExpires( now.add( tokenData.expires_in, 'seconds' ).unix() ) )
												dispatch( storeRefreshToken( tokenData.refresh_token ) )
												dispatch( setRefreshTokenExpires( now.add( 30, 'days' ).unix() ) )
											}
											else dispatch( storeAccessTokenForSession( accessToken ) )
										} )
										.catch( err => { setOpen( true ) } )
										.then( () => {
											if ( !open && !_.isEmpty( accessToken ) ) {
												if ( stayLoggedIn ) redirectAuthenticatedToDashboard( stayLoggedIn, accessToken, null )
												else redirectAuthenticatedToDashboard( stayLoggedIn, null, accessToken )
												client.resetStore()
											}
										} )
								} }>
									<Typography className={ classes.title } variant="h2">Anmeldung</Typography>
									<TextField
										autoComplete="username"
										className={ classes.textField }
										error={ hasError('email') }
										fullWidth
										helperText={
											hasError('email') ? formState.errors.email[ 0 ] : null
										}
										label="E-Mail Adresse"
										name="email"
										onChange={ handleChange }
										type="text"
										value={ formState.values.email || '' }
										variant="outlined" />
									<FormControl
										className={ classes.textField }
										error={ hasError('password') }
										fullWidth
										variant="outlined">
										<InputLabel htmlFor="password">Passwort</InputLabel>
										<OutlinedInput
											aria-describedby="password-text"
											autoComplete="current-password"
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="Schalte klartext Passwort um."
														onClick={ () => { setShowPassword(!showPassword) } }
														onMouseDown={ event => { event.preventDefault() } }>
														{ showPassword ? <Visibility /> : <VisibilityOff /> }
													</IconButton>
												</InputAdornment>
											}
											id="password"
											labelWidth={ 70 }
											name="password"
											onChange={ handleChange }
											type={ !showPassword ? 'password' : 'text' }
											value={ formState.values.password || '' } />
										<FormHelperText id="password-text">{ hasError('password') ? formState.errors.password[ 0 ] : null }</FormHelperText>
									</FormControl>
									<FormControlLabel
										className={ classes.checkbox }
										control={
											<Checkbox
												checked={ formState.values.stayLoggedIn || false }
												color="primary"
												inputProps={ { 'aria-label': 'Angemeldet bleiben?' } }
												name="stayLoggedIn"
												onChange={ handleChange } />
										}
										label="Angemeldet bleiben?" />
									<Button
										className={ classes.signInButton }
										color="primary"
										disabled={ !formState.isValid }
										fullWidth
										size="large"
										type="submit"
										variant="contained">
										Jetzt anmelden
									</Button>
								</Box>
							</Box>
						</Box>
					</Grid>
				</Grid>
				<Snackbar
					anchorOrigin={ {
						horizontal: 'center',
						vertical: 'bottom',
					} }
					autoHideDuration={ 5000 }
					open={ open }
					onClose={ (_, reason) => {
						if (reason === 'clickaway') return

						setOpen( false )
					} }>
					<SnackbarContent
						action={ [
							<IconButton color="inherit" key="close" onClick={ (_, reason) => {
								if (reason === 'clickaway') return

								setOpen( false )
							} }>
								<Close className={ classes.icon } />
							</IconButton>
						] }
						className={ classes.error }
						message={
							<Box className={ classes.message } component="span">
								<Error className={ clsx( classes.icon, classes.iconVariant ) } />
								Leider konnten Sie nicht angemeldet werden. Bitte versuchen Sie es erneut!
							</Box>
						} />
				</Snackbar>
			</>
		)
}

export default SignInPage

export const query = graphql`
query {
	file( relativePath: { eq: "Jugend_debattiert_Logo_2019_RGB.jpg" } ) {
		childImageSharp {
			gatsbyImageData(
				layout: FIXED
				placeholder: NONE
				quality: 100
				width: 500
			)
		}
	}
}
`
