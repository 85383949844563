/**
 * Topbar.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default theme => ( {
	flexGrow: {
    	flexGrow: 1,
	},
	icon: {
		height: 36,
	},
	root: {
    	boxShadow: 'none',
	},
	signOutButton: {
    	marginLeft: theme.spacing(1),
	},
} )
