/**
 * EventsTable.jsx
 *
 * @file This file exports a table which holds the events.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from "lodash"
import {
	Avatar,
	Badge,
	Box,
	Card,
	CardContent,
	Tooltip
} from '@material-ui/core'
import {
	DataGrid,
	getGridDateOperators,
	getGridSingleSelectOperators,
	getGridStringOperators,
	GridToolbar,
} from "@mui/x-data-grid"
import { Edit } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/styles'
import moment from "moment"
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { IconButtonLink } from '../IconButtonLink'
import { pages } from '../Sidebar'
import styles from './EventsTable.styles'
import { valueFormatterSingleSelectShouldShowLabel } from "../../utils"

// Create styles
const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: theme.palette.info.main,
	},
}))(Badge)
const useStyles = makeStyles(styles)

const EVENT_STATUS = [
	{ label: "Erfasst", value: "ERFASST" },         //  1
	{ label: "Freigegeben", value: "FREIGEGEBEN" }, //  2
	{ label: "Geöffnet", value: "GEOEFFNET" },      //  3
	{ label: "Geschlossen", value: "GESCHLOSSEN" }, //  4
	{ label: "Möglich", value: "MOEGLICH" },        //  5
	{ label: "Fraglich", value: "FRAGLICH" },       //  6
	{ label: "Ungenügend", value: "UNGENUEGEND" },  //  7
	{ label: "Gestrichen", value: "GESTRICHEN" },   //  8
	{ label: "Abgesagt", value: "ABGESAGT" },       //  9
	{ label: "Gelaufen", value: "GELAUFEN" },       // 10
]

/**
 * Create the events table.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const EventsTable = ({
	events,
	eventTypes,
	fetchMore,
	loading,
	page,
	paginatorInfo,
	refetch,
	rowsPerPage,
	selectedEvents,
	setPage,
	setRowsPerPage,
	setSelectedEvents,
	updateEventStatus,
	...other
}) => {
	const classes = useStyles()

	/** Define a state that holds the current filter variables */
	const [filter, setFilter] = useState({})
	/** Define a state that holds the rows currently displayed */
	const [rows, setRows] = useState([])

	const dataGridGetActions = (params) => [
		<IconButtonLink
			to={ `${pages[1].href}/${params.id}/edit` }>
			<Edit />
		</IconButtonLink>,
	]

	const filterOperatorsDate = getGridDateOperators().filter(
		(operator) => operator.value === "after" || operator.value === "before",
	)

	const filterOperatorsSingleSelect = getGridSingleSelectOperators().filter(
		(operator) => operator.value === "is",
	)

	const filterOperatorsString = getGridStringOperators().filter(
		(operator) => operator.value === "contains",
	)

	const renderCellDbId = (params) => {

		return (
			<ButtonLink
				color="primary"
				size="small"
				to={ `${ pages[1].href }/${ params.value }` }
				variant="text">
				{ params.value }
			</ButtonLink>
		)
	}

	const renderCellEventType = (params) => {

		return params.value.fee ?
			<Tooltip placement="top-end" title="Honorarbasis">
				<StyledBadge
					anchorOrigin={{
						horizontal: 'right',
						vertical: 'top',
					}}
					overlap="circle"
					variant="dot">
					<Avatar className={ classes.avatar }>{ params.value.type }</Avatar>
				</StyledBadge>
			</Tooltip>
		:
			<Avatar className={ classes.avatar }>{ params.value.type }</Avatar>
	}

	const renderCellLecturer = (params) => {

		return params.value.lecturer ?
			<ButtonLink
				color="primary"
				size="small"
				to={ `${ pages[8].href }/${ params.value.lecturer.id }` }
				variant="text">
				{ params.value.lecturer.name }
			</ButtonLink>
		:
			params.value.lecturerFallback
	}

	const valueFormatterInitialRegistrationPeriod = (params) => moment(params.value).format("dd DD.MM.YYYY")

	const valueFormatterStart = (params) => moment(params.value).format("dd DD.MM.YYYY HH:mm")

	const columns = [
		{ field: "dbId", filterable: false, flex: 0.25, headerName: "#ID", renderCell: renderCellDbId, },
		{ field: "eventType", filterOperators: filterOperatorsSingleSelect, flex: 1, headerName: "Veranstaltungstyp", hideable: false, renderCell: renderCellEventType, type: "singleSelect", valueOptions: eventTypes.map(({ description, type }) => ({ label: description, value: type })), },
		{ field: "eventId", filterable: false, flex: 2, headerName: "Veranstaltungsnummer", hideable: false, },
		{ field: "start", filterOperators: filterOperatorsDate, flex: 2, headerName: "Veranstaltungsbeginn", type: "dateTime", valueFormatter: valueFormatterStart, },
		{ field: "initialRegistrationPeriod", filterOperators: filterOperatorsDate, flex: 2, headerName: "Beginn der Anmeldephase", type: "date", valueFormatter: valueFormatterInitialRegistrationPeriod },
		{ field: "regions", filterOperators: filterOperatorsString, flex: 1.5, headerName: "Regionalbezirke", },
		{ field: "lecturer", filterable: false, flex: 1, headerName: "Dozent", renderCell: renderCellLecturer, sortable: false, },
		{ field: "venue", filterable: false, flex: 1, headerName: "Veranstaltungsort", sortable: false, },
		{ editable: true, field: "status", filterable: false, filterOperators: filterOperatorsSingleSelect, flex: 2, headerName: "Status", hideable: false, type: "singleSelect", valueFormatter: valueFormatterSingleSelectShouldShowLabel, valueOptions: EVENT_STATUS, }, // TODO: filterable is false cause it breaks with the display workaround
		{ field: "actions", flex: 1, getActions: dataGridGetActions, headerName: "Bearbeiten", hideable: false, type: "actions", },
	]

	useMemo(
		() => {
			const newRows = []
			events.forEach((event) => {
				let type = ''
				let splittedEventType = event.type.type.split("_")
				if (splittedEventType.length > 1) {
					for (let i = 0; i < splittedEventType.length; i++) {
						type += splittedEventType[i].charAt(0)
					}
				}
				else {
					type = event.type.type[0]
				}
				type = type.toUpperCase()

				newRows.push({
					dbId: event.id,
					eventId: event.eventID,
					eventType: {
						fee: event.fee,
						type: type,
					},
					id: event.id,
					initialRegistrationPeriod: moment(event.initialRegistrationPeriod),
					lecturer: {
						lecturer: event.lecturer,
						lecturerFallback: event.lecturerFallback,
					},
					regions: JSON.parse(event.regionalNetworks).cities.join(", "),
					start: moment(event.start),
					status: event.status,
					venue: event.venue ? event.venue.name : "",
				})

				setRows(newRows)
			})
		},
		[events]
	)


	/**
	 * Change the current page, fetch more data from the server
	 * and unselect all events.
	 *
	 * @param {number} newPage The page number to navigate to.
	 */
	const handlePageChange = (newPage) => {
    	setPage(newPage)

		fetchMore(filter)
	}

	/**
	 * Change the number of total rows per page, fetch more data from the server
	 * and unselect all events.
	 *
	 * @param {number} newPageSize The newly selected page size
	 */
	const handleRowsPerPageChange = (newPageSize) => {
    	setRowsPerPage(newPageSize)

		fetchMore(filter)
	}

	/**
	 * Change the status of a specific event.
	 *
	 * @param {Object} newEvent The altered event object.
	 * @param {Object} oldEvent The original event object.
	 */
	const handleStatusChange = useCallback(
		(newEvent, oldEvent) => {
			if (_.isEqual(newEvent, oldEvent)) {
				return oldEvent
			}
			else {
				updateEventStatus({
					variables: {
						id: newEvent.id,
						status: newEvent.status,
					},
				})
				return newEvent
			}
		},
		[updateEventStatus]
	)

	/**
	 * Change the filter for the events.
	 *
	 * @param {Object} filterModel The filter to applay.
	 */
	const handleFilterChange = useCallback(
		(filterModel) => {
			// DataGrid (free) only supports single filters
			let filterItem = filterModel.items[0]

			if (typeof filterItem === "undefined" || typeof filterItem.value === "undefined") {
				refetch({
					first: rowsPerPage,
					initialRegistrationPeriodAfter: undefined,
					initialRegistrationPeriodBefore: undefined,
					page: page,
					regionalNetwork: undefined,
					startAfter: undefined,
					startBefore: undefined,
					status: undefined,
					type: undefined,
				})
			}
			else {
				let variables = {}
				switch (filterItem.columnField) {
					case "eventType":
						variables = {
							initialRegistrationPeriodAfter: undefined,
							initialRegistrationPeriodBefore: undefined,
							regionalNetwork: undefined,
							startAfter: undefined,
							startBefore: undefined,
							status: undefined,
							type: filterItem.value,
						}
						break
					case "initialRegistrationPeriod":
						if (filterItem.operatorValue === "after") {
							variables = {
								initialRegistrationPeriodAfter: filterItem.value,
								initialRegistrationPeriodBefore: undefined,
								regionalNetwork: undefined,
								startAfter: undefined,
								startBefore: undefined,
								status: undefined,
								type: undefined,
							}
						}
						else {
							variables = {
								initialRegistrationPeriodAfter: undefined,
								initialRegistrationPeriodBefore: filterItem.value,
								regionalNetwork: undefined,
								startAfter: undefined,
								startBefore: undefined,
								status: undefined,
								type: undefined,
							}
						}
						break
					case "regions":
						variables = {
							initialRegistrationPeriodAfter: undefined,
							initialRegistrationPeriodBefore: undefined,
							regionalNetwork: `%${filterItem.value}%`,
							startAfter: undefined,
							startBefore: undefined,
							status: undefined,
							type: undefined,
						}
						break
					case "start":
						if (filterItem.operatorValue === "after") {
							variables = {
								initialRegistrationPeriodAfter: undefined,
								initialRegistrationPeriodBefore: undefined,
								regionalNetwork: undefined,
								startAfter: filterItem.value,
								startBefore: undefined,
								status: undefined,
								type: undefined,
							}
						}
						else {
							variables = {
								initialRegistrationPeriodAfter: undefined,
								initialRegistrationPeriodBefore: undefined,
								regionalNetwork: undefined,
								startAfter: undefined,
								startBefore: filterItem.value,
								status: undefined,
								type: undefined,
							}
						}
						break
					case "status":
						variables = {
							initialRegistrationPeriodAfter: undefined,
							initialRegistrationPeriodBefore: undefined,
							regionalNetwork: undefined,
							startAfter: undefined,
							startBefore: undefined,
							status: filterItem.value,
							type: undefined,
						}
						break
					default:
						variables = {
							initialRegistrationPeriodAfter: undefined,
							initialRegistrationPeriodBefore: undefined,
							regionalNetwork: undefined,
							startAfter: undefined,
							startBefore: undefined,
							status: undefined,
							type: undefined,
						}
				}

				setFilter(variables)
				refetch({
					first: rowsPerPage,
					page: page,
					...variables,
				})
			}
		},
		[refetch]
	)

	return (
		<Card className={ classes.root } { ...other }>
    		<CardContent className={ classes.content }>
        		<Box className={ classes.inner }>
				<DataGrid
					autoHeight
					checkboxSelection
					columns={ columns }
					components={{
						Toolbar: GridToolbar,
					}}
					disableSelectionOnClick
					experimentalFeatures={{
						newEditingApi: true,
					}}
					filterMode="server"
					keepNonExistentRowsSelected
					loading={ loading }
					onFilterModelChange={ handleFilterChange }
					onPageChange={ handlePageChange }
					onPageSizeChange={ handleRowsPerPageChange }
					onSelectionModelChange={ (newSelectionModel) => {
						setSelectedEvents(newSelectionModel)
					} }
					page={ page }
					pageSize={ rowsPerPage }
					pagination
					paginationMode="server"
					processRowUpdate={ handleStatusChange }
					rowCount={ paginatorInfo.lastPage }
					rows={ rows }
					rowsPerPageOptions={[ 10, 15, 25, 50, 100 ]}
					selectionModel={ selectedEvents } />
    			</Box>
    		</CardContent>
    	</Card>
	)
}

/**
 *
 */
EventsTable.propTypes = {
	events: PropTypes.arrayOf(PropTypes.shape({
		end: PropTypes.string,
		eventID: PropTypes.string.isRequired,
		fee: PropTypes.bool.isRequired,
		htmlLink: PropTypes.string,
		id: PropTypes.string.isRequired,
		initialRegistrationPeriod: PropTypes.string,
		lecturer: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		lecturerFallback: PropTypes.string,
		periodOfRest: PropTypes.string,
		regionalNetworks: PropTypes.string,
		secondDate: PropTypes.shape({
			end: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			start: PropTypes.string.isRequired,
		}),
		secondRegistrationPeriod: PropTypes.string,
		start: PropTypes.string,
		status: PropTypes.oneOf( EVENT_STATUS.map((status) => status.value) ).isRequired,
		type: PropTypes.shape({
			description: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
		}).isRequired,
		venue: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	})).isRequired,
	eventTypes: PropTypes.arrayOf(PropTypes.shape({
		description: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	})).isRequired,
	fetchMore: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	page: PropTypes.number.isRequired,
	paginatorInfo: PropTypes.exact({
		__typename: PropTypes.string,
		count: PropTypes.number.isRequired,
		currentPage: PropTypes.number.isRequired,
		firstItem: PropTypes.number,
		hasMorePages: PropTypes.bool.isRequired,
		lastItem: PropTypes.number,
		lastPage: PropTypes.number.isRequired,
		perPage: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired,
	}).isRequired,
	refetch: PropTypes.func.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	selectedEvents: PropTypes.array.isRequired,
	setPage: PropTypes.func.isRequired,
	setRowsPerPage: PropTypes.func.isRequired,
	setSelectedEvents: PropTypes.func.isRequired,
	updateEventStatus: PropTypes.func.isRequired,
}

export default EventsTable
