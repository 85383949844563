/**
 * EventEdit.jsx
 *
 * @file This file exports a view to edit a specific event.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import { Alert } from '@material-ui/lab'
import {
	Button,
	Grid,
	Snackbar,
	Typography
} from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import logger from 'loglevel'
import Lottie from 'lottie-react'
import moment from 'moment'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useParams } from '@reach/router'

// internal imports
import { EventScheduler } from '../EventScheduler'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'

/** Mutation to add a second date of an event. */
const ADD_SECOND_DATE = gql`
	mutation AddSecondDate( $end: DateTime! $id: ID! $start: DateTime! ) {
		addSecondDate( end: $end id: $id start: $start ) {
			id
		}
	}
`

/** Mutation to delete an event (only ment to use for second dates). */
const DELETE_EVENT = gql`
	mutation DeleteSecondDate( $id: ID! ) {
		deleteEvent( id: $id ) {
			id
		}
	}
`

/** Query to get all events and schools from the server. */
const GET_EVENT_DETAILS = gql`
	query GetEventDetails( $id: ID! ) {
		event( id: $id ) {
			id
			status
			regionalNetworks
			type {
				description
				type
			}
			fee
			eventID
			initialRegistrationPeriod
			secondRegistrationPeriod
			periodOfRest
			start
			end
			lecturerFallback
			description
			htmlLink
			icalUID
			calendarID
			summary
			lecturer {
				id
				name
			}
			secondDate {
				end
				id
				start
			}
			venue {
				city
				id
				name
			}
		}
		allEventTypes {
			description
			type
		}
		allUsers {
			id
			name
		}
		allVenues {
			city
			id
			name
		}
	}
`

/** Mutation to update a specific event. */
const UPDATE_EVENT = gql`
	mutation UpdateEvent(
		$calendarID: String
		$description: String
		$end: DateTime
		$fee: Boolean
		$htmlLink: String
		$icalUID: String
		$id: ID!
		$initialRegistrationPeriod: Date
		$lecturerFallback: String
		$lecturerID: ID
		$periodOfRest: Date
		$regionalNetworks: JSON
		$secondRegistrationPeriod: Date
		$start: DateTime
		$status: EventStatus
		$summary: String
		$type: ID
		$venueID: ID
	) {
		updateEvent( input: {
			calendarID: $calendarID
			description: $description
			end: $end
			fee: $fee
			htmlLink: $htmlLink
			icalUID: $icalUID
			id: $id
			initialRegistrationPeriod: $initialRegistrationPeriod
			lecturer: {
				connect: $lecturerID
			}
			lecturerFallback: $lecturerFallback
			periodOfRest: $periodOfRest
			regionalNetworks: $regionalNetworks
			secondRegistrationPeriod: $secondRegistrationPeriod
			start: $start
			status: $status
			summary: $summary
			type: {
				connect: $type
			}
			venue: {
				connect: $venueID
			}
		} ) {
			id
		}
	}
`

/**
 * This component creates a view to create new events.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const EventEdit = () => {
	const params = useParams()

	const now = moment()

	/** Define a state holding the calendar-id of the Google Calendar event. */
	const [ calendarID, setCalendarID ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the description of the event. */
	const [ description, setDescription ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state disabling the submit button. */
	const [ disableSubmit, setDisableSubmit ] = useState( true )
	/** Define a state holding the datetime when the event ends. */
	const [ end, setEnd ] = useState( moment( now ).add( 30, 'd' ).startOf( 'hour' ).add( 2, 'h' ) )
	/** Define a state holding an error. */
	const [ error, setError ] = useState( {} )
	/** Define a state holding the event ID "Veranstaltungsnummer" of the event. */
	const [ eventID, setEventID ] = useState( {
		error: false,
		value: '',
		verified: false
	} )
	const [eventTypes, setEventTypes] = useState([
		{ description: "", type: "" }
	])
	/** Define a state holding the fee-status of the event. */
	const [ fee, setFee ] = useState( false )
	/** Define a state holding the html link of the Google Calendar event. */
	const [ htmlLink, setHtmlLink ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the ical-uid of the Google Calendar event. */
	const [ icalUID, setIcalUID ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the ID of the event. */
	const [ ID, setID ] = useState( '0' )
	/** Define a state holding the datetime when the initial registration period begins. */
	const [ initialPeriod, setInitialPeriod ] = useState( moment( now ).startOf( 'day' ).add( 7, 'd' ) )
	/** Define a state holding the lecturer of the event. */
	const [ lecturer, setLecturer ] = useState( {
		error: false,
		value: {
			id: '0',
			name: '',
			__typename: 'User'
		},
		verified: false
	} )
	/** Define a state holding the information, whether the user exists in the system or a simple `TextField` should be displayed for the name. */
	const [ lecturerFallback, setLecturerFallback ] = useState( false )
	/** Define a state holding the name of the "fallback" lecturer */
	const [ lecturerFallbackName, setLecturerFallbackName ] = useState( {
		error: false,
		value: '',
		verified: false
	} )
	/** Define a state holding the original event data. */
	const [ originalEvent, setOriginalEvent ] = useState( {} )
	/** Define a state holding the datetime when the period of rest begins. */
	const [ periodOfRest, setPeriodOfRest ] = useState( moment( now ).startOf( 'day' ).add( 28, 'd' ) )
	/** Define a state holding an array of regional networks. */
	const [ regionalNetworks, setRegionalNetworks ] = useState( {
		error: false,
		value: [],
		verified: false
	} )
	/** Define a state holding the information whether the event has a second date. */
	const [ secondDate, setSecondDate ] = useState( false )
	/** Define a state holding the datetime when the second date of the event ends. */
	const [ secondDateEnd, setSecondDateEnd ] = useState( moment( now ).add( 31, 'd' ).startOf( 'hour' ).add( 2, 'h' ) )
	/** Define a state holding the datetime when the second date of the event begins. */
	const [ secondDateStart, setSecondDateStart ] = useState( moment( now ).add( 31, 'd' ).startOf( 'hour' ) )
	/** Define a state holding the ID of the second date of the event. */
	const [ secondID, setSecondID ] = useState( '0' )
	/** Define a state holding the datetime when the second registration period begins. */
	const [ secondPeriod, setSecondPeriod ] = useState( moment( now ).startOf( 'day' ).add( 21, 'd' ) )
	/** Define a state holding the datetime when the event begins. */
	const [ start, setStart ] = useState( moment( now ).add( 30, 'd' ).startOf( 'hour' ) )
	/** Define a state holding the status of the new event. */
	const [ status, setStatus ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the summary of the Google Calendar event. */
	const [ summary, setSummary ] = useState( {
		error: false,
		value: '',
		verified: true
	} )
	/** Define a state holding the type of the new event. */
	const [ type, setType ] = useState( {
		error: false,
		value: '',
		verified: false
	} )
	/** Define a state holding a default venue for the new event. */
	const [ venue, setVenue ] = useState( {
		error: false,
		value: {
			city: '',
			id: '0',
			label: '',
			name: '',
			__typename: 'Venue'
		},
		verified: true
	} )
	/** Define a state holding all available venues from the server. */
	const [ venues, setVenues ] = useState( [] )
	/** Define a state holding all available users from the server. */
	const [ users, setUsers ] = useState( [] )

	/** Fetch all venues from the GraphQL Server. */
	const { error: queryError, loading: queryLoading } = useQuery( GET_EVENT_DETAILS, {
		displayName: 'GetEventDetailsQuery',
		onCompleted: (data) => {
			if ( data ) {
				if ( data.event ) {
					setOriginalEvent( data.event )
					setCalendarID( {
						error: false,
						value: data.event.calendarID || '',
						verified: true
					} )
					setDescription( {
						error: false,
						value: data.event.description || '',
						verified: true
					} )
					setEnd( moment( data.event.end ) )
					setEventID( {
						error: false,
						value: data.event.eventID,
						verified: true
					} )
					setFee( data.event.fee )
					setHtmlLink( {
						error: false,
						value: data.event.htmlLink || '',
						verified: true
					} )
					setIcalUID( {
						error: false,
						value: data.event.icalUID || '',
						verified: true
					} )
					setID( data.event.id )
					setInitialPeriod( moment( data.event.initialRegistrationPeriod ) )
					if ( data.event.lecturer )
						setLecturer( {
							error: false,
							value: data.event.lecturer,
							verified: true
						} )
					if ( data.event.lecturerFallback ) {
						setLecturerFallback( true )
						setLecturerFallbackName( {
							error: false,
							value: data.event.lecturerFallback,
							verified: true
						} )
					}
					setPeriodOfRest( moment( data.event.periodOfRest ) )
					setRegionalNetworks( {
						error: false,
						value: JSON.parse( data.event.regionalNetworks ).cities,
						verified: true
					} )
					if ( data.event.secondDate ) {
						setSecondDate( true )
						setSecondDateEnd( moment( data.event.secondDate.end ) )
						setSecondDateStart( moment( data.event.secondDate.start ) )
						setSecondID( data.event.secondDate.id )
					}
					setSecondPeriod( moment( data.event.secondRegistrationPeriod ) )
					setStart( moment( data.event.start ) )
					setStatus( {
						error: false,
						value: data.event.status,
						verified: true
					} )
					setSummary( {
						error: false,
						value: data.event.summary || '',
						verified: true
					} )
					setType( {
						error: false,
						value: data.event.type.type,
						verified: true
					} )
					if ( data.event.venue ) {
						setVenue( {
							error: false,
							value: {
								...data.event.venue,
								label: `${ data.event.venue.name } (${ data.event.venue.city })`
							},
							verified: true
						} )
					}
				}
				if ( data.allVenues ) {
					setVenues(
						data.allVenues.map( venue => ( {
							...venue,
							label: `${ venue.name } (${ venue.city })`
						} ) )
					)
				}
				if ( data.allUsers ) setUsers( data.allUsers )
				if (data.allEventTypes) setEventTypes(data.allEventTypes)
			}
		},
		ssr: false,
		variables: {
			id: params.id
		}
	} )

	/** Add a second date to the `Event` on the server. */
	const [ addSecondDate, { loading: mutationAddSecondDateLoading } ] = useMutation( ADD_SECOND_DATE, {
		onCompleted: (data) => {
			setSecondID( data.addSecondDate.id )

			navigate(`/events/${ ID }`)
		},
		onError: (error) => {
			logger.error(error)
			setError(error)
		},
		variables: {
			end: secondDateEnd.format( "YYYY-MM-DD HH:mm:ss" ),
			id: ID,
			start: secondDateStart.format( "YYYY-MM-DD HH:mm:ss" )
		}
	} )

	/** Delete the second date of the `Event` on the server. */
	const [ deleteSecondDate, { loading: mutationDeleteSecondDateLoading } ] = useMutation( DELETE_EVENT, {
		onCompleted: (data) => {
			navigate(`/events/${ ID }`)
		},
		onError: (error) => {
			logger.error(error)
			setError(error)
		},
		variables: {
			id: secondID
		}
	} )

	/** Create a new `Event` on the server. */
	const [ updateEvent, { loading: mutationLoading } ] = useMutation( UPDATE_EVENT, {
		onCompleted: (data) => {
			if ( _.isEqual( ID, data.updateEvent.id ) && eventSecondDateWasMutated() ) {
				if ( _.isEmpty( originalEvent.secondDate ) && secondDate ) addSecondDate()
				else if ( !_.isEmpty( originalEvent.secondDate ) && !secondDate ) deleteSecondDate()
				else
					updateEvent( {
						variables: {
							calendarID: _.isEmpty( calendarID.value ) ? null : calendarID.value,
							description: _.isEmpty( description.value ) ? null : description.value,
							end: secondDateEnd.format( "YYYY-MM-DD HH:mm:ss" ),
							fee: fee,
							htmlLink: _.isEmpty( htmlLink.value ) ? null : htmlLink.value,
							icalUID: _.isEmpty( icalUID.value ) ? null : icalUID.value,
							id: secondID,
							initialRegistrationPeriod: initialPeriod.format( "YYYY-MM-DD" ),
							lecturerFallback: ( !lecturerFallback || _.isEmpty( lecturerFallbackName.value ) ) ? null : lecturerFallbackName.value,
							lecturerID: ( lecturerFallback || lecturer.value.id === '0' ) ? null : lecturer.value.id,
							periodOfRest: periodOfRest.format( "YYYY-MM-DD" ),
							regionalNetworks: JSON.stringify( { cities: [ ...regionalNetworks.value ] } ),
							secondRegistrationPeriod: secondPeriod.format( "YYYY-MM-DD" ),
							start: secondDateStart.format( "YYYY-MM-DD HH:mm:ss" ),
							status: _.isEmpty( status.value ) ? null : status.value,
							summary: _.isEmpty( summary.value ) ? eventID.value : summary.value,
							type: type.value,
							venueID: venue.value.id === '0' ? null : venue.value.id
						}
					} )
			}
			else navigate(`/events/${ ID }`)
		},
		onError: (error) => {
			logger.error(error)
			setError(error)
		}
	} )

	/** Disable the save button, if necessary. */
	useEffect(
		() => {
			if (
				!calendarID.verified ||
				!description.verified ||
				!eventID.verified ||
				!htmlLink.verified ||
				!icalUID.verified ||
				(
					( !lecturerFallback && !lecturer.verified ) &&
					( lecturerFallback && !lecturerFallbackName.verified )
				) ||
				!regionalNetworks.verified ||
				!status.verified ||
				!summary.verified ||
				!type.verified ||
				!venue.verified ||
				( !eventWasMutated() && !eventSecondDateWasMutated() )
			) {
				setDisableSubmit(true)
			}
			else {
				setDisableSubmit(false)
			}
		},
		[
			calendarID,
			description,
			end,
			eventID,
			fee,
			htmlLink,
			icalUID,
			initialPeriod,
			lecturer,
			lecturerFallback,
			lecturerFallbackName,
			periodOfRest,
			regionalNetworks,
			secondDate,
			secondDateEnd,
			secondDateStart,
			secondPeriod,
			start,
			status,
			summary,
			type,
			venue
		]
	)

	/**
	 * Checks if the original data of the second date of the event was mutated.
	 *
	 * @returns {boolean}
	 */
	const eventSecondDateWasMutated = () => {
		let mutated = false

		if ( ( !_.isEmpty( originalEvent ) && !_.isEmpty( originalEvent.secondDate ) ) && !secondDate ) mutated = true
		else if ( ( !_.isEmpty( originalEvent ) && !_.isEmpty( originalEvent.secondDate ) ) || secondDate ) {
			if ( !_.isEmpty( originalEvent.secondDate ) ) {
				if ( !secondDateEnd.isSame( originalEvent.secondDate.end ) ) mutated = true

				if ( !secondDateStart.isSame( originalEvent.secondDate.start ) ) mutated = true
			}
			else mutated = true
		}

		return mutated
	}

	/**
	 * Checks if the original data was mutated.
	 *
	 * @returns {boolean}
	 */
	const eventWasMutated = () => {
		let mutated = false

		if ( !_.isEmpty( originalEvent ) ) {
			if ( !_.isEqual( originalEvent.calendarID || '', calendarID.value ) ) mutated = true

			if ( !_.isEqual( originalEvent.description || '', description.value ) ) mutated = true

			if ( !end.isSame( originalEvent.end ) ) mutated = true

			if ( !_.isEqual( originalEvent.fee, fee ) ) mutated = true

			if ( !_.isEqual( originalEvent.htmlLink || '', htmlLink.value ) ) mutated = true

			if ( !_.isEqual( originalEvent.icalUID || '', icalUID.value ) ) mutated = true

			if ( !initialPeriod.isSame( originalEvent.initialRegistrationPeriod ) ) mutated = true

			if ( !lecturerFallback ) {
				if ( !_.isEqual( originalEvent.lecturer, lecturer.value ) ) mutated = true
			}
			else {
				if ( !_.isEqual( originalEvent.lecturerFallback, lecturerFallbackName.value ) ) mutated = true
			}

			if ( !periodOfRest.isSame( originalEvent.periodOfRest ) ) mutated = true

			if ( !_.isEqual( JSON.parse( originalEvent.regionalNetworks ), { cities: regionalNetworks.value } ) ) mutated = true

			if ( !secondPeriod.isSame( originalEvent.secondRegistrationPeriod ) ) mutated = true

			if ( !start.isSame( originalEvent.start ) ) mutated = true

			if ( !_.isEqual( originalEvent.status, status.value ) ) mutated = true

			if ( !_.isEqual( originalEvent.summary, summary.value ) ) mutated = true

			if ( !_.isEqual( originalEvent.type, type.value ) ) mutated = true

			if (
				!_.isEqual(
					originalEvent.venue ?
						{ ...originalEvent.venue, label: `${ originalEvent.venue.name } (${ originalEvent.venue.city })` }
					:
						{ city: '', id: '0', label: '', name: '', __typename: 'Venue' },
					venue.value
				)
			) mutated = true
		}

		return mutated
	}

	return (
		<>
			<GatsbySeo title="Event bearbeiten" />
			<Typography color="textSecondary" gutterBottom variant="h3">Event bearbeiten</Typography>
			{ queryLoading &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ true }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 'auto',
							}} />
					</Grid>
				</Grid>
			}
			{ !queryLoading &&
				<EventScheduler
					calendarID={ calendarID }
					description={ description }
					disableEventID
					end={ end }
					eventID={ eventID }
					eventTypes={ eventTypes }
					fee={ fee }
					handleCalendarIDChanged={ setCalendarID }
					handleDescriptionChanged={ setDescription }
					handleEndChanged={ setEnd }
					handleEventIDChanged={ setEventID }
					handleFeeChanged={ setFee }
					handleHtmlLinkChanged={ setHtmlLink }
					handleIcalUIDChanged={ setIcalUID }
					handleInitialPeriodChanged={ setInitialPeriod }
					handleLecturerChanged={ setLecturer }
					handleLecturerFallbackChanged={ setLecturerFallback }
					handleLecturerFallbackNameChanged={ setLecturerFallbackName }
					handlePeriodOfRestChanged={ setPeriodOfRest }
					handleRegionalNetworksChanged={ setRegionalNetworks }
					handleSecondDateChanged={ setSecondDate }
					handleSecondDateEndChanged={ setSecondDateEnd }
					handleSecondDateStartChanged={ setSecondDateStart }
					handleSecondPeriodChanged={ setSecondPeriod }
					handleStartChanged={ setStart }
					handleStatusChanged={ setStatus }
					handleSummaryChanged={ setSummary }
					handleTypeChanged={ setType }
					handleVenueChanged={ setVenue }
					htmlLink={ htmlLink }
					icalUID={ icalUID }
					initialPeriod={ initialPeriod }
					lecturer={ lecturer }
					lecturerFallback={ lecturerFallback }
					lecturerFallbackName={ lecturerFallbackName }
					periodOfRest={ periodOfRest }
					regionalNetworks={ regionalNetworks }
					secondDate={ secondDate }
					secondDateEnd={ secondDateEnd }
					secondDateStart={ secondDateStart }
					secondPeriod={ secondPeriod }
					start={ start }
					status={ status }
					summary={ summary }
					type={ type }
					users={ users }
					venue={ venue }
					venues={ venues }>
					<Grid item md={ 6 } xs={ 12 }>
						<Typography gutterBottom variant="h5">Änderungen speichern</Typography>

						<Typography paragraph variant="body2">Bitte überprüfen Sie Ihre Eingaben noch einmal, bevor Sie die Änderungen in der Datenbank speichern.</Typography>
						<Button
							color="secondary"
							disabled={ disableSubmit }
							onClick={ e => {
								e.preventDefault()

								if ( eventWasMutated() ) {
									updateEvent( {
										variables: {
											calendarID: _.isEmpty( calendarID.value ) ? null : calendarID.value,
											description: _.isEmpty( description.value ) ? null : description.value,
											end: end.format( "YYYY-MM-DD HH:mm:ss" ),
											fee: fee,
											htmlLink: _.isEmpty( htmlLink.value ) ? null : htmlLink.value,
											icalUID: _.isEmpty( icalUID.value ) ? null : icalUID.value,
											id: ID,
											initialRegistrationPeriod: initialPeriod.format( "YYYY-MM-DD" ),
											lecturerFallback: ( !lecturerFallback || _.isEmpty( lecturerFallbackName.value ) ) ? null : lecturerFallbackName.value,
											lecturerID: ( lecturerFallback || lecturer.value.id === '0' ) ? null : lecturer.value.id,
											periodOfRest: periodOfRest.format( "YYYY-MM-DD" ),
											regionalNetworks: JSON.stringify( { cities: [ ...regionalNetworks.value ] } ),
											secondRegistrationPeriod: secondPeriod.format( "YYYY-MM-DD" ),
											start: start.format( "YYYY-MM-DD HH:mm:ss" ),
											status: _.isEmpty( status.value ) ? null : status.value,
											summary: _.isEmpty( summary.value ) ? eventID.value : summary.value,
											type: type.value,
											venueID: venue.value.id === '0' ? null : venue.value.id
										}
									} )
								}
								else {
									if ( eventSecondDateWasMutated() ) {
										if ( _.isEmpty( originalEvent.secondDate ) && secondDate ) addSecondDate()
										else if ( !_.isEmpty( originalEvent.secondDate ) && !secondDate ) deleteSecondDate()
										else {
											updateEvent( {
												variables: {
													calendarID: _.isEmpty( calendarID.value ) ? null : calendarID.value,
													description: _.isEmpty( description.value ) ? null : description.value,
													end: secondDateEnd.format( "YYYY-MM-DD HH:mm:ss" ),
													fee: fee,
													htmlLink: _.isEmpty( htmlLink.value ) ? null : htmlLink.value,
													icalUID: _.isEmpty( icalUID.value ) ? null : icalUID.value,
													id: secondID,
													initialRegistrationPeriod: initialPeriod.format( "YYYY-MM-DD" ),
													lecturerFallback: ( !lecturerFallback || _.isEmpty( lecturerFallbackName.value ) ) ? null : lecturerFallbackName.value,
													lecturerID: ( lecturerFallback || lecturer.value.id === '0' ) ? null : lecturer.value.id,
													periodOfRest: periodOfRest.format( "YYYY-MM-DD" ),
													regionalNetworks: JSON.stringify( { cities: [ ...regionalNetworks.value ] } ),
													secondRegistrationPeriod: secondPeriod.format( "YYYY-MM-DD" ),
													start: secondDateStart.format( "YYYY-MM-DD HH:mm:ss" ),
													status: _.isEmpty( status.value ) ? null : status.value,
													summary: _.isEmpty( summary.value ) ? eventID.value : summary.value,
													type: type.value,
													venueID: venue.value.id === '0' ? null : venue.value.id
												}
											} )
										}
									}
								}
							} }
							variant="contained">
							Änderungen speichern
						</Button>
					</Grid>
				</EventScheduler>
			}

			<Snackbar open={ !_.isEmpty( error ) }>
				<Alert elevation={ 6 } severity="error" variant="filled">Es trat ein unerwarteter Fehler auf. Bitte öffnen Sie die Konsole (Rechtsklick auf die Website -&gt; Element untersuchen -&gt; Konsole ) und beschreiben Sie dem Entwickler möglichst genau die Fehlermeldung.</Alert>
			</Snackbar>
		</>
	)
}

export default EventEdit
