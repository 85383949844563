/**
 * wordpress.jsx
 *
 * @file This file exports a page that displays WordPress in an iframe.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { navigate } from 'gatsby'

/**
 * This component creates a page that displays WordPress in an iframe.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const WordPressPage = () => {
	window.open(process.env.GATSBY_WORDPRESS_URL, "_blank")
	navigate("/")
	return null
}

export default WordPressPage
