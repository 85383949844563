/**
 * SchoolDetail.jsx
 *
 * @file This file exports a detail view for a school.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash/core'
import { Grid, Link, Typography } from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useQuery } from '@apollo/client'
import Lottie from 'lottie-react'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useParams } from '@reach/router'

// internal imports
/**!
 * Copyright © Erick Daniel Juarez Gil
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/ErickDanielJuarezGil
 * @link https://lottiefiles.com/9531-oops-something-went-wrong?lang=en
 */
import errorAnimationData from '../../assets/animations/ErrorAnimation.json'
/**!
 * Copyright © Amos Gyamfi
 *
 * Changed the primary color of the hourglass from `#E47373` to `#009EE3`
 *
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/nimbbble
 * @link https://lottiefiles.com/7414-hourglass?lang=en
 */
import loadingAnimationData from '../../assets/animations/LoadingAnimation.json'
import styles from './SchoolDetail.styles'

// Create styles
const useStyles = makeStyles(styles)

/** Fetch the specified school and all it's details from the GraphQL API. */
const GET_SCHOOL_DETAIL = gql`
	query SchoolDetail( $id: ID! ) {
		school( id: $id ) {
			address
			city
			comment
			email
			firstParticipation
			id
			name
			postalCode
			regionalNetwork
			telephone
			type
			website
		}
	}
`

/**
 * Create the school detail view.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const SchoolDetail = () => {
	const classes = useStyles()
	const { id }  = useParams()

	/** Execute the query and fetch the data from the GraphQL API. */
	const { data, error, loading } = useQuery( GET_SCHOOL_DETAIL, {
		fetchPolicy: 'network-only',
        variables: { id }
	} )

	return (
		<>
			<GatsbySeo title="Schulen" />
			{ error &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ errorAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ loading &&
				<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
					<Grid item xs={ 12 }>
						<Lottie
							animationData={ loadingAnimationData }
							autoplay={ true }
							loop={ false }
							style={{
								display: 'inline-block',
								height: 'auto',
								maxWidth: '100%',
								width: 1000,
							}} />
					</Grid>
				</Grid>
			}
			{ !error && data &&
				data.school &&
					<Grid alignItems="center" container direction="column" justify="center" spacing={ 4 }>
						<Grid item xs={ 12 }>
							<Typography align="center" className={ classes.paragraph } gutterBottom variant="h5">{ data.school.name }</Typography>
							<Typography align="right" className={ classes.paragraph } gutterBottom variant="h6">{ data.school.type }, { data.school.firstParticipation }</Typography>
							<Typography align="left" className={ classes.paragraph } variant="body2">{ data.school.address }</Typography>
							<Typography align="left" className={ classes.paragraph } variant="body2">{ data.school.postalCode } { data.school.city }</Typography>
							<Typography align="left" className={ classes.paragraph } paragraph variant="body2">{ data.school.regionalNetwork }</Typography>
							<Typography align="left" className={ classes.paragraph } gutterBottom variant="body1">Kontaktmöglichkeiten:</Typography>
							<Typography align="left" className={ classes.paragraph } paragraph variant="body2">
								{ !_.isEmpty( data.school.email ) && ( <Link href={ `mailto:${ data.school.email }` }>• { data.school.email }</Link> ) }{ ' ' }
								{ !_.isEmpty( data.school.website ) && ( <Link href={ data.school.website } target="_blank">• { data.school.website }</Link> ) }{ ' ' }
								{ !_.isEmpty( data.school.telephone ) && ( <Link href={ `tel:${ data.school.telephone }` }>• { data.school.telephone }</Link> ) }
							</Typography>
							<Typography align="left" className={ classes.paragraph } gutterBottom variant="body1">Kommentar zur Schule:</Typography>
							<Typography align="left" className={ classes.paragraph } component="pre" paragraph variant="body2">{ data.school.comment }</Typography>
						</Grid>
					</Grid>
			}
		</>
	)
}

export default SchoolDetail
