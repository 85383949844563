/**
 * StatusBullet.jsx
 *
 * @file This file exports a custom status bullet component.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import styles from './StatusBullet.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * Create a custom status bullet.
 *
 * @param {object} props The component props.
 * @returns {Node} The rendered component.
 */
const StatusBullet = ({ size, color, ...other }) => {
	const classes = useStyles()

	return (
    	<Box
    		className={ clsx(
        		{
        			[ classes.root ]: true,
        			[ classes[ size ] ]: size,
        			[ classes[ color ] ]: color
        		}
			) }
			component="span"
			{ ...other } />
	)
}

StatusBullet.defaultProps = {
	color: 'default',
	size: 'md'
}

/**
 *
 */
StatusBullet.propTypes = {
	color: PropTypes.oneOf( [
		'error',
		'info',
		'neutral',
    	'primary',
    	'success',
    	'warning'
	] ),
	size: PropTypes.oneOf( [ 'lg', 'md', 'sm' ] )
}

export default StatusBullet
