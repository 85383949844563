/**
 * LatestParticipants.jsx
 *
 * @file This file exports a tile view which shows the latest participants.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { ArrowRight } from '@material-ui/icons'
import {
	Box,
	Card,
	CardActions,
	CardHeader,
	CardContent,
	Button,
	Divider,
	LinearProgress,
} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import logger from "loglevel"
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { pages } from '../Sidebar'
import { StatusBullet } from '../StatusBullet'
import styles from './LatestParticipants.styles'
import { transformAppellation } from '../../utils'

// Create Styles
const useStyles = makeStyles(styles)

const statusColors = {
	ABGELEHNT: 'error',
	ABGEMELDET: 'info',
	ABSENT: 'warning',
	ANGEMELDET: 'info',
	TEILGENOMMEN: 'success',
	ZUGELASSEN: 'success',
}

/**
 * Create the latest participants tile.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const LatestParticipants = ({ paginatorInfo, participants, ...other }) => {
	const classes = useStyles()

	const renderCellEvent = (params) => {

		return (
			<ButtonLink
				color="primary"
				size="small"
				to={ `${ pages[ 1 ].href }/${ params.value.id }` }
				variant="text">
				{ params.value.eventID }
			</ButtonLink>
		)
	}

	const renderCellStatus = (params) => {
		logger.debug(params)

		// TODO: StatusBullet isn't shown
		return (
			<Box className={ classes.statusContainer }>
				<StatusBullet
					className={ classes.status }
					color={ statusColors[ params.value ] }
					size="sm" />
					{ params.value.charAt(0) + params.value.slice(1).toLowerCase() }
			</Box>
		)
	}

	const columns = [
		{ field: "dbId", headerName: "#ID", sortable: false, width: 60 },
		{ field: "participant", headerName: "Teilnehmer", hideable: false, minWidth: 120, sortable: false, width: 240 },
		{ field: "signedUp", headerName: "Angemeldet am", sortable: false, width: 150 },
		{ field: "status", headerName: "Status", renderCell: renderCellStatus, sortable: false, width: 225 },
		{ field: "event", headerName: "Event#ID", hideable: false, renderCell: renderCellEvent, sortable: false, width: 225 },
	]

	const rows = []
	participants.forEach((participant) => {
		rows.push({
			dbId: participant.id,
			event: participant.participation.event,
			id: participant.uuid,
			participant: `${ transformAppellation(participant.appellation) } ${ participant.firstName } ${ participant.lastName }`,
			signedUp: moment(participant.createdAt).format("DD.MM.YYYY"),
			status: participant.status,
		})
	})

	return (
    	<Card className={ classes.root } { ...other }>
    		<CardHeader title={ `Neueste Anmeldungen (${ paginatorInfo.count })` } />
    		<Divider />
    		<CardContent className={ classes.content }>
        		<PerfectScrollbar>
        			<Box className={ classes.inner }>
						<DataGrid
							autoHeight
							columns={ columns }
							components={{ LoadingOverlay: LinearProgress }}
							disableColumnMenu
							disableColumnFilter
							disableDensitySelector
							disableSelectionOnClick
							hideFooterPagination
							pageSize={ 10 }
							rows={ rows } />
        			</Box>
        		</PerfectScrollbar>
    		</CardContent>
    		<Divider />
    		<CardActions className={ classes.actions }>
        		<Button
        			color="primary"
        			size="small"
					to={ pages[3].href }
        			variant="text">
        			Alle anzeigen ({ paginatorInfo.total }) <ArrowRight />
        		</Button>
    		</CardActions>
    	</Card>
	)
}

/**
 *
 */
LatestParticipants.propTypes = {
	paginatorInfo: PropTypes.shape( {
		count: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired
	} ).isRequired,
	participants: PropTypes.arrayOf( PropTypes.shape( {
		appellation: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		participation: PropTypes.shape( {
			event: PropTypes.shape( {
				eventID: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired
			} ).isRequired,
			id: PropTypes.string
		} ),
		status: PropTypes.string.isRequired,
		uuid: PropTypes.string.isRequired
	} ).isRequired ).isRequired
}

export default LatestParticipants
