/**
 * wrapWithProviders.jsx
 *
 * @file This component is injected via Gatsby's Browser & SSR APIs,
 * to wrap the root element of this web application with all necessary
 * providers.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash/core'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ApolloProvider } from '@apollo/client'
import { createGenerateClassName } from '@material-ui/core/styles'
import { createTheme as createThemeV4 } from '@material-ui/core/styles'
import {
	createTheme as createThemeV5,
	responsiveFontSizes,
	StyledEngineProvider,
	ThemeProvider as ThemeProviderV5
} from '@mui/material/styles'
import { deDE as deDEv4 } from '@material-ui/core/locale'
import { deDE as deDEv5 } from '@mui/material/locale'
import { grey } from '@mui/material/colors'
import { deDE as gridDeDE } from '@mui/x-data-grid'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import { PersistGate } from 'redux-persist/integration/react'
import { deDE as pickersDeDE } from '@mui/x-date-pickers/locales'
import { Provider as ReduxProvider } from 'react-redux'
import React, { useMemo } from 'react'
import { setContext } from '@apollo/client/link/context'
import { StylesProvider, ThemeProvider as ThemeProviderV4 } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'

// internal imports
import { claimAccessTokenWithRefreshToken } from './services/auth'
import {
	cleanAccessToken,
	cleanAccessTokenFromSession,
	cleanRefreshToken,
	persistor,
	selectAuthAccessToken,
	selectAuthRefreshToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn,
	selectSettingFontSize,
	selectSettingUIMode,
	setAccessTokenExpires,
	setRefreshTokenExpires,
	store,
	storeAccessToken,
	storeRefreshToken
} from './state'
import { createApolloClient } from './Context'
import {
	darkPalette,
	lightPalette,
	darkNprogress,
	lightNprogress,
	typography
} from './styles'

const generateClassName = createGenerateClassName({
	// By enabling this option, if you have non-MUI elements (e.g. `<div />`)
	// using MUI classes (e.g. `.MuiButton`) they will lose styles.
	// Make sure to convert them to use `styled()` or `<Box />` first.
	disableGlobal: true,
	// Class names will receive this seed to avoid name collisions.
	seed: 'mui-jss',
})

const WithCustomizableThemeProvider = ({ element }) => {
	const prefersDarkMode = useMediaQuery('( prefers-color-scheme: dark )')

	/** Receive the font size state from the store. */
	const fontSize = useSelector( selectSettingFontSize )
	/** Receive the font size state from the store. */
	const uiMode = useSelector( selectSettingUIMode )

	let useDarkMode = null

	if ( uiMode === 'light' )
		useDarkMode = false
	else if ( uiMode === 'dark' )
		useDarkMode = true
	else
		useDarkMode = prefersDarkMode

	const themeV5 = useMemo(
		() => responsiveFontSizes(
			createThemeV5({
				nprogress: useDarkMode ? darkNprogress : lightNprogress,
				palette: useDarkMode ? darkPalette : lightPalette,
				shape: {
					borderRadius: 4,
				},
				spacing: 8,
				typography: {
					...typography,
					fontSize: fontSize,
				},
				zIndex: {
					appBar: 1200,
					drawer: 1100,
				},
				components: {
					MuiButton: {
						contained: {
							backgroundColor: ( prefersDarkMode ? darkPalette : lightPalette ).common.white,
							boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.20)',
						},
					},
					MuiIconButton: {
						root: {
							color: ( prefersDarkMode ? darkPalette : lightPalette ).icon,
							'&:hover': {
								backgroundColor: 'rgba(0, 0, 0, 0.03)',
							},
						},
					},
					MuiLink: {
						defaultProps: {
							underline: 'hover',
						},
					},
					MuiPaper: {
						elevation1: {
							boxShadow: '0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)',
						},
					},
					MuiTableCell: {
						root: {
							...typography( prefersDarkMode ? darkPalette : lightPalette ).body1,
							borderBottom: `1px solid ${ ( prefersDarkMode ? darkPalette : lightPalette ).divider }`,
						},
					},
					MuiTableHead: {
						root: {
							backgroundColor: grey[ 50 ],
						},
					},
					MuiTableRow: {
						root: {
							'&$selected': {
								backgroundColor: ( prefersDarkMode ? darkPalette : lightPalette ).background.default,
							},
							'&$hover': {
								'&:hover': {
									backgroundColor: ( prefersDarkMode ? darkPalette : lightPalette ).background.default,
								},
							},
						},
					},
					MuiTextField: {
						defaultProps: {
							variant: 'standard',
						},
					},
				},
			}, deDEv5, gridDeDE, pickersDeDE),
			{
				breakpoints: [ 'sm', 'md', 'lg' ],
				disableAlign: false,
				factor: 2,
				variants: [
					'body1',
					'body2',
					'button',
					'caption',
					'h1',
					'h2',
					'h3',
					'h4',
					'h5',
					'h6',
					'overline',
					'subtitle1',
					'subtitle2',
				],
			},
		),
		[ fontSize, useDarkMode ]
	)

	const themeV4 = useMemo(
		() => responsiveFontSizes(
			createThemeV4({
				nprogress: themeV5.nprogress,
				palette: themeV5.palette,
				shape: themeV5.shape,
				spacing: themeV5.spacing,
				typography: themeV5.typography,
				zIndex: themeV5.zIndex,
				overrides: {
					MuiButton: {
						contained: {
							backgroundColor: ( prefersDarkMode ? darkPalette : lightPalette ).common.white,
							boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.20)',
						},
					},
					MuiIconButton: {
						root: {
							color: ( prefersDarkMode ? darkPalette : lightPalette ).icon,
							'&:hover': {
								backgroundColor: 'rgba(0, 0, 0, 0.03)',
							},
						},
					},
					MuiPaper: {
						elevation1: {
							boxShadow: '0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)',
						},
					},
					MuiTableCell: {
						root: {
							...typography( prefersDarkMode ? darkPalette : lightPalette ).body1,
							borderBottom: `1px solid ${ ( prefersDarkMode ? darkPalette : lightPalette ).divider }`,
						},
					},
					MuiTableHead: {
						root: {
							backgroundColor: grey[ 50 ],
						},
					},
					MuiTableRow: {
						root: {
							'&$selected': {
								backgroundColor: ( prefersDarkMode ? darkPalette : lightPalette ).background.default,
							},
							'&$hover': {
								'&:hover': {
									backgroundColor: ( prefersDarkMode ? darkPalette : lightPalette ).background.default,
								},
							},
						},
					},
				},
				props: {
					MuiLink: {
						underline: 'hover',
					},
					MuiTextField: {
						variant: 'standard',
					},
				},
			}, deDEv4),
			{
				breakpoints: [ 'sm', 'md', 'lg' ],
				disableAlign: false,
				factor: 2,
				variants: [
					'body1',
					'body2',
					'button',
					'caption',
					'h1',
					'h2',
					'h3',
					'h4',
					'h5',
					'h6',
					'overline',
					'subtitle1',
					'subtitle2',
				],
			},
		),
		[ themeV5 ]
	)

	return (
		<StylesProvider generateClassName={generateClassName}>
			<ThemeProviderV4 theme={ themeV4 }>
				<ThemeProviderV5 theme={ themeV5 }>
					<LocalizationProvider dateAdapter={ AdapterMoment }>
						{ element }
					</LocalizationProvider>
				</ThemeProviderV5>
			</ThemeProviderV4>
		</StylesProvider>
	)
}

const WithProviders = ({ element }) => {
	/** Retrieve the `auth` access token from the store. */
	const authAccessToken = useSelector( selectAuthAccessToken )
	/** Retrieve the `auth` refresh token from the store. */
	const authRefreshToken = useSelector( selectAuthRefreshToken )
	/** Retrieve the `authSession` access token from the store. */
	const authSessionAccessToken = useSelector( selectAuthSessionAccessToken )
	/** Retrieve the `authSession` stay logged-in value. */
	const authSessionStayLoggedIn = useSelector( selectAuthSessionStayLoggedIn )

	const dispatch = useDispatch()

	const client = useMemo(
		() => createApolloClient(
			setContext( (req, { headers }) => {
				const now = moment()

				let accessToken = null, refreshToken = null
				if ( authSessionStayLoggedIn ) {
					if ( !_.isEmpty( authSessionAccessToken ) ) dispatch( cleanAccessTokenFromSession )

					accessToken = now.isBefore( moment.unix( authAccessToken.expires ) ) ? authAccessToken.token : null
					refreshToken = now.isBefore( moment.unix( authRefreshToken.expires ) ) ? authRefreshToken.token : null
					if ( !_.isEmpty( accessToken ) )
						return {
							headers: {
								...headers,
								authorization: `Bearer ${ accessToken }`
							}
						}

					else if ( _.isEmpty( accessToken ) && !_.isEmpty( refreshToken ) ) {
						return claimAccessTokenWithRefreshToken( refreshToken )
							.then( res => {
								const tokenData = res.data

								dispatch( storeAccessToken( tokenData.access_token ) )
								dispatch( setAccessTokenExpires( now.add( tokenData.expires_in, 'seconds' ).unix() ) )
								dispatch( storeRefreshToken( tokenData.refresh_token ) )
								dispatch( setRefreshTokenExpires( now.add( 30, 'days' ).unix() ) )

								return {
									headers: {
										...headers,
										authorization: `Bearer ${ tokenData.access_token }`
									}
								}
							} )
					}
				}
				else {
					if ( !_.isEmpty( authAccessToken.token ) ) dispatch( cleanAccessToken )
					if ( !_.isEmpty( authRefreshToken.token ) ) dispatch( cleanRefreshToken )

					accessToken = authSessionAccessToken
					return {
						headers: {
							...headers,
							authorization: `Bearer ${ accessToken }`
						}
					}
				}

				return headers
			} )
		),
		[ authAccessToken, authRefreshToken, authSessionAccessToken, authSessionStayLoggedIn, dispatch ]
	)

	return (
		<ApolloProvider client={ client }>
			<WithCustomizableThemeProvider element={ element } />
		</ApolloProvider>
    )
}

/** Wrap all content with the necessary context providers. */
export default ({ element }) => {

	return (
		<ReduxProvider store={ store }>
			<PersistGate loading={ null } persistor={ persistor }>
				<WithProviders element={ element } />
			</PersistGate>
		</ReduxProvider>
	)
}
