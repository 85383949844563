/**
 * Profile.jsx
 *
 * @file This file exports a component which creates the profile view of
 * the sidebar.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Avatar, Badge, Box, Typography } from '@material-ui/core'
import { gql, useQuery } from '@apollo/client'
import { makeStyles, withStyles } from '@material-ui/styles'
import md5 from 'md5'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// internal imports
import { Link } from '../Link'
import styles from './Profile.styles'

// Create styles
const StyledBadge = withStyles( theme => ( {
	badge: {
    	backgroundColor: theme.palette.success.main,
    	boxShadow: `0 0 0 2px ${ theme.palette.background.paper }`,
    	'&::after': {
			animation: '$ripple 1.2s infinite ease-in-out',
    		border: `1px solid ${ theme.palette.success.main }`,
			borderRadius: '50%',
    		content: '""',
			left: 0,
			height: '100%',
    		position: 'absolute',
    		top: 0,
    		width: '100%'
    	}
	},
	'@keyframes ripple': {
    	'0%': {
			opacity: 1,
    		transform: 'scale( .8 )'
    	},
    	'100%': {
			opacity: 0,
    		transform: 'scale( 2.4 )'
		}
	}
} ) )( Badge )
const useStyles = makeStyles( styles )

/** Query to get the users profile from the server. */
const GET_PROFILE = gql`
	query GetProfile {
    	me {
			email
			id
			meta
			name
		}
	}
`

/**
 * Create the profile view of the sidebar.
 *
 * @returns {Node} The rendered component.
 */
const Profile = () => {

	const classes = useStyles()

	const dispatch = useDispatch()

	/** Fetch the users profile from the server. */
	const { data } = useQuery( GET_PROFILE )

	let avatar = ''
	let role   = ''

	if ( data && data.me ) {

		const meta = JSON.parse( data.me.meta )

		switch ( meta.role ) {

			case 'a':
				role = 'Administrator'
				break

		}

		if ( meta.avatar ) avatar = meta.avatar
		else avatar = `//www.gravatar.com/avatar/${ md5( data.me.email ) }?d=identicon&r=g&s=120`

	}

	return (
    	<Box className={ classes.root }>
			{ ( data && data.me ) &&
				<>
					<StyledBadge
						anchorOrigin={ {
							horizontal: 'right',
							vertical: 'bottom'
						} }
						overlap="circular"
						variant="dot">
						<Avatar
							alt={ role }
							className={ classes.avatar }
							component={ Link }
							src={ avatar }
							to="/users/me" />
					</StyledBadge>
					<Typography className={ classes.name } variant="h4">{ data.me.name }</Typography>
					<Typography variant="body2">{ role }</Typography>
				</>
			}
		</Box>
	)

}

export default Profile
