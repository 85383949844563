/**
 * Minimal.jsx
 *
 * @file This file exports a component which creates a minimal layout
 * for the admin interface.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { Box } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { makeStyles } from '@material-ui/styles'
import NProgressBar from '@material-ui/docs/NProgressBar'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import { CookieBanner } from '../CookieBanner'
import styles, { name } from './Layout.styles'
import { Topbar } from '../Topbar'

// Create styles
const useStyles = makeStyles(styles, { name })

/**
 * Create the main layout.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered component.
 */
const Minimal = ({ children }) => {
	const classes = useStyles()

	return (
		<>
			<GatsbySeo
				canonical={ process.env.GATSBY_SITE_URL }
				title="Hello" />
			<Box className={ classes.rootMinimal }>
				<NProgressBar />
				<CssBaseline />
				<Topbar minimal />
				<Box className={ classes.contentWrapper } component="main">
					<Box className={ classes.content }>
						{ children }
					</Box>
				</Box>
				<CookieBanner />
			</Box>
		</>
	)
}

/**
 *
 */
Minimal.propTypes = {
	children: PropTypes.node.isRequired
}

export default Minimal
