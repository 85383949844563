/**
 * 404.jsx
 *
 * @file This file exports a custom 404 error page.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Lottie from 'lottie-react'
import React from 'react'
import { Typography } from '@material-ui/core'

// internal imports
/**!
 * Copyright © Fabio Oliveira
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/fabioooliveira
 * @link https://lottiefiles.com/4047-404-animation?lang=en
 */
import animationData from '../assets/animations/NotFoundAnimation.json'

/**
 * This component creates a custom 404 error page.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const NotFoundPage = () => {

	return (
		<>
			<GatsbySeo title="404 - Seite nicht gefunden" />
			<Typography variant="h1">
				404 - Seite nicht gefunden
			</Typography>
			<Typography variant="subtitle2">
				Die von Ihnen angeforderte Seite konnte nicht gefunden werden.
			</Typography>
			<Lottie
				animationData={ animationData }
				autoplay={ true }
				loop={ true }
				style={{
					display: 'inline-block',
					height: 'auto',
					marginTop: 50,
					maxWidth: '100%',
					width: '100%',
				}} />
		</>
	)
}

export default NotFoundPage
