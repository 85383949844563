/**
 * ActiveNews.jsx
 *
 * @file This file exports a tile view which shows the current active news.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { ArrowRight, Notes } from '@material-ui/icons'
import {
	Avatar,
	Card,
	CardActions,
	CardContent,
	Grid,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { pages } from '../Sidebar'
import styles from './ActiveNews.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * Create the active news tile.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const ActiveNews = ({ count, ...other }) => {
	const classes = useStyles()

	return (
    	<Card className={ classes.root } { ...other }>
    		<CardContent>
        		<Grid container justifyContent="space-between">
        			<Grid item>
            			<Typography
            				className={ classes.title }
            				color="textSecondary"
            				gutterBottom
            				variant="body2">
            				AKTUELLE NEWS
            			</Typography>
            			<Typography variant="h3">Anzahl: { count }</Typography>
        			</Grid>
        			<Grid item>
            			<Avatar className={ classes.avatar }>
            				<Notes className={ classes.icon } />
            			</Avatar>
        			</Grid>
        		</Grid>
    		</CardContent>
    		<CardActions className={ classes.actions }>
        		<ButtonLink
        			color="primary"
					size="small"
					to={ pages[5].href }
        			variant="text">
        			Alle anzeigen <ArrowRight />
        		</ButtonLink>
    		</CardActions>
    	</Card>
	)
}

/**
 *
 */
ActiveNews.propTypes = {
	count: PropTypes.number.isRequired
}

export default ActiveNews
