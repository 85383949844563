/**
 * CookieBanner.jsx
 *
 * @file This component creates a cookie banner.
 * @author Robin Walter <hello@robinwalter.me>
 */

import {
	Button,
	Checkbox,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	IconButton,
	Link as MuiLink,
	Slide,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Toolbar,
	Tooltip,
	Typography,
} from '@material-ui/core'
import { Delete, ExpandLess, ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// internal imports
import {
	changePermissionSocial,
	cookieInitialState,
	save,
	selectCookieAllowedSocial,
	selectCookieEssential,
	selectCookiePreferences,
	selectCookieSaved,
	selectCookieSocial,
	selectCookieStats,
	selectCookieVisibility
} from '../../state'
import styles from './CookieBanner.styles'

// Create styles
const useStyles = makeStyles( styles )

/** Create a wrapper around the configured to `Slide` component to support `React.forwardRef` */
const Transition = React.forwardRef( ( props, ref ) => <Slide direction="up" ref={ ref } { ...props } /> )

/**
 * Create a cookie alert to inform the user about the use of
 * cookies on this site.
 *
 * @param {Object} props The component props.
 * @return {Node} The rendered component.
 */
const CookieBanner = () => {
	const classes = useStyles()

	/** Get the cookie preference for allowed social media content from the store. */
	const allowedSocial = useSelector( selectCookieAllowedSocial )
	/** Get the `essential` preference from the redux store. */
	const essential = useSelector( selectCookieEssential )
	/** Get the `preferences` preference from the redux store. */
	const preferences = useSelector( selectCookiePreferences )
	/** Get the `saved` preference from the redux store. */
	const saved = useSelector( selectCookieSaved )
	/** Get the `social` preference from the redux store. */
	const social = useSelector( selectCookieSocial )
	/** Get the `stats` preference from the redux store. */
	const stats = useSelector( selectCookieStats )
	/** Get the `isVisible` preference from the redux store. */
	const visibility = useSelector( selectCookieVisibility )

	const dispatch = useDispatch()

	/** Define a state to store the cookie preferences. */
	const [ cookiePref, setCookiePref ] = useState( {
		essential,
		preferences,
		social,
		stats
	} )
	/** Define a state to control the preferences accordion. */
	const [ isOpenAccordion, setOpenAccordion ] = useState( true )
	/** Define a state to control the social embeds accordion. */
	const [ isOpenSocialEmbeds, setOpenSocialEmbeds ] = useState( false )
	/** Define a state to store the visibility of the dialog. */
	const [ isVisible, setIsVisible ] = useState( !saved || visibility )

	/**
	 * Handler to save the user preferences. This action handler
	 * dispatches a new state and saves it to the redux store. Afterwards
	 * the dialog will be closed.
	 */
	const savePreferences = () => {
        dispatch( save( {
			...cookiePref,
			isVisible: false,
			saved: true
		} ) )

		setIsVisible( false )
	}

	useEffect( () => {
        if ( saved && typeof window !== 'undefined' && typeof window._paq !== 'undefined' ) {
            if ( stats ) {
                window._paq.push( [ 'setConsentGiven' ] )
                window._paq.push( [ 'rememberConsentGiven' ] )
            }
            else {
                window._paq.push( [ 'forgetConsentGiven' ] )
                window._paq.push( [ 'disableCookies' ] )
            }
        }
	} )

	useMemo(
		() => {
			if ( saved ) {
				if ( isVisible !== visibility ) setIsVisible( visibility )
			}
		},
		[ isVisible, saved, setIsVisible, visibility ]
	)

	const SocialEmbeds = []

	for ( const service in allowedSocial ) {
		SocialEmbeds.push(
			<FormControlLabel
				control={ (
					<Switch
						checked={ allowedSocial[ service ] }
						onChange={ event => { dispatch( changePermissionSocial( { social: service, value: event.target.checked } ) ) } }
						size="small" />
				) }
				disabled={ !cookiePref.social }
				key={ service }
				label={ !allowedSocial[ service ] ? `${ service }-Inhalte erlauben?` : `${ service }-Inhalte erlaubt!` } />
		)
	}

	return (
		<Dialog
			aria-describedby="cookie-dialog-description"
			aria-labelledby="cookie-dialog-title"
			className={ classes.dialog }
			fullWidth
			keepMounted
			maxWidth="lg"
			open={ isVisible }
			TransitionComponent={ Transition }>
			<DialogTitle id="cookie-dialog-title">Cookie-Hinweis</DialogTitle>
			<DialogContent>
				<DialogContentText id="cookie-dialog-title">
					Wir verwenden Cookies, um Funktionen für soziale Medien anbieten zu können und um die Zugriffe auf unsere Website zu analysieren. Außerdem werden funktionale Cookies von der Website gesetzt, damit diese richtig funktionieren kann.
					<br /><br />
					Weitere Informationen zu der Verwendung von Cookies finden Sie in unserer <MuiLink href={ process.env.GATSBY_SITE_URL_PRIVACY_POLICY }>Datenschutzerklärung</MuiLink>.
				</DialogContentText>
				<Toolbar className={ classes.toolbarRoot }>
					<Typography className={ classes.toolbarTitle } component="div" id="tableTitle" variant="h6">
						Cookie-Präferenzen
						<Tooltip title={ isOpenAccordion ? `Verberge Tabelle` : `Zeige Tabelle` }>
							<IconButton
								aria-label={ isOpenAccordion ? `Verberge Tabelle` : `Zeige Tabelle` }
								onClick={ () => { setOpenAccordion( !isOpenAccordion ) } }>
								{ isOpenAccordion ? <ExpandLess /> : <ExpandMore /> }
							</IconButton>
						</Tooltip>
					</Typography>
					<Tooltip title="Auswahl zurücksetzen">
						<IconButton aria-label="Auswahl zurücksetzen" onClick={ () => {
							setCookiePref( {
								essential: cookieInitialState.essential,
								preferences: cookieInitialState.preferences,
								social: cookieInitialState.social,
								stats: cookieInitialState.stats
							} )
						} }>
							<Delete />
						</IconButton>
					</Tooltip>
				</Toolbar>
				<Collapse in={ isOpenAccordion } timeout="auto" unmountOnExit>
					<TableContainer>
						<Table aria-labelledby="tableTitle" className={ classes.table }>
							<TableHead>
								<TableRow>
									<TableCell padding="checkbox">
										<Checkbox
											indeterminate={
												cookiePref.essential && ( cookiePref.preferences || cookiePref.social || cookiePref.stats ) &&
												!( cookiePref.essential && cookiePref.preferences && cookiePref.social && cookiePref.stats )
											}
											checked={ cookiePref.essential && cookiePref.preferences && cookiePref.social && cookiePref.stats }
											onChange={ e => {
												setCookiePref( {
													...cookiePref,
													preferences: e.target.checked,
													social: e.target.checked,
													stats: e.target.checked
												} )
											} }
											inputProps={ { 'aria-label': 'Wählen Sie alle Einstellungen aus' } } />
									</TableCell>
									<TableCell>Wert</TableCell>
									<TableCell>Beschreibung</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow
									aria-checked={ cookiePref.essential }
									hover
									role="checkbox"
									tabIndex={ -1 }>
									<TableCell padding="checkbox">
										<Switch checked={ cookiePref.essential } disabled inputProps={ { 'aria-labelledby': 'cookie-essential' } } />
									</TableCell>
									<TableCell component="th" id="cookie-essential" scope="row" padding="none">Notwendig</TableCell>
									<TableCell>Notwendige Cookies unterstützen die Grundfunktionen dieser Website. Ohne diese Cookies kann die Website nicht funktionieren und wäre damit auch nicht nutzbar.</TableCell>
								</TableRow>
								<TableRow
									aria-checked={ cookiePref.preferences }
									hover
									onClick={ () => { setCookiePref( { ...cookiePref, preferences: !cookiePref.preferences } ) } }
									role="checkbox"
									selected={ cookiePref.preferences }
									tabIndex={ -1 }>
									<TableCell padding="checkbox">
										<Switch checked={ cookiePref.preferences } inputProps={ { 'aria-labelledby': 'cookie-preferences' } } />
									</TableCell>
									<TableCell component="th" id="cookie-preferences" scope="row" padding="none">Präferenzen</TableCell>
									<TableCell>Diese Cookies ermöglichen es unter anderem von Ihnen vorgenommene Einstellungen zu speichern, um Ihnen das bestmögliche Nutzungserlebnis zu ermöglichen.</TableCell>
								</TableRow>
								<TableRow
									aria-checked={ cookiePref.stats }
									hover
									onClick={ () => { setCookiePref( { ...cookiePref, stats: !cookiePref.stats } ) } }
									role="checkbox"
									selected={ cookiePref.stats }
									tabIndex={ -1 }>
									<TableCell padding="checkbox">
										<Switch checked={ cookiePref.stats } inputProps={ { 'aria-labelledby': 'cookie-stats' } } />
									</TableCell>
									<TableCell component="th" id="cookie-stats" scope="row" padding="none">Statistiken</TableCell>
									<TableCell>Statistik-Cookies helfen uns dabei zu verstehen, wie Sie und andere Nutzer mit unserer Website interagieren und mit welchen Geräten auf unsere Website zugegriffen wird. Diese Daten werden anonymisiert gesammelt und ausgewertet.</TableCell>
								</TableRow>
								<TableRow
									aria-checked={ cookiePref.social }
									hover
									role="checkbox"
									selected={ cookiePref.social }
									tabIndex={ -1 }>
									<TableCell onClick={ () => { setCookiePref( { ...cookiePref, social: !cookiePref.social } ) } } padding="checkbox">
										<Switch checked={ cookiePref.social } inputProps={ { 'aria-labelledby': 'cookie-social' } } />
									</TableCell>
									<TableCell component="th" id="cookie-social" scope="row" padding="none">Soziale Medien</TableCell>
									<TableCell>
										<Typography paragraph variant="body2">Auf einigen unserer Seiten binden wir Inhalte von Sozialen Medien ein. Diese Dienste nutzen ebenfalls Cookies. Sie können das einbinden von Inhalten aus Sozialen Medien mit dieser Einstellung unterbinden. Hinweise dazu, wie Drittanbieter mit Ihren Daten umgehen, finden Sie in unserer <MuiLink href={ `${ process.env.GATSBY_SITE_URL_PRIVACY_POLICY }#m328` }>Datenschutzerklärung</MuiLink>.</Typography>
										<Typography gutterBottom variant="caption">
											Erlaubte Inhalte von Sozialen Medien
											<Tooltip title={ isOpenSocialEmbeds ? `Verberge Soziale Medien` : `Zeige Soziale Medien` }>
												<IconButton
													aria-label={ isOpenSocialEmbeds ? `Verberge Soziale Medien` : `Zeige Soziale Medien` }
													onClick={ () => { setOpenSocialEmbeds( !isOpenSocialEmbeds ) } }
													size="small">
													{ isOpenSocialEmbeds ? <ExpandLess /> : <ExpandMore /> }
												</IconButton>
											</Tooltip>
										</Typography>
										<Collapse in={ isOpenSocialEmbeds } timeout="auto" unmountOnExit>
											<FormGroup>{ SocialEmbeds }</FormGroup>
										</Collapse>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Collapse>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={ savePreferences }>Einstellungen speichern</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CookieBanner
