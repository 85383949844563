/**
 * LatestEvents.jsx
 *
 * @file This file exports a tile view which shows the latest events.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { ArrowRight } from '@material-ui/icons'
import {
	Avatar,
	Badge,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip
} from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { IconButtonLink } from '../IconButtonLink'
import { pages } from '../Sidebar'
import styles from './LatestEvents.styles'

// Create styles
const useStyles = makeStyles(styles)

const statusColors = {
	ERFASST:     'white',   //  1
	FREIGEGEBEN: 'info',    //  2
	GEOEFFNET:   'info',    //  3
	GESCHLOSSEN: 'success', //  4
	MOEGLICH:    'warning', //  5
	FRAGLICH:    'warning', //  6
	UNGENUEGEND: 'error',  //  7
	GESTRICHEN:  'error',  //  8
	ABGESAGT:    'error',  //  9
	GELAUFEN:    'success'  // 10
}

/**
 * Create the latest events tile.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const LatestEvents = ({ events, paginatorInfo, ...other }) => {
	const classes = useStyles()
	const theme   = useTheme()

	return (
    	<Card className={ classes.root } { ...other }>
    		<CardHeader subtitle={ `${ paginatorInfo.total } insgesamt` } title="Aktuelle Events" />
    		<Divider />
    		<CardContent className={ classes.content }>
        		<List>
        			{events.map((event, i) => {
						const color = theme.palette[ statusColors[ event.status ] ].main ?
								theme.palette[ statusColors[ event.status ] ].main
							:
								theme.palette[ statusColors[ event.status ] ]

						const StyledBadge = withStyles(() => ({
							badge: {
						    	backgroundColor: color,
							},
						}))(Badge)

						let status = ''
						switch (event.status) {
							case 'ERFASST': // 1
								status = 'Erfasst'
								break
							case 'FREIGEGEBEN': // 2
								status = 'Freigegeben'
								break
							case 'GEOEFFNET': // 3
								status = 'Geöffnet'
								break
							case 'GESCHLOSSEN': // 4
								status = 'Geschlossen'
								break
							case 'MOEGLICH': // 5
								status = 'Möglich'
								break
							case 'FRAGLICH': // 6
								status = 'Fraglich'
								break
							case 'UNGENUEGEND': // 7
								status = 'Ungenügend'
								break
							case 'GESTRICHEN': // 8
								status = 'Gestrichen'
								break
							case 'ABGESAGT': // 9
								status = 'Abgesagt'
								break
							case 'GELAUFEN': // 10
								status = 'Gelaufen'
								break
						}

						let type = ''
						let splittedEventType = event.type.type.split("_")
						if (splittedEventType.length > 1) {
							for (let i = 0; i < splittedEventType.length; i++) {
								type += splittedEventType[i].charAt(0)
							}
						}
						else {
							type = event.type.type[0]
						}
						type = type.toUpperCase()

						return (
	            			<ListItem divider={ i < events.length - 1 } key={ event.id }>
	            				<ListItemAvatar>
									<Tooltip placement="top-end" title={ status }>
										<StyledBadge
											anchorOrigin={{
												horizontal: 'right',
												vertical: 'top',
											}}
											overlap="circular"
											variant="dot">
											<Avatar className={ classes.avatar }>{ type }</Avatar>
										</StyledBadge>
									</Tooltip>
	            				</ListItemAvatar>
	            				<ListItemText primary={ event.eventID } secondary={ JSON.parse( event.regionalNetworks ).cities.join( ', ' ) } />
	            				<IconButtonLink
	                				edge="end"
	                				size="small"
									to={ `${ pages[ 1 ].href }/${ event.id }` }>
	                				<ArrowRight />
	            				</IconButtonLink>
	            			</ListItem>
        				)
					})}
        		</List>
    		</CardContent>
    		<Divider />
    		<CardActions className={ classes.actions }>
        		<ButtonLink
        			color="primary"
        			size="small"
					to={ pages[ 1 ].href }
					variant="text">
        			Alle anzeigen <ArrowRight />
        		</ButtonLink>
    		</CardActions>
    	</Card>
	)
}

/**
 *
 */
LatestEvents.propTypes = {
	className: PropTypes.string
}

export default LatestEvents
