/**
 * schedule.jsx
 *
 * @file This file exports a view to create new events.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import { Alert } from '@material-ui/lab'
import {
	Button,
	Grid,
	Snackbar,
	Typography
} from '@material-ui/core'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import logger from "loglevel"
import moment from "moment"
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// internal imports
import { EventScheduler } from '../../components/EventScheduler'
import { redirectUnauthenticatedToSignIn } from '../../services/auth'
import {
	selectAuthAccessToken,
	selectAuthSessionAccessToken,
	selectAuthSessionStayLoggedIn
} from '../../state'

/** Mutation to create a new event. */
const ADD_EVENT = gql`
	mutation addEvent(
		$calendarID: String
		$description: String
		$end: DateTime!
		$eventID: String!
		$fee: Boolean
		$htmlLink: String
		$icalUID: String
		$initialRegistrationPeriod: Date!
		$lecturerFallback: String
		$lecturerID: ID
		$periodOfRest: Date!
		$regionalNetworks: JSON!
		$secondRegistrationPeriod: Date!
		$start: DateTime!
		$status: EventStatus
		$summary: String!
		$type: ID!
		$venueID: ID
	) {
		createEvent( input: {
			calendarID: $calendarID
			description: $description
			end: $end
			eventID: $eventID
			fee: $fee
			htmlLink: $htmlLink
			icalUID: $icalUID
			initialRegistrationPeriod: $initialRegistrationPeriod
			lecturer: {
				connect: $lecturerID
			}
			lecturerFallback: $lecturerFallback
			periodOfRest: $periodOfRest
			regionalNetworks: $regionalNetworks
			secondRegistrationPeriod: $secondRegistrationPeriod
			start: $start
			status: $status
			summary: $summary
			type: {
				connect: $type
			}
			venue: {
				connect: $venueID
			}
		} ) {
			id
		}
	}
`

/** Mutation to add a second date of an event. */
const ADD_SECOND_DATE = gql`
	mutation addSecondDate( $end: DateTime! $id: ID! $start: DateTime! ) {
		addSecondDate( end: $end id: $id start: $start ) {
			id
		}
	}
`

/** Query to get all events and schools from the server. */
const GET_EVENT_TYPES_USERS_VENUES = gql`
	query GetEventTypesUsersVenues {
		allEventTypes {
			description
			type
		}
		allUsers {
			id
			name
		}
		allVenues {
        	city
        	id
        	name
    	}
	}
`

/**
 * This component creates a view to create new events.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const ScheduleEventPage = () => {
	/** Retrieve the `auth` access-token from the store. */
	const authAccessToken = useSelector(selectAuthAccessToken)
	/** Retrieve the `authSession` access-token from the store. */
	const authSessionAccessToken = useSelector(selectAuthSessionAccessToken)
	/** Retrieve the `authSession` stay logged-in value from the store. */
	const authSessionStayLoggedIn = useSelector(selectAuthSessionStayLoggedIn)

	const now = moment()

	/** Define a state holding the calendar-id of the Google Calendar event. */
	const [calendarID, setCalendarID] = useState({
		error: false,
		value: "",
		verified: true,
	})
	/** Define a state holding the description of the event. */
	const [description, setDescription] = useState({
		error: false,
		value: "",
		verified: true,
	})
	/** Define a state disabling the submit button. */
	const [disableSubmit, setDisableSubmit] = useState(true)
	/** Define a state holding the datetime when the event ends. */
	const [end, setEnd] = useState(moment(now).add(30, "d").startOf("hour").add(2, "h"))
	/** Define a state holding an error. */
	const [error, setError] = useState({})
	/** Define a state holding the event ID "Veranstaltungsnummer" of the event. */
	const [eventID, setEventID] = useState({
		error: false,
		value: "",
		verified: false,
	})
	/** Define a state that holds all possible event types. */
	const [eventTypes, setEventTypes] = useState([])
	/** Define a state holding the fee-status of the new event. */
	const [fee, setFee] = useState(false)
	/** Define a state holding the html link of the Google Calendar event. */
	const [htmlLink, setHtmlLink] = useState({
		error: false,
		value: "",
		verified: true,
	})
	/** Define a state holding the ical-uid of the Google Calendar event. */
	const [icalUID, setIcalUID] = useState({
		error: false,
		value: "",
		verified: true,
	})
	/** Define a state holding the ID of the newly created event. */
	const [ID, setID] = useState('0')
	/** Define a state holding the datetime when the initial registration period begins. */
	const [initialPeriod, setInitialPeriod] = useState(moment(now).startOf("day").add(7, "d"))
	/** Define a state holding the lecturer of the event. */
	const [lecturer, setLecturer] = useState({
		error: false,
		value: {
			id: "0",
			name: "",
			__typename: "User",
		},
		verified: false,
	})
	/** Define a state holding the information, whether the user exists in the system or a simple `TextField` should be displayed for the name. */
	const [lecturerFallback, setLecturerFallback] = useState(false)
	/** Define a state holding the name of the "fallback" lecturer */
	const [lecturerFallbackName, setLecturerFallbackName] = useState({
		error: false,
		value: "",
		verified: false,
	})
	/** Define a state holding the datetime when the period of rest begins. */
	const [periodOfRest, setPeriodOfRest] = useState(moment(now).startOf("day").add(28, "d"))
	/** Define a state holding an array of regional networks. */
	const [regionalNetworks, setRegionalNetworks] = useState({
		error: false,
		value: [],
		verified: false,
	})
	/** Define a state holding the information whether the event has a second date. */
	const [secondDate, setSecondDate] = useState(false)
	/** Define a state holding the datetime when the second date of the event ends. */
	const [secondDateEnd, setSecondDateEnd] = useState(moment(now).add(31, "d").startOf("hour").add(2, "h"))
	/** Define a state holding the datetime when the second date of the event begins. */
	const [secondDateStart, setSecondDateStart] = useState(moment(now).add(31, "d").startOf("hour"))
	/** Define a state holding the datetime when the second registration period begins. */
	const [secondPeriod, setSecondPeriod] = useState(moment(now).startOf("day").add(21, "d"))
	/** Define a state holding the datetime when the event begins. */
	const [start, setStart] = useState(moment(now).add(30, "d").startOf("hour"))
	/** Define a state holding the status of the new event. */
	const [status, setStatus] = useState({
		error: false,
		value: "",
		verified: true,
	})
	/** Define a state holding the summary of the Google Calendar event. */
	const [summary, setSummary] = useState({
		error: false,
		value: "",
		verified: true,
	})
	/** Define a state holding the type of the new event. */
	const [type, setType] = useState({
		error: false,
		value: "",
		verified: false,
	})
	/** Define a state holding a default venue for the new event. */
	const [venue, setVenue] = useState({
		error: false,
		value: {
			city: "",
			id: "0",
			label: "",
			name: "",
			__typename: "Venue",
		},
		verified: true,
	})
	/** Define a state holding all available venues from the server. */
	const [venues, setVenues] = useState([])
	/** Define a state holding all available users from the server. */
	const [users, setUsers] = useState([])

	/** Fetch all venues from the GraphQL Server. */
	const {} = useQuery(GET_EVENT_TYPES_USERS_VENUES, {
		displayName: "GetEventTypesUsersVenuesQuery",
		onCompleted: (data) => {
			if (data && data.allVenues) {
				setVenues(
					data.allVenues.map((venue) => ({
						...venue,
						label: `${ venue.name } (${ venue.city })`,
					}))
				)
			}
			if (data && data.allUsers) setUsers(data.allUsers)
			if (data && data.allEventTypes) setEventTypes(data.allEventTypes)
		},
		ssr: false,
	})

	/** Create a new `Event` on the server. */
	const [addEvent] = useMutation(ADD_EVENT, {
		onCompleted: (data) => {
			setID(data.createEvent.id)

			if (secondDate) addSecondDate()
			else navigate(`/events/${ data.createEvent.id }`)
		},
		onError: (error) => {
			logger.error(error)
			setError(error)
		},
		variables: {
			calendarID: _.isEmpty(calendarID.value) ? null : calendarID.value,
			description: _.isEmpty(description.value) ? null : description.value,
			end: end.format("YYYY-MM-DD HH:mm:ss"),
			eventID: eventID.value,
			fee: fee,
			htmlLink: _.isEmpty(htmlLink.value) ? null : htmlLink.value,
			icalUID: _.isEmpty(icalUID.value) ? null : icalUID.value,
			initialRegistrationPeriod: initialPeriod.format("YYYY-MM-DD"),
			lecturerFallback: (!lecturerFallback || _.isEmpty(lecturerFallbackName.value)) ? null : lecturerFallbackName.value,
			lecturerID: (lecturerFallback || lecturer.value.id === "0") ? null : lecturer.value.id,
			periodOfRest: periodOfRest.format("YYYY-MM-DD"),
			regionalNetworks: JSON.stringify({ cities: [...regionalNetworks.value] }),
			secondRegistrationPeriod: secondPeriod.format("YYYY-MM-DD"),
			start: start.format("YYYY-MM-DD HH:mm:ss"),
			status: _.isEmpty(status.value) ? null : status.value,
			summary: _.isEmpty(summary.value) ? eventID.value : summary.value,
			type: type.value,
			venueID: venue.value.id === "0" ? null : venue.value.id,
		},
	})

	/** Add a second date to the newly created `Event` on the server. */
	const [addSecondDate] = useMutation(ADD_SECOND_DATE, {
		onCompleted: () => {
			navigate(`/events/${ ID }`)
		},
		onError: (error) => {
			logger.error(error)
			setError(error)
		},
		variables: {
			end: secondDateEnd.format("YYYY-MM-DD HH:mm:ss"),
			id: ID,
			start: secondDateStart.format("YYYY-MM-DD HH:mm:ss"),
		},
	})

	/** Disable the save button, if necessary. */
	useEffect(
		() => {
			if (
				!calendarID.verified ||
				!description.verified ||
				!eventID.verified ||
				!htmlLink.verified ||
				!icalUID.verified ||
				(
					( !lecturerFallback && !lecturer.verified ) &&
					( lecturerFallback && !lecturerFallbackName.verified )
				) ||
				!regionalNetworks.verified ||
				!status.verified ||
				!summary.verified ||
				!type.verified ||
				!venue.verified
			) {
				setDisableSubmit(true)
			}
			else {
				setDisableSubmit(false)
			}
		},
		[
			calendarID.verified,
			description.verified,
			eventID.verified,
			htmlLink.verified,
			icalUID.verified,
			lecturer.verified,
			lecturerFallbackName.verified,
			regionalNetworks.verified,
			status.verified,
			summary.verified,
			type.verified,
			venue.verified
		]
	)

	return redirectUnauthenticatedToSignIn(authSessionStayLoggedIn, authAccessToken.token, authSessionAccessToken) ?
		null
	:
		(
			<>
				<GatsbySeo title="Neues Event planen" />
				<Typography color="textSecondary" gutterBottom variant="h3">Neues Event planen</Typography>
				<EventScheduler
					calendarID={ calendarID }
					description={ description }
					end={ end }
					eventID={ eventID }
					eventTypes={ eventTypes }
					fee={ fee }
					handleCalendarIDChanged={ setCalendarID }
					handleDescriptionChanged={ setDescription }
					handleEndChanged={ setEnd }
					handleEventIDChanged={ setEventID }
					handleFeeChanged={ setFee }
					handleHtmlLinkChanged={ setHtmlLink }
					handleIcalUIDChanged={ setIcalUID }
					handleInitialPeriodChanged={ setInitialPeriod }
					handleLecturerChanged={ setLecturer }
					handleLecturerFallbackChanged={ setLecturerFallback }
					handleLecturerFallbackNameChanged={ setLecturerFallbackName }
					handlePeriodOfRestChanged={ setPeriodOfRest }
					handleRegionalNetworksChanged={ setRegionalNetworks }
					handleSecondDateChanged={ setSecondDate }
					handleSecondDateEndChanged={ setSecondDateEnd }
					handleSecondDateStartChanged={ setSecondDateStart }
					handleSecondPeriodChanged={ setSecondPeriod }
					handleStartChanged={ setStart }
					handleStatusChanged={ setStatus }
					handleSummaryChanged={ setSummary }
					handleTypeChanged={ setType }
					handleVenueChanged={ setVenue }
					htmlLink={ htmlLink }
					icalUID={ icalUID }
					initialPeriod={ initialPeriod }
					lecturer={ lecturer }
					lecturerFallback={ lecturerFallback }
					lecturerFallbackName={ lecturerFallbackName }
					periodOfRest={ periodOfRest }
					regionalNetworks={ regionalNetworks }
					secondDate={ secondDate }
					secondDateEnd={ secondDateEnd }
					secondDateStart={ secondDateStart }
					secondPeriod={ secondPeriod }
					start={ start }
					status={ status }
					summary={ summary }
					type={ type }
					users={ users }
					venue={ venue }
					venues={ venues }>
					<Grid item md={ 6 } xs={ 12 }>
						<Typography gutterBottom variant="h5">Veranstaltung speichern</Typography>

						<Typography paragraph variant="body2">Bitte überprüfen Sie Ihre Eingaben noch einmal, bevor Sie sie in der Datenbank speichern.</Typography>

						<Button
							color="secondary"
							disabled={ disableSubmit }
							onClick={ e => {
								e.preventDefault()

								addEvent()
							} }
							variant="contained">
							Veranstaltung speichern
						</Button>
					</Grid>
				</EventScheduler>
				<Snackbar open={ !_.isEmpty(error) }>
					<Alert elevation={ 6 } severity="error" variant="filled">Es trat ein unerwarteter Fehler auf. Bitte öffnen Sie die Konsole (Rechtsklick auf die Website -&gt; Element untersuchen -&gt; Konsole ) und beschreiben Sie dem Entwickler möglichst genau die Fehlermeldung.</Alert>
				</Snackbar>
			</>
		)
}

export default ScheduleEventPage
