/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import chalk from 'chalk'
import log from 'loglevel'
import moment from 'moment'
import 'moment/locale/de'
import prefixer from 'loglevel-plugin-prefix'

// internal imports
import wrapper from './src/wrapPageElement'
import wrapWithProviders from './src/wrapWithProviders'

// Setup moment
moment.locale( 'de' )

// Setup logger
const colors = {
	// log level specific colors
	TRACE: chalk.cyan,
	DEBUG: chalk.green,
	INFO: chalk.yellow,
	WARN: chalk.red,
	ERROR: chalk.redBright,
	// logger object specific colors
	apollo: chalk.magenta,
	auth: chalk.green,
	graphql: chalk.magenta,
	redux: chalk.red,
}

// Setup prefixer
prefixer.reg(log)

// Set log level based on environment
if ( process.env.NODE_ENV === 'development' ) {
	log.enableAll()
}
else {
	log.setLevel('WARN')
}

// Setup logger template
prefixer.apply(log, {
	template: `%t - %n - %l -`,
	levelFormatter(level) {
		const UPPER_CASE = level.toUpperCase()

		return colors[UPPER_CASE].bold(UPPER_CASE)
	},
	nameFormatter(name) {
		return name ? colors[name](name) : 'jd-admin'
	},
	timestampFormatter(date) {
		return chalk.gray(moment(date).format('YYYY-MM-DD HH:mm:ss,SSS'))
	},
})

export const wrapPageElement = wrapper
export const wrapRootElement = wrapWithProviders
