/**
 * Address.jsx
 *
 * @file This file exports the form part for the address.
 * @author Robin Walter <hello@robinwalter.me>
 */

import {
	Grid,
	TextField,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import validator from 'validator'

// internal imports
import styles from './SchoolEditor.styles'

// Create styles
const useStyles = makeStyles(styles)

/**
 * This component creates a form part for the address.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered component.
 */
const Address = ({
	address,
	city,
	handleAddressChanged,
	handleCityChanged,
	handlePostalCodeChanged,
	md,
	postalCode,
	xs
}) => {
	const classes = useStyles()

	/** Validate the input data and check if there are any mistakes. */
	useEffect( () => {
		// Validate the address
		if ( validator.isEmpty( address.value ) ) {
			handleAddressChanged( {
				...address,
				error: true,
				verified: false
			} )
		}
		else {
			handleAddressChanged( {
				...address,
				error: false,
				verified: true
			} )
		}
		// Validate the city
		if ( validator.isEmpty( city.value ) ) {
			handleCityChanged( {
				...city,
				error: true,
				verified: false
			} )
		}
		else {
			handleCityChanged( {
				...city,
				error: false,
				verified: true
			} )
		}
		// Validate the postal code
		if ( validator.isEmpty( postalCode.value ) || !validator.isPostalCode( postalCode.value, 'DE' ) ) {
			handlePostalCodeChanged( {
				...postalCode,
				error: true,
				verified: false
			} )
		}
		else {
			handlePostalCodeChanged( {
				...postalCode,
				error: false,
				verified: true
			} )
		}
	}, [ address.value, city.value, postalCode.value ] )

	return (
		<Grid item md={ md || 6 } xs={ xs || 12 }>
			<Typography gutterBottom variant="h5">2. Adressdaten</Typography>
			<TextField
				className={ classes.formControl }
				error={ address.error }
				helperText="Geben Sie die Adresse der Schule ein."
				id="school-address"
				InputProps={{
					className: classes.input,
				}}
				label="Adresse"
				onChange={ e => {
					handleAddressChanged( {
						...address,
						value: e.target.value
					} )
				} }
				required
				value={ address.value } />
			<TextField
				className={ classes.formControl }
				error={ city.error }
				helperText="Geben Sie die Stadt ein."
				id="school-city"
				InputProps={{
					className: classes.input,
				}}
				label="Stadt"
				onChange={ e => {
					handleCityChanged( {
						...city,
						value: e.target.value
					} )
				} }
				required
				value={ city.value } />
			<TextField
				className={ classes.formControl }
				error={ postalCode.error }
				helperText="Geben Sie die Postleitzahl ein."
				id="school-postal-code"
				InputProps={{
					className: classes.input,
				}}
				label="Postleitzahl"
				onChange={ e => {
					handlePostalCodeChanged( {
						...postalCode,
						value: e.target.value
					} )
				} }
				required
				value={ postalCode.value } />
		</Grid>
	)
}

/**
 *
 */
Address.propTypes = {
	address: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	city: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	handleAddressChanged: PropTypes.func.isRequired,
	handleCityChanged: PropTypes.func.isRequired,
	handlePostalCodeChanged: PropTypes.func.isRequired,
	md: PropTypes.number,
	postalCode: PropTypes.shape( {
		error: PropTypes.bool.isRequired,
		value: PropTypes.string.isRequired,
		verified: PropTypes.bool.isRequired
	} ).isRequired,
	xs: PropTypes.number
}

export default Address
