/**
 * CookieBanner.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default theme => ( {
	dialog: {
		zIndex: theme.zIndex.modal + 5,
	},
	table: {
		minWidth: 750,
	},
	toolbarRoot: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	toolbarTitle: {
		flex: '1 1 100%',
	}
} )
