/**
 * TotalParticipants.styles.js
 *
 * @file This file exports the component specific styles.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default (theme) => ({
	actions: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
    	marginTop: theme.spacing(2),
	},
	avatar: {
    	backgroundColor: theme.palette.success.main,
    	height: 56,
    	width: 56,
	},
	content: {
    	alignItems: 'center',
    	display: 'flex',
	},
	icon: {
    	height: 32,
    	width: 32,
	},
	root: {
		height: '100%',
	},
	title: {
    	fontWeight: 700,
	},
})
