/**
 * typography.js
 *
 * @file The custom theme configuration for the typography.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default palette => ( {
	body1: {
    	color: palette.text.primary,
		fontWeight: 600,
	},
	body2: {
    	color: palette.text.secondary,
	},
	button: {
    	color: palette.text.primary,
		fontWeight: 600,
	},
	caption: {
    	color: palette.text.secondary,
	},
	fontFamily: [
		'Nunito',
		'Verdana',
		'sans-serif'
	].join( ', ' ),
	fontSize: 16,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 600,
	h1: {
    	color: palette.text.primary,
    	fontWeight: 600,
	},
	h2: {
    	color: palette.text.primary,
    	fontWeight: 600,
	},
	h3: {
    	color: palette.text.primary,
    	fontWeight: 600,
	},
	h4: {
    	color: palette.text.primary,
    	fontWeight: 600,
	},
	h5: {
    	color: palette.text.primary,
    	fontWeight: 600,
	},
	h6: {
    	color: palette.text.primary,
    	fontWeight: 600,
	},
	overline: {
    	color: palette.text.secondary,
    	fontWeight: 600,
    	textTransform: 'uppercase',
	},
	subtitle1: {
    	color: palette.text.primary,
		fontWeight: 600,
	},
	subtitle2: {
    	color: palette.text.secondary,
    	fontWeight: 400,
	},
} )
