/**
 * EventDetailParticipantsTable.jsx
 *
 * @file This file exports a table which holds the participants.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import {
	Avatar,
	Badge,
	Box,
	Card,
	CardContent,
	IconButton,
	Tooltip,
} from '@material-ui/core'
import {
	DataGrid,
	getGridSingleSelectOperators,
	gridStringOrNumberComparator,
	GridToolbar,
} from "@mui/x-data-grid"
import DeleteIcon from "@material-ui/icons/Delete"
import logger from "loglevel"
import { makeStyles, useTheme, withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

// internal imports
import { ButtonLink } from '../ButtonLink'
import { pages } from '../Sidebar'
import styles from './EventDetailParticipantsTable.styles'
import { transformAppellation } from '../../utils'

// Create styles
const useStyles = makeStyles(styles)

const PARTICIPANT_STATUS = [
	{ label: "Abgelehnt", value: "ABGELEHNT" },       // 1
	{ label: "Abgemeldet", value: "ABGEMELDET" },     // 2
	{ label: "Absent", value: "ABSENT" },             // 3
	{ label: "Angemeldet", value: "ANGEMELDET" },     // 4
	{ label: "Teilgenommen", value: "TEILGENOMMEN" }, // 5
	{ label: "Zugelassen", value: "ZUGELASSEN" },     // 6
]

const PARTICIPANT_TYPE = [
	{ label: "Ehemalig", value: "FORMER" },
	{ label: "Andere", value: "OTHER" },
	{ label: "Eltern", value: "PARENT" },
	{ label: "Schüler:in", value: "PUPIL" },
	{ label: "Lehrer:in", value: "TEACHER" },
]

/**
 * Create the participants table.
 *
 * @param {Object} props The component props.
 * @returns {Node} The styled component.
 */
const EventDetailParticipantsTable = ({
	dataDeletion,
	deleteParticipant,
	errorDeletion,
	loading,
	participations,
	updateParticipantStatus,
	...other
}) => {
	const classes = useStyles()
	const theme = useTheme()

	/** Define a state that holds the rows currently displayed */
	const [rows, setRows] = useState([])

	const dataGridGetActions = (params) => [
		<IconButton onClick={(event) => {
			event.preventDefault()

			deleteParticipant({
				variables: {
					id: params.row.dbId,
				},
			})
		}}>
			<DeleteIcon />
		</IconButton>,
	]

	const filterOperatorsSingleSelect = getGridSingleSelectOperators().filter(
		(operator) => operator.value === "is",
	)

	const renderCellDbId = (params) => {

		return (
			<ButtonLink
				color="primary"
				size="small"
				to={ `${ pages[3].href }/${ params.value }` }
				variant="text">
				{ params.value }
			</ButtonLink>
		)
	}

	const renderCellParticipation = (params) => {

		return (
			<ButtonLink
				color="primary"
				size="small"
				to={ `${ pages[4].href }/${ params.value }` }
				variant="text">
				{ params.value }
			</ButtonLink>
		)
	}

	const renderCellSchool = (params) => {
		if (params.value === "N/A") {
			return "N/A"
		}

		return (
			<ButtonLink
				color="primary"
				size="small"
				to={ `${ pages[6].href }/${ params.value.id }` }
				variant="text">
				{ params.value.name }
			</ButtonLink>
		)
	}

	const renderCellType = (params) => {
		const StyledBadge = withStyles(() => ({
			badge: {
				backgroundColor: params.value.confirmed ? theme.palette.success.main : theme.palette.secondary.main,
			},
		}))(Badge)

		let title = '', type = ''
		switch (params.value.type) {
			case PARTICIPANT_TYPE[0].value:
				title = 'Ehemalig'
				type = 'F'
				break
			case PARTICIPANT_TYPE[1].value:
				title = 'Andere'
				type = 'XY'
				break
			case PARTICIPANT_TYPE[2].value:
				title = 'Eltern'
				type = 'E'
				break
			case PARTICIPANT_TYPE[3].value:
				title = 'Schüler:in'
				type = 'S'
				break
			default:
				title = 'Lehrer:in'
				type = 'L'
		}

		return (
			<Tooltip placement="top-end" title={ title }>
				<StyledBadge
					anchorOrigin={{
						horizontal: "right",
						vertical: "top",
					}}
					overlap="circular"
					variant="dot">
					<Avatar
						className={ classes.avatar }>
						{ type }
					</Avatar>
				</StyledBadge>
			</Tooltip>
		)
	}

	const sortComparatorSchool = (school1, school2, param1, param2) =>
			gridStringOrNumberComparator(
				school1 !== "N/A" ? school1.name : "N/A",
				school2 !== "N/A" ? school2.name : "N/A",
				param1,
				param2
			)

	const sortComparatorType = (type1, type2, param1, param2) =>
			gridStringOrNumberComparator(
				type1.type,
				type2.type,
				param1,
				param2
			)

	const valueFormatterSchool = (params) => `${params.value.name} (${params.value.city})`

	const valueFormatterType = (params) => {
		let type = ""
		switch (params.value.type) {
			case PARTICIPANT_TYPE[0].value:
				type = "Ehemalig"
				break
			case PARTICIPANT_TYPE[1].value:
				type = "Andere"
				break
			case PARTICIPANT_TYPE[2].value:
				type = "Eltern"
				break
			case PARTICIPANT_TYPE[3].value:
				type = "Schüler:in"
				break
			default:
				type = "Lehrer:in"
		}

		return type
	}

	const columns = [
		{ field: "dbId", filterable: false, flex: 0.25, headerName: "#ID", renderCell: renderCellDbId, },
		{ field: "type", flex: 0.5, headerName: "Typ", renderCell: renderCellType, sortComparator: sortComparatorType, type: "singleSelect", valueFormatter: valueFormatterType, valueOptions: PARTICIPANT_TYPE, },
		{ field: "appellation", filterable: false, flex: 0.5, headerName: "Anrede", },
		{ field: "firstName", filterable: false, flex: 0.5, headerName: "Vorname", },
		{ field: "lastName", filterable: false, flex: 0.75, headerName: "Nachname", hideable: false, },
		{ field: "participation", filterable: false, flex: 0.5, headerName: "Anmeldung", renderCell: renderCellParticipation, },
		{ field: "school", filterable: false, flex: 2, headerName: "Schule", renderCell: renderCellSchool, sortComparator: sortComparatorSchool, valueFormatter: valueFormatterSchool, },
		{ editable: true, field: "status", filterOperators: filterOperatorsSingleSelect, flex: 1.5, headerName: "Status", type: "singleSelect", valueOptions: PARTICIPANT_STATUS, }, /** TODO: Check {@link https://github.com/mui/mui-x/issues/4437} for fix, since this field MUST be filterable */
		{ field: "actions", flex: 0.5, getActions: dataGridGetActions, headerName: "Bearbeiten", type: "actions", },
	]

	useMemo(
		() => {
			const newRows = []
			participations.forEach((participation) => {
				participation.participants.forEach((participant) => {
					newRows.push({
						appellation: !_.isEmpty(participant.appellation) ? transformAppellation(participant.appellation) : "",
						dbId: participant.id,
						firstName: participant.firstName,
						id: participant.uuid,
						lastName: participant.lastName,
						participation: participation.id,
						school: participation.school ? participation.school : "N/A",
						status: participant.status,
						type: {
							confirmed: participant.confirmed,
							type: participant.type,
						},
					})
				})
			})
			setRows(newRows)

			if (errorDeletion) {
				logger.error(errorDeletion)
			}

			if (dataDeletion && dataDeletion.deleteParticipant) {
				let prepareRows = [...rows]
				const index = _.findIndex(prepareRows, { dbId: dataDeletion.deleteParticipant.id })
				if (index !== -1) {
					prepareRows.splice(index, 1)
					setRows(prepareRows)
				}
			}
		},
		[dataDeletion, errorDeletion, participations]
	)

	/**
	 * Change the status of a specific participant.
	 *
	 * @param {Object} newParticipant The altered participant object.
	 * @param {Object} oldParticipant The original participant object.
	 */
	 const handleStatusChange = useCallback(
		(newParticipant, oldParticipant) => {
			if (_.isEqual(newParticipant, oldParticipant)) {
				return oldParticipant
			}
			else {
				updateParticipantStatus({
					variables: {
						id: newParticipant.dbId,
						status: newParticipant.status,
					},
				})
				return newParticipant
			}
		},
		[updateParticipantStatus]
	)

	return (
		<>
			<Card className={ classes.root } { ...other }>
				<CardContent className={ classes.content }>
					<Box className={ classes.inner }>
					<DataGrid
						autoHeight
						columns={ columns }
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
						experimentalFeatures={{
							newEditingApi: true,
						}}
						initialState={{
							sorting: {
								sortModel: [{ field: "school", sort: "asc" }],
							}
						}}
						isCellEditable={ (params) => params.row.type.confirmed }
						loading={ loading }
						onProcessRowUpdateError={(error) => {
							logger.error(error)
						}}
						pagination
						processRowUpdate={ handleStatusChange }
						rowCount={ rows.length }
						rows={ rows }
						rowsPerPageOptions={[ 10, 15, 25, 50, 100 ]} />
					</Box>
				</CardContent>
			</Card>
		</>
	)
}

/**
 *
 */
EventDetailParticipantsTable.propTypes = {
	dataDeletion: PropTypes.shape({
		uuid: PropTypes.string.isRequired,
	}),
	deleteParticipant: PropTypes.func.isRequired,
	errorDeletion: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	participations: PropTypes.arrayOf( PropTypes.shape( {
		participants: PropTypes.arrayOf( PropTypes.shape( {
			appellation: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			status: PropTypes.oneOf(PARTICIPANT_STATUS.map((status) => status.value)).isRequired,
			type: PropTypes.oneOf(PARTICIPANT_TYPE.map((type) => type.value)).isRequired,
			uuid: PropTypes.string.isRequired
		} ) ).isRequired,
		school: PropTypes.shape( {
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		} )
	} ) ).isRequired,
	updateParticipantStatus: PropTypes.func.isRequired,
}

export default EventDetailParticipantsTable
